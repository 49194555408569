import axios from "axios";
import { SelfonboardingRequestResponse } from "./models/selfonboardingRequest";
import { FetchDataRequest, FetchDataResult } from "../../../Common/models/grid";
import {
  getSieveRequestModel,
  mapToFetchDataResult,
} from "../../../Common/utils/sieveRequestModel";
import { SieveResponse } from "../../../Common/models/sieve";

interface IFetchDataRequest {
  projectId: string;
  memberId: string;
}

export const getSelfonboardingRequest = async ({
  projectId,
  memberId,
}: IFetchDataRequest): Promise<SelfonboardingRequestResponse> => {
  const { data } = await axios.get<SelfonboardingRequestResponse>(
    `${window.configuration.apiUrl}api/Selfonboarding/request/projects/${projectId}/members/${memberId}`
  );

  return data;
};

export const getSelfonboardingRequests = async (
  request: FetchDataRequest
): Promise<FetchDataResult> => {
  const sieveRequest = getSieveRequestModel(request);
  const { data } = await axios.get<SieveResponse>(
    `${window.configuration.apiUrl}api/Selfonboarding/requests/pending`,
    { params: sieveRequest }
  );

  const response = mapToFetchDataResult(data);
  return response;
};

export const getSelfonboardingProcessedRequests = async (
  request: FetchDataRequest
): Promise<FetchDataResult> => {
  const sieveRequest = getSieveRequestModel(request);
  const { data } = await axios.get<SieveResponse>(
    `${window.configuration.apiUrl}api/Selfonboarding/requests/processed`,
    { params: sieveRequest }
  );

  const response = mapToFetchDataResult(data);
  return response;
};

export const patchSelfonboardingRequest = async (
  projectId: string,
  memberId: string,
  isApproved: boolean
): Promise<void> => {
  await axios.patch(
    `${window.configuration.apiUrl}api/Selfonboarding/request/projects/${projectId}/members/${memberId}`,
    {
      projectId,
      memberId,
      isApproved,
    }
  );
};
