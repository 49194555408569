import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../App/store/store';

export type HeiUser = {
  name: string;
  initials: string;
  email: string;
  roles: string[];
}

export type Account = {
  accountId: string;
  accessToken: string;
};

const initialState: AccountState = {
  accountId: '',
  accessToken: '',
  heiUser: {
    initials: '',
    name: '',
    roles: ['User'],
    email: ''
  }
};

export type AccountState = Account & {
  heiUser: HeiUser
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountId: (state: AccountState, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },
    setToken: (state: AccountState, action: PayloadAction<string>) => {
        state.accessToken = action.payload;
      },
    setHeiUser: (state: AccountState, action: PayloadAction<HeiUser>) => {
        state.heiUser = action.payload;
    },
  },
});

export const { setAccountId, setToken, setHeiUser } = accountSlice.actions;

export const selectAccountId = (state: RootState) => state.account.accountId;
export const selectToken = (state: RootState) => state.account.accessToken;
export const selectHeiUser = (state: RootState) => state.account.heiUser;

export default accountSlice.reducer;