import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import JoinProjectButton from "../JoinProjectButton/JoinProjectButton";
import { getColumns } from "../MyProjectsGrid/getMyProjectsGridColumns";

export const getOpenProjectColumns = (callForceRefresh: any): GridColDef[] => {
  const baseColumns = getColumns();

  return [
    ...baseColumns,
    {
      field: "actions",
      headerName: "",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        const openRoles = params.row.openRoles.split(",");
        if (openRoles.length > 0) {
          openRoles.unshift("JOIN AS");
          return (
            <JoinProjectButton
              disabled={params.row.status === "Blocked"}
              projectId={params.row.id}
              roles={openRoles}
              isProvisioningFromApi={params.row.isProvisioning}
              callForceRefresh={callForceRefresh}
            />
          );
        }
      },
    },
  ];
};
