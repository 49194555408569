import { CheckCircleOutline } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { EntityState } from "../../../../Common/api/models/EntityState";
import { ProjectTool, ToolStatusEnum } from "../../../../Common/api/models/ProjectTool";
import ToolCard from "../../../../Common/components/ToolCard/ToolCard";
import ToolDetailsFieldsTableRow from "../../../../Common/components/ToolDetailsFieldsTableRow/ToolDetailsFieldsTableRow";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import { ProjectFinalizeFormType } from "../../hooks/useProjectDetails";
import EntityDeleteToggleButton from "../ToolDeleteToggleButton/EntityDeleteToggleButton";
import ToolLaunchIcon from "../ToolLaunchIcon/ToolLaunchIcon";
import ToolStatusBadge from "../ToolStatus/ToolStatusBadge";
import ToolStatusChip from "../ToolStatusChip/ToolStatusChip";
import styles from "./ProjectToolTableRow.module.scss";

interface ProjectToolTableRowProps {
  form: UseFormReturn<ProjectFormType>;
  toolOpen: boolean;
  toolIsHidden: boolean;
  tool: ProjectTool;
  toolInviteMode: string;
  toolIsMandatory: boolean;
  rowIndex: number;
  userCanEdit: boolean;
  userIsAdmin: boolean;
  handleDelete: (index: number) => void;
  ToggleItemButton: React.ReactNode;
  setIsFinalizeProjectPopupOpen: (isOpen: boolean) => void;
  finalizeForm: UseFormReturn<ProjectFinalizeFormType, any>;
}

const ProjectToolTableRow = ({
  form,
  toolOpen,
  tool,
  toolIsMandatory,
  toolInviteMode,
  rowIndex,
  userCanEdit,
  userIsAdmin,
  handleDelete,
  ToggleItemButton,
  setIsFinalizeProjectPopupOpen,
  finalizeForm
}: ProjectToolTableRowProps): JSX.Element => {
  const { watch } = form;

  const toolEditStatus = watch(`tools.${rowIndex}.editStatus`) as EntityState;

  const openFinalizePopup = () => {    
    finalizeForm.setValue("toolProjectDeeplink", "");
    finalizeForm.setValue("toolProjectId", "");  
    finalizeForm.setValue("toolId", tool.toolId);
    finalizeForm.setValue("capabilityId", tool.capabilityId);
    setIsFinalizeProjectPopupOpen(true);
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={2}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ToolCard
              capabilityName={tool.capabilityName}
              toolKey={tool.capabilityToolKey}
              toolName={tool.toolDisplayName ?? tool.toolName}
              tooltip={tool.toolName}
            />
            <ToolStatusChip
              toolEditStatus={toolEditStatus}
              isNew={tool.isToolNew}
            />
          </div>
        </TableCell>

        <TableCell>{toolInviteMode}</TableCell>
        <TableCell sx={{ p: 0, pl: 2, height: "100%" }}>
          <div className={styles.toolDetails__collapseCell}>
            <ToolStatusBadge status={tool.status} />
            <div>
              {userIsAdmin && tool.status == ToolStatusEnum.CreationPending && (
                <Tooltip
                  title="Finalize tool creation"
                  arrow
                  placement="top"
                >
                  <IconButton aria-label="finalize" onClick={openFinalizePopup}>
                    <CheckCircleOutline />
                  </IconButton>
                </Tooltip>
              )}
              <ToolLaunchIcon toolProjectDeeplink={tool.toolProjectDeeplink} />
              {userCanEdit && !toolIsMandatory && (
                <EntityDeleteToggleButton
                  entityName={"tool"}
                  entityStatus={toolEditStatus}
                  onClick={() => handleDelete(rowIndex)}
                />
              )}
            </div>
            {ToggleItemButton}
          </div>
        </TableCell>
      </TableRow>
      <ToolDetailsFieldsTableRow
        form={form}
        index={rowIndex}
        toolKey={tool.capabilityToolKey}
        CustomFields={tool.CustomFields}
        toolOpen={toolOpen}
        userCanEdit={userCanEdit}
        status={tool.status}
        isEdit
      />
    </>
  );
};

export default ProjectToolTableRow;
