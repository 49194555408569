import { CancelOutlined, DeleteForeverOutlined, EditOutlined, SaveOutlined } from "@mui/icons-material";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import ActionConfirmation from "../../../../../Common/components/ActionConfirmation/ActionConfirmation";
import HeiCheckbox from "../../../../../Common/components/Form/HeiCheckbox/HeiCheckbox";
import HeiTextField from "../../../../../Common/components/Form/HeiTextField/HeiTextField";
import { AdminOpcoDto } from "../../../api/models/AdminOpcoDto";
import AdminOpcoManagersGrid from "../AdminOpcoManagersGrid/AdminOpcoManagersGrid";
import { useAdminOpcoRow } from "../hooks/useAdminOpcoRow";
import styles from "./AdminOpcoRow.module.scss";

interface AdminOpcoRowProps {
    opco: AdminOpcoDto;
    opcoExpanded?: boolean;
    ToggleItemButton?: React.ReactNode;
    edited?: boolean;
    setAdding?: React.Dispatch<React.SetStateAction<boolean>>;
    reloadData: () => Promise<void>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export type OperationType = "create" | "update" | "remove";
  
const AdminOpcoRow = ({ opco, opcoExpanded, ToggleItemButton, edited, setAdding, reloadData, setIsLoading }: AdminOpcoRowProps)
    : JSX.Element => {
    const {opcoForm, handleSave, handleCancel, handleDelete, isEdited, setIsEdited, showConfirmationModal, 
        setShowConfirmationModal, enqueueSnackbar} = useAdminOpcoRow(opco, reloadData, setIsLoading, edited, setAdding);

    return (
        <>
        <ActionConfirmation
            title={"Do you want to remove this opco?"}
            description=""
            when={showConfirmationModal}
            confirm={() => handleDelete()}
            cancel={() => setShowConfirmationModal(false)}
        />
        <TableRow key={opco.id}>
            <TableCell> { isEdited
                ? <HeiTextField sx={{width: "100%"}} control={opcoForm.control} name="name" label=""/>
                : opco.name
                }
            </TableCell>
            <TableCell className={styles.details__checkBoxCell}> 
                <HeiCheckbox control={opcoForm.control} name="infoRequired" label="" disabled={!isEdited} sx={{mt: "0"}} />
            </TableCell>
            <TableCell>
                { isEdited
                ? (<>
                    <IconButton onClick={() => handleSave()}><SaveOutlined/></IconButton>
                    <IconButton onClick={() => handleCancel()}><CancelOutlined/></IconButton>
                </>)
                : (<>
                    <IconButton onClick={() => setIsEdited(true)}><EditOutlined/></IconButton>
                    <IconButton onClick={() => setShowConfirmationModal(true)}><DeleteForeverOutlined/></IconButton>
                </>)
                }
            </TableCell>
            <TableCell sx={{ p: 0, pl: 2, height: "100%" }}>
            <div className={styles.userDetails__collapseCell}>
                {ToggleItemButton}
            </div>
            </TableCell>
        </TableRow>
        <TableRow sx={{ background: "#f5f5f5" }} key={`${opco.id}-managers`}>
            <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: "top" }}>
                <Collapse in={opcoExpanded}>
                    <AdminOpcoManagersGrid opcoId={opco.id} managers={opco.managers} setIsLoading={setIsLoading} enqueueSnackbar={enqueueSnackbar} key={`${opco.id}-managers-grid`} />
                </Collapse>
            </TableCell>
        </TableRow>
        </>
    );
};

export default AdminOpcoRow;
