import OutlinedFlagRoundedIcon from "@mui/icons-material/OutlinedFlagRounded";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import HeiCheckbox from "../../../../../Common/components/Form/HeiCheckbox/HeiCheckbox";
import HeiDynamicField from "../../../../../Common/components/Form/HeiDynamicField/HeiDynamicField";
import HeiTextField from "../../../../../Common/components/Form/HeiTextField/HeiTextField";
import NavigationPrompt from "../../../../../Common/components/NavigationPrompt/NavigationPrompt";
import { mapCreateProjectFormField } from "../../../../../Common/utils/dynamicFieldUtil";
import ProjectTypeSelection from "../../../../CreateProject/components/ProjectTypeSelection/ProjectTypeSelection";
import { ProjectDetails } from "../../../api/models/ProjectDetails";
import useUpdateProjectDetails from "../../../hooks/useUpdateProjectDetails";
import styles from "./ProjectDetailsTab.module.scss";
import { useState } from "react";
import PackageActionPopup from "../../../components/PackageActionPopup/PackageActionPopup";
import usePackageActions from "../../../hooks/usePackageActions";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import usePageTitle from "../../../../../Common/hooks/usePageTitle";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";

interface ProjectDetailsTabProps {
  project: ProjectDetails;
  userCanEdit: boolean;
}

const ProjectDetailsTab = ({
  project,
  userCanEdit,
}: ProjectDetailsTabProps) => {
  const { form, opcos, handleSubmit, isSaving } =
    useUpdateProjectDetails(project);

  usePageTitle("Project | Details");

  const { packageCustomField, id, packageId } = project;
  const { packageActions, isLoading: isPackageActionsLoading } =
    usePackageActions(packageId);
  const dynamicFields = packageCustomField?.map(mapCreateProjectFormField);

  const {
    control,
    watch,
    formState: { isDirty },
  } = form;

  const billingType = watch("billingType");
  const [isPackageActionPopupOpen, setIsPackageActionPopupOpen] =
    useState(false);
  const [actionIndex, setActionIndex] = useState(0);

  const handleActionClick = (index: number) => {
    setActionIndex(index);
    setIsPackageActionPopupOpen(true);
  };
  const isArchived = project?.isArchived ?? false;

  return (
    <Box sx={{ pl: "80px", pt: "24px", height: "100%" }}>
      <NavigationPrompt
        when={isDirty}
        title="Do you want to leave this page?"
        description="You have unsaved changes that will be lost if you decide to continue."
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSaving || !opcos}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Typography variant={"h6"} sx={{ mb: "32px" }}>
        Project Details
      </Typography>

      {!isArchived && project.isFlagged &&
        project.flaggingStatus !== "Locked" &&
        billingType !== "EnterBillingLater" && (
          <Alert
            severity="warning"
            icon={<OutlinedFlagRoundedIcon />}
            className={styles.projectDetails__flaggedAlert}
          >
            <AlertTitle className={styles.projectDetails__flaggedAlertTitle}>
              Project flagged
            </AlertTitle>
            Incorrect cost center
          </Alert>
        )}

      {!isArchived && project.isFlagged && project.flaggingStatus === "Locked" && (
        <Alert
          severity="error"
          icon={<LockOutlinedIcon />}
          className={styles.projectDetails__flaggedAlert}
        >
          <AlertTitle className={styles.projectDetails__flaggedAlertTitle}>
            Project blocked
          </AlertTitle>
          Incorrect cost center
        </Alert>
      )}

      {!isArchived && project.isFlagged &&
        project.flaggingStatus !== "Locked" &&
        billingType === "EnterBillingLater" && (
          <Alert
            severity="warning"
            icon={<OutlinedFlagRoundedIcon />}
            className={styles.projectDetails__flaggedAlert}
          >
            <AlertTitle className={styles.projectDetails__flaggedAlertTitle}>
              No billing type entered yet
            </AlertTitle>
            Select a billing type and enter the details.
          </Alert>
        )}

      {isArchived && (
        <Alert 
          severity="warning" 
          icon={<ArchiveOutlinedIcon/>}
          className={styles.projectDetails__flaggedAlert}>
          <AlertTitle className={styles.projectDetails__flaggedAlertTitle}>
            Project archived
          </AlertTitle>
          Project is archived, only viewable by admins
        </Alert>
      )}

      <form style={{ height: "100%" }} onSubmit={handleSubmit}>
        <HeiTextField
          control={control}
          name="projectName"
          label="Project name"
          sx={{ width: "45%" }}
          disabled
        />
        <HeiCheckbox
          control={control}
          disabled={!userCanEdit}
          name="hideProject"
          label="Hide this project"
          tooltip="Hide this project if it should not be publicly visible within Heineken. Note: team members cannot onboard themselves on hidden projects."
        />
        {opcos && (
          <ProjectTypeSelection
            opcos={opcos}
            form={form}
            isEdit
            canUserEdit={userCanEdit}
          />
        )}
        {packageActions && packageActions.length > 0 && (
          <PackageActionPopup
            projectId={id}
            packageAction={packageActions[actionIndex]}
            open={isPackageActionPopupOpen}
            handleClose={() => setIsPackageActionPopupOpen(false)}
          />
        )}
        {packageActions?.map((action, index) => (
          <p>
            <Button onClick={() => handleActionClick(index)} variant="text">
              {action.actionConfiguration.title}
            </Button>
          </p>
        ))}
        {dynamicFields && (
          <>
            <Typography
              className={styles.projectDetails__packageDetailsTitle}
              variant="h6"
              sx={{ mb: "32px" }}
            >
              Package details
            </Typography>

            {dynamicFields.map((df) => (
              <HeiDynamicField
                key={df.name}
                control={control}
                field={{ ...df, disabled: true }}
              />
            ))}
          </>
        )}

        {isDirty && (
          <div className={styles.projectDetails__footer}>
            <Button
              variant="contained"
              type="submit"
              size="large"
              sx={{ mr: 3 }}
            >
              Save
            </Button>
          </div>
        )}
      </form>
    </Box>
  );
};

export default ProjectDetailsTab;
