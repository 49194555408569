const routes = {
  myProjects: "my-projects",
  projects: "/projects/:tab",
  createProject: "create-project/*",
  projectDetails: "/project/:id/:tab",
  projectDetails_v1: "/Projects/details/:id",
  faq: "/faq",
  faqArticle: "/faq/:id",
  createFaqArticle: "/faq-create",
  selfOnboarding: "self-onboarding/:projectId/:userId",
  requests: "/requests/:tab",

  admin: {
    billing: "/admin/billing",
    userSearch: "/admin/user-search",
    opcos: "/admin/opcos"
  }
};

export default routes;
