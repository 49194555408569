
import "react-quill/dist/quill.snow.css";
import useUpdateFaq from "../hooks/useUpdateFaq";
import { useSelector } from "react-redux";
import { selectHeiUser } from "../../../Auth/store/accountSlice";
import { useMemo } from "react";
import { FaqArticleResponse } from "../../FaqArticle/api/models/FaqArticleResponse";
import FaqArticleForm from "../../Common/components/FaqArticleForm/FaqArticleForm";


interface FaqArticleBodyProps {
  faqArticle: FaqArticleResponse;
  handleCancel: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateFaqArticle = ({ faqArticle, handleCancel }: FaqArticleBodyProps): JSX.Element => {
  
  const heiUser = useSelector(selectHeiUser);
  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );

  const { form, submit, isLoading } = useUpdateFaq(faqArticle);
  if (!isAdmin) return <></>;

  return (
    <FaqArticleForm
      form={form}
      isLoading={isLoading}
      submit={submit}
      handleCancel={() => handleCancel(false)}
      actionButtonLabel="Update"
    />
  );
};

export default UpdateFaqArticle;
