import classNames from "classnames";
import ToolCardSimple from "../../../../Common/components/ToolCardSimple/ToolCardSimple";
import { FaqToolOption } from "../../../../Common/consts/faqToolOptions";
import styles from "./FaqToolButton.module.scss";

export interface FaqToolButtonProps extends FaqToolOption {
  toggleToolSelect: (tool: string) => void;
  isSelected: boolean;
}

const FaqToolButton = ({
  toolKey,
  toolName,
  toolDisplayName,
  toggleToolSelect,
  isSelected,
}: FaqToolButtonProps): JSX.Element => {
  const handleOnClick = () => {
    toggleToolSelect(toolName);
  };
  return (
    <button
      data-testid={`button-${toolKey}`}
      onClick={handleOnClick}
      className={classNames(styles.faqToolButton, {
        [styles["faqToolButton--selected"]]: isSelected,
      })}
    >
      <ToolCardSimple toolKey={toolKey} toolName={toolName} toolDisplayName={toolDisplayName} />
    </button>
  );
};

export default FaqToolButton;
