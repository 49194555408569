import { Backdrop, Box, Button, Typography } from "@mui/material";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import styles from "./CreateFaqArticle.module.scss";

import "react-quill/dist/quill.snow.css";
import HeiRichTextField from "../../../Common/components/Form/HeiRichTextField/HeiRichTextField";
import HeiTextField from "../../../Common/components/Form/HeiTextField/HeiTextField";
import useCreateFaq from "../hooks/useCreateFaq";
import { useNavigate } from "react-router-dom";
import HeiSelection from "../../../Common/components/HeiSelection/HeiSelection";
import { faqToolOptions } from "../../../Common/consts/faqToolOptions";
import { useSelector } from "react-redux";
import { selectHeiUser } from "../../../Auth/store/accountSlice";
import { useMemo } from "react";
import usePageTitle from "../../../Common/hooks/usePageTitle";

const CreateFaqArticle = (): JSX.Element => {
  usePageTitle("FAQ | Create");
  const navigate = useNavigate();
  const heiUser = useSelector(selectHeiUser);

  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );
  const { form, submit, isLoading } = useCreateFaq();
  const { control, getValues } = form;

  if (!isAdmin) return <></>;

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      ></Backdrop>
      <TopBar>
        <Box>
          <Typography variant="h4">Create article</Typography>
        </Box>
      </TopBar>
      <div className={styles.createFaqView}>
        <HeiTextField
          name={"title"}
          label="Title"
          control={control}
          rules={{
            required: "Title is required",
          }}
        />
        <HeiTextField
          name={"synopsis"}
          label="Synopsis"
          control={control}
          rules={{
            required: "Synopsis is required",
          }}
        />
        <HeiSelection
          options={faqToolOptions.map((f) => f.toolName)}
          getValues={getValues}
          fieldName={"tools"}
          label="Tools"
          multiple
          control={control}
        />
        <HeiTextField
          name={"alertTitle"}
          label="Alert title"
          control={control}
        />
        <HeiTextField name={"alertBody"} label="Alert body" control={control} />
        <HeiRichTextField
          name={"article"}
          label="Article"
          control={control}
          rules={{
            required: "Article is required",
          }}
        />
        <div className={styles.actionButtons}>
          <Button onClick={() => submit()} variant="contained">
            Create
          </Button>
          <Button onClick={() => navigate(-1)}>Cancel</Button>
        </div>
      </div>
    </>
  );
};

export default CreateFaqArticle;
