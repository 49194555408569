import { Checkbox, TableCell, TableRow } from "@mui/material";
import EntityDeleteToggleButton from "../ToolDeleteToggleButton/EntityDeleteToggleButton";
import styles from "./ProjectUserTableRow.module.scss";
import ProjectUserDetailsFieldsTableRow from "../ProjecUserDetailsFieldsTableRow/ProjectUserDetailsFieldsTableRow";
import { UseFormReturn } from "react-hook-form";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import GridTools from "../../../ProjectsList/components/GridTools/GridTools";
import { ToolCapability, User } from "../../../../Common/models/FormModels";
import { CapabilityTool } from "../../../../Common/models/tools";
import { Roles } from "../../../../Common/models/roles";
import { EntityState } from "../../../../Common/api/models/EntityState";

interface ProjectUserTableRowProps {
  form: UseFormReturn<ProjectFormType>;
  userOpen: boolean;
  user: User;
  rowIndex: number;
  userCanEdit: boolean;
  handleDelete: (index: number) => void;
  ToggleItemButton: React.ReactNode;
}

const ProjectUserTableRow = ({
  form,
  userOpen,
  user,
  rowIndex,
  userCanEdit,
  handleDelete,
  ToggleItemButton,
}: ProjectUserTableRowProps): JSX.Element => {
  const getGridTools = (toolsCaps: ToolCapability[]) => {
    return toolsCaps
      .filter((tc) => tc.hasAccess)
      .map((tc) => {
        return tc.capabilityToolKey as CapabilityTool;
      });
  };

  const getRolesLabel = (roles: Roles[]) => {
    return roles.length > 1 ? "Multiple" : roles[0];
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {user.name}
          </div>
        </TableCell>
        <TableCell> {user.adPrinciple}</TableCell>
        <TableCell> {user.email}</TableCell>
        <TableCell>{getRolesLabel(user.roles)}</TableCell>
        <TableCell>
          <GridTools tools={getGridTools(user.toolCaps as ToolCapability[])} />
        </TableCell>
        <TableCell sx={{ p: 0, pl: 2, height: "100%" }}>
          <div className={styles.userDetails__collapseCell}>
            <div>
              {userCanEdit && (
                <EntityDeleteToggleButton
                  entityName={"user"}
                  entityStatus={user.editStatus as EntityState}
                  onClick={() => handleDelete(rowIndex)}
                />
              )}
            </div>
            {ToggleItemButton}
          </div>
        </TableCell>
      </TableRow>
      <ProjectUserDetailsFieldsTableRow
        form={form}
        userOpen={userOpen}
        index={rowIndex}
        userCanEdit={userCanEdit}
        isEdit
      />
    </>
  );
};

export default ProjectUserTableRow;
