import axios, { AxiosRequestConfig } from "axios";

import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTokenAndStore } from "../../Auth/utils/jwtUtils";
import { useNavigate } from "react-router-dom";
import routes from "../../Common/consts/routes";
import { useLocation } from "react-router-dom";
import { generatePath } from "react-router";

export const useAppEntry = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const [tokenSetup, setTokenSetup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const registerInterceptor = (jwt: string) => {
    const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${jwt}`;
      return config;
    };

    axios.interceptors.request.use(onRequest);
  };

  const getJwt = useCallback(async () => {
    const jwt = await getTokenAndStore(instance, accounts[0]);
    // const userRoles = getUserRoles(jwt);

    registerInterceptor(jwt);
    setTokenSetup(true);
  }, [accounts, dispatch, instance]);

  useEffect(() => {
    getJwt();

    if (currentPath === "/")
      navigate(
        generatePath(routes.projects, {
          tab: "my",
        })
      );
  }, [getJwt]);

  return {
    tokenSetup,
  };
};
