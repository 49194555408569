import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { generatePath, useNavigate } from "react-router-dom";
import routes from "../../../Common/consts/routes";
import {
  getProjectDetails,
  getUserProjectRoles,
  removeProject
} from "../api/projectDetailsHttpClient";

export type ProjectFinalizeFormType = {
  toolProjectId: string,
  toolProjectDeeplink: string,
  toolId: string,
  capabilityId: string
};

const useProjectDetails = (id: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isRemoving, setIsRemoving] = useState(false);
  const navigate = useNavigate();
  const getProjectDetailsCallback = useCallback(
    () => getProjectDetails(id),
    [id]
  );

  const getUserProjectRolesCallback = useCallback(
    () => getUserProjectRoles(id),
    [id]
  );

  const { data: projectDetails, isLoading: isLoadingProjectDetails } = useQuery(
    ["project_details", id],
    getProjectDetailsCallback
  );

  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(
    ["user_project_permissions", id],
    getUserProjectRolesCallback
  );

  const handleRemove = async () => {
    setIsRemoving(true);
    try {
      await removeProject(id);

      enqueueSnackbar("Project has been removed", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });

      navigate(
        generatePath(routes.projects, {
          tab: "my",
        })
      );
    } catch {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } finally {
      setIsRemoving(false);
    }
  };

  return {
    projectDetails,
    permissions,
    handleRemove,
    isLoading: isLoadingProjectDetails || isLoadingPermissions || isRemoving,
  };
};

export default useProjectDetails;
