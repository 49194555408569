import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import GrantAccessButtons from "../GrantAccessButtons/GrantAccessButtons";
export const getPendingRequestsColumns = (): GridColDef[] => {
  return [
    {
      field: "icon",
      headerName: "",
      width: 30,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <AccessTimeRoundedIcon color={"info"} />;
      },
    },
    {
      field: "memberName",
      headerName: "Name",
      disableColumnMenu: true,
      flex: 0.8,
    },
    {
      field: "memberEmail",
      headerName: "Email",
      flex: 0.9,
      disableColumnMenu: true,
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 0.9,
      disableColumnMenu: true,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.9,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Grant Access",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <GrantAccessButtons
            projectId={params.row.projectId}
            memberId={params.row.memberId}
          />
        );
      },
    },
  ];
};
