import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { msalConfig } from "./Auth/configs/authConfig";

import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from "./App/store/store";
import AppEntry from "./App/views/AppEntry";
import theme from "./Layout/utils/theme";

const container = document.getElementById("root");
const root = createRoot(container!);
const pca = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: window.configuration.appInsightsInstrumentationKey,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();
root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
            >
              <BrowserRouter>
                <SnackbarProvider maxSnack={5}>
                  <AppEntry appInsights={appInsights} />
                </SnackbarProvider>
              </BrowserRouter>
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </>
);
