import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useSelfonboarding } from "../../hooks/useSelfonboarding";
import styles from "../../../ProjectsList/components/JoinProjectButton/JoinProjectButton.module.scss";

interface GrantAccessButtonsProps {
  projectId: string;
  memberId: string;
}
const GrantAccessButtons = ({
  projectId,
  memberId,
}: GrantAccessButtonsProps) => {
  const { submitHandler, isSaving } = useSelfonboarding(projectId, memberId);
  const [accessGranted, setAccessGranted] = useState<boolean | null>(null);

  const handleDecision = async (isApproved: boolean) => {
    setAccessGranted(isApproved);
    await submitHandler(isApproved);
  };

  const buttonLabel = isSaving
    ? "PROCESSING"
    : accessGranted
    ? "APPROVED"
    : "DENIED";

  return (
    <div>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
      >
        {accessGranted !== null ? (
          <Button disabled={true}>
            {buttonLabel}
            {isSaving && (
              <CircularProgress
                size={24}
                color={"inherit"}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        ) : (
          <>
            <Button onClick={() => handleDecision(true)}>YES</Button>
            <Button onClick={() => handleDecision(false)}>NO</Button>
          </>
        )}
      </ButtonGroup>
    </div>
  );
};

export default GrantAccessButtons;
