import axios from "axios";
import { Faq } from "./models/faq";
import qs from "qs";

export const getFaqs = async (
  search?: string,
  tools?: string[]
): Promise<Faq[]> => {
  const { data } = await axios.get<Faq[]>(
    `${window.configuration.apiUrl}api/Faq`,
    {
      params: {
        search,
        tools,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );

  return data;
};
