import { useState } from "react";
import { JoinProjectRequest } from "../api/models/JoinProjectRequest";
import { useSnackbar, VariantType } from "notistack";
import { postJoinRequest } from "../api/openProjectsHttpClient";

export const useJoinOpenProjects = (
  isProvisioningFromApi: boolean,
  callForceRefresh: any
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isProvisioning, setIsProvisioning] = useState(isProvisioningFromApi);
  const [isSaving, setIsSaving] = useState(false);

  const showSnackbar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  };

  const handleSentJoinOpenProjectHandler = async (
    request: JoinProjectRequest
  ) => {
    setIsSaving(true);

    try {
      await postJoinRequest(request);
      showSnackbar("Your request to join project has been sent.", "success");
      setIsProvisioning(true);
    } catch {
      showSnackbar(
        "Your request cannot be sent. Please contact administrator.",
        "error"
      );
      setIsProvisioning(false);
    }
    callForceRefresh.current();
    setIsSaving(false);
  };

  return { isProvisioning, isSaving, handleSentJoinOpenProjectHandler };
};
