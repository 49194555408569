import { Typography } from "@mui/material";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import { useParams } from "react-router-dom";
import RequestsTabPanels from "../components/RequestsTabPanels/RequestsTabPanels";
import { tabs } from "../api/models/RequestsTabs";

const RequestsList = () => {
  const { tab } = useParams();
  return (
    <>
      <TopBar>
        <Typography variant="h4">Requests</Typography>
      </TopBar>

      <RequestsTabPanels routeTabIndex={tabs.indexOf(tab || "pending")} />
    </>
  );
};

export default RequestsList;
