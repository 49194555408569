import { useEffect, useState } from "react";
import { generateNewBilling, getBillings } from "../api/billingClient";
import { BillingDto } from "../api/models/billingDto";

export const useAdminBillings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [billings, setBillings] = useState<BillingDto[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);

  const fetchBillings = async () => {
    setIsLoading(true);
    try {
      const billings = await getBillings();
      setBillings(billings);
    } finally {
      setIsLoading(false);
    }
  };

  const generateBilling = async () => {
    setIsGenerating(true);
    try {
      await generateNewBilling();
    } finally {
      setIsGenerating(false);
      fetchBillings();
    }
  };

  useEffect(() => {
    fetchBillings();
  }, []);

  return {
    fetchBillings,
    generateBilling,
    billings,
    isLoading,
    isGenerating,
  };
};
