import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useProjectCostGrid } from "../../../hooks/useProjectCostGrid";
import ProjectCostGrid from "../../../components/ProjectCostGrid/ProjectCostGrid";
import usePageTitle from "../../../../../Common/hooks/usePageTitle";

interface ProjectCostTabProps {
  id: string;
}

const ProjectCostsTab = ({ id }: ProjectCostTabProps) => {
  const { projectCost, isLoading } = useProjectCostGrid(id);
  usePageTitle("Project | Costs");
  return (
    <Box sx={{ pl: "80px", pt: "24px", height: "100%", width: "90%" }}>
      <Typography variant={"h6"}>Costs</Typography>
      <Typography variant={"body2"} sx={{ marginBottom: "16px" }}>
        Overview of the estimated costs. To change your billing type, go to the
        Details tab.
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {projectCost && (
        <ProjectCostGrid
          projectCostResponse={projectCost}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default ProjectCostsTab;
