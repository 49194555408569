import { Card, Typography } from "@mui/material";
import { faqToolOptions } from "../../../../Common/consts/faqToolOptions";
import FaqToolButton from "../FaqToolButton/FaqToolButton";
import styles from "./FaqToolsFilter.module.scss";
interface FaqToolsFilterProps {
  toggleToolSelect: (tool: string) => void;
  tools: string[];
}

const FaqToolsFilter = ({
  toggleToolSelect,
  tools,
}: FaqToolsFilterProps): JSX.Element => {
  return (
    <Card className={styles.faqToolsFilter}>
      <div className={styles.faqToolsFilter__header}>
        <Typography variant="h6">Filter articles by tool</Typography>
      </div>
      <div className={styles.faqToolsFilter__content}>
        {faqToolOptions.map((t) => (
          <FaqToolButton
            key={t.toolKey}
            {...t}
            isSelected={tools.some((tool) => tool === t.toolName)}
            toggleToolSelect={toggleToolSelect}
          />
        ))}
      </div>
    </Card>
  );
};

export default FaqToolsFilter;
