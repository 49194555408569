import axios from "axios";
import { Package } from "../models/Package";


export const getPackages = async (): Promise<Package[]> => {
  const { data: packages } = await axios.get<Package[]>(
    `${window.configuration.apiUrl}api/Projects/packages`
  );
  
  return packages;
};
