import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { getTools } from "../../Projects/CreateProject/api/toolsHttpClient";
import { ToolModel } from "../../Projects/CreateProject/models/ToolTile";

export const selectedToolsAtom = atom<ToolModel[]>([]);

export const useToolsForSelect = (packageId: string) => {
  const [tools, setTools] = useState<ToolModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTools, setSelectedTools] = useAtom(selectedToolsAtom);

  useEffect(() => {
    setIsLoading(true);
    getTools(packageId)
      .then((tools) => {
        setTools(tools);
        if (selectedTools.length === 0) {
          setSelectedTools(tools.filter((x) => !x.isOptional));
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const toggleToolSelect = (tool: ToolModel) => {
    if (
      selectedTools.filter(
        (t) => t.capabilityToolKey === tool.capabilityToolKey
      ).length > 0
    ) {
      setSelectedTools([
        ...selectedTools.filter(
          (t) => t.capabilityToolKey !== tool.capabilityToolKey
        ),
      ]);
    } else {
      setSelectedTools([...selectedTools, tool]);
    }
  };

  return {
    allTools: tools,
    tools: tools.filter((x) => !x.isOptional),
    optionalTools: tools.filter((x) => x.isOptional),
    isLoading,
    selectedTools,
    toggleToolSelect,
  };
};
