import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import DownloadBillingButton from "./DownloadBillingButton";

export const getColumns = (): GridColDef[] => {
  const baseColumns = [
    {
      field: "calculationDate",
      headerName: "Calculation Date",
      type: "date",
      flex: 0.8,
      renderCell: (params: GridRenderCellParams<string>) => {
        const calculationDate = params.row.calculationDate;
        const formattedDate = dayjs(calculationDate).format(
          "DD-MM-YYYY HH:mm:ss"
        );

        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.8,
    },
    {
      field: "id",
      headerName: "Download",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        const id = params.row.id;
        const calculationDate = params.row.calculationDate;
        const formattedDate = dayjs(calculationDate).format("YYYY-MM");
        const fileName = `Billing-${formattedDate}-SDLC.csv`;
        return <DownloadBillingButton fileName={fileName} id={id} />;
      },
    },
  ];

  return baseColumns;
};
