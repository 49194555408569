import * as React from "react";

import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  SxProps,
  Theme,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormGetValues,
} from "react-hook-form";

interface HeiSelectionProps<TForm extends FieldValues> {
  sx?: SxProps<Theme>;
  control: Control<TForm>;
  fieldName: Path<TForm>;
  getValues: UseFormGetValues<TForm>;
  options: string[];
  label: string;
  multiple: boolean;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  disabled?: boolean;
}

const HeiSelection = <TForm extends FieldValues>({
  sx,
  control,
  fieldName,
  getValues,
  options,
  label,
  multiple,
  rules,
  disabled,
}: HeiSelectionProps<TForm>): JSX.Element => {
  const required = rules?.required;
  return (
    <div>
      <Controller
        control={control}
        name={fieldName}
        rules={rules}
        render={({ field, fieldState }) => {
          const error = fieldState.error;
          const errorMessage = error?.message;
          return (
            <FormControl
              sx={
                sx || {
                  fontSize: "14px",
                  width: "180px",
                  marginRight: "8px",
                }
              }
              error={error ? true : false}
              variant="outlined"
              size="small"
            >
              <InputLabel shrink>
                {label}
                {required ? "*" : ""}
              </InputLabel>
              <Select
                {...field}
                onClose={() => {
                  setTimeout(() => {
                    if (document) {
                      (document.activeElement as HTMLElement).blur();
                    }
                  }, 0);
                }}
                multiple={multiple}
                fullWidth
                required={!!required}
                error={error !== undefined}
                disabled={disabled}
                defaultValue={[]}
                input={<OutlinedInput notched label={label} />}
                renderValue={(selected) => {
                  return multiple
                    ? selected.length === 1
                      ? selected
                      : "Multiple"
                    : selected;
                }}
              >
                {options.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox
                      disabled={disabled}
                      checked={getValues(fieldName).indexOf(option) > -1}
                    />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              {error ? <FormHelperText>{errorMessage}</FormHelperText> : null}
            </FormControl>
          );
        }}
      />
    </div>
  );
};
export default HeiSelection;
