import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { EntityState } from "../../../../Common/api/models/EntityState";

interface ToolDeleteToggleButtonProps {
  entityName: string;
  entityStatus: EntityState;
  onClick: () => void;
}

const EntityDeleteToggleButton = ({
  entityName,
  entityStatus,
  onClick,
}: ToolDeleteToggleButtonProps): JSX.Element => {
  const isRestore = useMemo(
    () => entityStatus === EntityState.Delete,
    [entityStatus]
  );

  return (
    <Tooltip
      title={
        isRestore ? `Restore ${entityName}` : `Mark ${entityName} to remove`
      }
      arrow
      placement="top"
    >
      <IconButton aria-label="delete" onClick={onClick}>
        {isRestore ? <RestoreFromTrashIcon /> : <DeleteOutlineIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default EntityDeleteToggleButton;
