import { ToolCapability } from "../../../../Common/models/FormModels";
import { UseFormReturn } from "react-hook-form";
import HeiCheckbox from "../../../../Common/components/Form/HeiCheckbox/HeiCheckbox";
import HeiGroupButton from "../../../../Common/components/Form/HeiGroupButton/HeiGroupButton";
import ToolIcon from "../../../../Common/components/ToolIcon/ToolIcon";
import { SelectOption } from "../../../../Common/models/form";
import { IconSize } from "../../../../Common/models/iconSize";
import { CapabilityTool } from "../../../../Common/models/tools";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import styles from "./ToolAccess.module.scss";
import { useMemo } from "react";
import { Roles } from "../../../../Common/models/roles";
import { FormLabel, Tooltip } from "@mui/material";
import { Warning } from "@mui/icons-material";

interface ToolAccessProps {
  toolIndex: number;
  userIndex: number;
  tool: ToolCapability;
  form: UseFormReturn<ProjectFormType>;
  userCanEdit: boolean;
}

const ToolAccess = ({
  tool,
  toolIndex,
  userIndex,
  form,
  userCanEdit,
}: ToolAccessProps): JSX.Element => {
  const { control, watch } = form;

  const userRoles = watch(`users.${userIndex}.roles`);

  const displayToolRoles = useMemo(
    () =>
      !(
        tool.capabilityToolKey === "MONITORING:NEWRELIC" &&
        !userRoles.includes(Roles["Team Member"])
      ),

    [userRoles, tool]
  );

  const toolCheckboxDisabled = !userCanEdit || !tool.toolCreationCompleted;
  const toolCheckboxLabel = tool.toolDisplayName ?? tool.toolName;

  return (
    <div key={toolIndex}>
      <div className={styles.toolAccess}>
        <HeiCheckbox
          disabled={toolCheckboxDisabled}
          control={control}
          name={`users.${userIndex}.toolCaps.${toolIndex}.hasAccess`}
          label={""}
        />
        <div className={styles.toolAccess__toolIcon}>
          <ToolIcon
            tool={tool.capabilityToolKey as CapabilityTool}
            size={IconSize.Medium}
            tooltipTitle={tool.toolName}
          />
        </div>
        <FormLabel
          disabled={toolCheckboxDisabled}
          className={styles.toolAccess__toolName}
        >
          {toolCheckboxLabel}
          {!tool.toolCreationCompleted && (
            <Tooltip
              title={`${toolCheckboxLabel} is currently pending creation. Adding new members is restricted until the tool is fully provisioned. You will be able to add users once the provisioning process is complete.`}
              arrow
            >
              <Warning sx={{ ml: 1 }} fontSize="small" color="warning" />
            </Tooltip>
          )}
        </FormLabel>
      </div>

      {tool.availableRoles?.length > 0 && displayToolRoles && tool.hasAccess && (
        <table className={styles.toolAccess__toolsTable}>
          <tr>
            <td>
              Invite user for {tool.toolName} as a:
              {tool.availableRoles?.map((roles) => {
                return (
                  <div>
                    {roles.name} ({roles.cost})
                  </div>
                );
              })}
            </td>
            <td className={styles.toolAccess__toolsTable___itemAlight}>
              <HeiGroupButton
                disabled={!userCanEdit}
                control={form.control}
                options={tool.availableRoles?.map(
                  (r) => ({ label: r.name, value: r.name } as SelectOption)
                )}
                name={`users.${userIndex}.toolCaps.${toolIndex}.toolCustomRole`}
              />
            </td>
          </tr>
        </table>
      )}
    </div>
  );
};

export default ToolAccess;
