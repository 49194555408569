import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectHeiUser } from "../../../Auth/store/accountSlice";
import usePageTitle from "../../../Common/hooks/usePageTitle";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import UpdateFaqArticle from "../../UpdateFaqArticle/views/UpdateFaqArticle";
import FaqArticleBody from "../components/FaqArticleBody/FaqArticleBody";
import { useFaqArticle } from "../hooks/useFaqArticle";
import styles from "./FaqArticle.module.scss";
import { DeleteFaqArticleModalWindow } from "../components/DeleteFaqArticleModalWindow/DeleteFaqArticleModalWindow";

const FaqArticle = () => {
  const { id } = useParams();
  const { faqArticle, isLoading, submitRating, submitDeleteArticle } = useFaqArticle(id as string);
  usePageTitle(`FAQ | ${faqArticle?.faqArticle.title}`);

  const navigate = useNavigate();

  const heiUser = useSelector(selectHeiUser);

  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );

  const [isInEditMode, setIsInEditMode] = useState(false);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TopBar>
        <div className={styles.topBar}>
          <Box>
            {!isInEditMode && (
              <Link href="#" underline="none" onClick={() => navigate(-1)}>
                <div className={styles.faqArticleBar}>
                  <ArrowBackOutlinedIcon fontSize={"small"} />
                  <span>Back</span>
                </div>
              </Link>
            )}

            <Typography variant="h4">{faqArticle?.faqArticle.title}</Typography>
          </Box>
          <Box>
          {isAdmin && !isInEditMode && (
            <Button onClick={() => setIsInEditMode(true)}>
              UPDATE Article
            </Button>
          )}
             {isAdmin  && (
              <Button color="error" onClick={()=> setIsAddUserModalOpen(true)}>
                DELETE Article
              </Button>
            )}
            </Box>
        </div>
        
      </TopBar>
            
      <DeleteFaqArticleModalWindow 
        open={isAddUserModalOpen} 
        handleClose={()=> setIsAddUserModalOpen(false)} 
        handleDelete={() => submitDeleteArticle(id as string)}/>


      {faqArticle && !isInEditMode && (
        <div className={styles.faqArticle}>
          <FaqArticleBody faqArticle={faqArticle} submitRating={submitRating} />
        </div>
      )}

      {faqArticle && isInEditMode && (
        <div className={styles.faqArticle}>
          <UpdateFaqArticle
            faqArticle={faqArticle}
            handleCancel={setIsInEditMode}
          />
        </div>
      )}
    </>
  );
};

export default FaqArticle;
