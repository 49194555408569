import {
  Autocomplete,
  IconButton,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from "react-hook-form";
import { getOptions } from "../../../api/asyncTypeaheadClient";
import { SelectOption } from "../../../models/form";
import * as DOMPurify from "dompurify";
import ClearIcon from "@mui/icons-material/Clear";

interface HeiAsyncTypeaheadProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  label: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  endpoint: string;
  title?: string;
  description?: string;
  disabled?: boolean;
}

const HeiAsyncTypeahead = <TForm extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  sx,
  rules,
  endpoint,
  title,
  description,
  disabled,
}: HeiAsyncTypeaheadProps<TForm>): JSX.Element => {
  const required = rules?.required;

  const [options, setOptions] = useState([] as SelectOption[]);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = async (value: string) => {
    if (value.length < 2) return;
    setIsLoading(true);
    const fetchedOptions = await getOptions(endpoint, value);
    setOptions(fetchedOptions);
    setIsLoading(false);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ fieldState, field: { ref, onChange, ...field } }) => {
        const error = fieldState.error;
        const errorMessage = error?.message;
        const [autoCompleteValue, setAutoCompleteValue] = useState(
          field.value as string | undefined | SelectOption
        );
        const onClear = (event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setAutoCompleteValue(null as PathValue<TForm, Path<TForm>>);
          onChange(undefined);
        };
        return (
          <>
            {title && (
              <Typography
                variant="body2"
                sx={{ color: "#202020", mb: 1, mt: 1 }}
              >
                {title}
              </Typography>
            )}
            {description && (
              <Typography variant="body2" sx={{ mb: 3 }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(description),
                  }}
                />
              </Typography>
            )}
            <Autocomplete
              disabled={disabled}
              sx={{ mb: 3, width: "70% !important" }}
              loading={isLoading}
              autoComplete
              value={autoCompleteValue}
              onChange={(_, data) => {
                setAutoCompleteValue((data as SelectOption)?.value);
                onChange((data as SelectOption)?.value);
              }}
              // onChange={(_, data) => onChange(data?.value)}
              onInputChange={(event, newInputValue) =>
                handleInputChange(newInputValue)
              }
              clearIcon={
                <IconButton sx={{ p: 0 }} onClick={onClear}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              }
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  fullWidth
                  inputRef={ref}
                  required={!!required}
                  InputLabelProps={{ shrink: true }}
                  sx={sx}
                  label={label}
                  variant="outlined"
                  placeholder={placeholder}
                  error={error !== undefined}
                  helperText={errorMessage}
                />
              )}
            />
          </>
        );
      }}
    />
  );
};

export default HeiAsyncTypeahead;
