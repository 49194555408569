import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import usePageTitle from "../../../Common/hooks/usePageTitle";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import AdminOpcosGrid from "../components/AdminOpcosGrid/AdminOpcosGrid";
import { useAdminOpcos } from "../hooks/useAdminOpcos";
import styles from "../views/AdminOpcosView.module.scss";

const AdminOpcosView = () : JSX.Element => {
    const { fetchData, isLoading, setIsLoading, opcos } = useAdminOpcos();
    usePageTitle("Admin | OpCo's and managers");

    useEffect(() => {fetchData();}, []);

    return (
        <div id="adminopcos" data-testid="adminopcos" style={{ height: "100%" }}>
            <TopBar>
                <div className={styles.details__topBarContainer}>
                <Typography variant="h4">OpCos and Managers</Typography>
                </div>
            </TopBar>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <AdminOpcosGrid opcos={opcos} reloadData={fetchData} setIsLoading={setIsLoading} />
        </div>
    );
};

export default AdminOpcosView;