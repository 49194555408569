import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import { tabs } from "../api/models/PojectsTabs";
import ProjectsTabPanels from "../components/ProjectsTabPanels/ProjectsTabPanels";

const ProjectList = () => {
  const { id, tab } = useParams();
  return (
    <>
      <TopBar>
        <Typography variant="h4">Projects</Typography>
      </TopBar>

      <ProjectsTabPanels routeTabIndex={tabs.indexOf(tab || "my")} />
    </>
  );
};

export default ProjectList;
