import axios from "axios";
import { AdminOpcoDto, AdminOpcoManagerDto } from "./models/AdminOpcoDto";

export const getOpcos = async (): Promise<AdminOpcoDto[]> => {
  const { data: opcos } = await axios.get<AdminOpcoDto[]>(
    `${window.configuration.apiUrl}api/opco/getadminopcos`,
  );

  return opcos;
};

export const saveOpco = async (opco: AdminOpcoDto): Promise<boolean> => {
  const { data: result } = await axios.put<boolean>(
    `${window.configuration.apiUrl}api/opco/saveopco`,
    {id: opco.id, name: opco.name, infoRequired: opco.infoRequired}
  );

  return result;
}

export const removeOpco = async (id: string): Promise<boolean> => {
  const { data: result } = await axios.delete<boolean>(
    `${window.configuration.apiUrl}api/opco/removeopco`,
    {data: {id}}
  );

  return result;
}

export const saveOpcoOwner = async (opcoOwner: AdminOpcoManagerDto): Promise<boolean> => {
  const { data: result } = await axios.put<boolean>(
    `${window.configuration.apiUrl}api/opco/saveopcoowner`,
    {opcoId: opcoOwner.opcoId, adPrinciple: opcoOwner.adPrinciple}
  );

  return result;
}

export const removeOpcoOwner = async (opcoOwner: AdminOpcoManagerDto): Promise<boolean> => {
  const { data: result } = await axios.delete<boolean>(
    `${window.configuration.apiUrl}api/opco/removeopcoowner`,
    {data: {opcoId: opcoOwner.opcoId, adPrinciple: opcoOwner.adPrinciple}}
  );

  return result;
}
