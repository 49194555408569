import { useForm } from "react-hook-form";
import { ProjectFormType } from "../../../Common/hooks/useCreateProject";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { postPackageAction } from "../api/projectDetailsHttpClient";

const usePackageActionsForm = (projectId: string, actionId: string) => {
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {} as ProjectFormType;

  const form = useForm<ProjectFormType>({
    defaultValues: defaultValues,
    mode: "all",
  });

  const handlePackageActionSubmit = async () => {
    setIsSaving(true);

    try {
      await postPackageAction(projectId, {
        actionId: actionId,
        customFieldsConfiguration: form.getValues("packageCustomField"),
      });

      enqueueSnackbar("Process started!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch {
      enqueueSnackbar("Process has not been started!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      console.error("Something went wrong");
    } finally {
      setIsSaving(false);
    }
  };

  return {
    form,
    handlePackageActionSubmit,
    isSaving,
  };
};

export default usePackageActionsForm;
