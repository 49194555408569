import { Button, Typography } from "@mui/material";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import FaqResults from "../components/FaqResults/FaqResults";
import FaqSearch from "../components/FaqSearch/FaqSearch";
import FaqToolsFilter from "../components/FaqToolsFilter/FaqToolsFilter";
import useFaqList from "../hooks/useFaqList";
import styles from "./FaqList.module.scss";
import { useNavigate } from "react-router-dom";
import routes from "../../../Common/consts/routes";
import { useSelector } from "react-redux";
import { selectHeiUser } from "../../../Auth/store/accountSlice";
import { useMemo } from "react";
import usePageTitle from "../../../Common/hooks/usePageTitle";

const FaqList = () => {
  const {
    isLoading,
    displayedFaqs,
    hasFilter,
    searchValue,
    setSearchValue,
    toggleToolSelect,
    tools,
  } = useFaqList();
  usePageTitle("FAQ");
  const navigate = useNavigate();
  const heiUser = useSelector(selectHeiUser);

  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );
  return (
    <>
      <TopBar>
        <div className={styles.topBar}>
          <Typography variant="h4">Support</Typography>
          {isAdmin && (
            <Button onClick={() => navigate(routes.createFaqArticle)}>
              CREATE Article
            </Button>
          )}
        </div>
      </TopBar>

      <div className={styles.faqList}>
        <div className={styles.faqList__container}>
          <Typography variant="h6">How can we help you?</Typography>
          <FaqSearch setSearchValue={setSearchValue} />
          <FaqResults
            faqs={displayedFaqs}
            hasFilter={hasFilter}
            searchValue={searchValue}
            isLoading={isLoading}
            tools={tools}
            toggleToolSelect={toggleToolSelect}
          />
        </div>
        <div className={styles.faqList__sideFilters}>
          <FaqToolsFilter toggleToolSelect={toggleToolSelect} tools={tools} />
        </div>
      </div>
    </>
  );
};

export default FaqList;
