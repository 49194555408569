import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import OutlinedFlagRoundedIcon from "@mui/icons-material/OutlinedFlagRounded";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import { ToolStatusEnum } from "../api/models/ProjectTool";

export const toolStatusIcon: Record<ToolStatusEnum, any> = {
  Created: <CheckCircleOutlineRoundedIcon />,
  Archived: <ArchiveOutlinedIcon />,
  NotFound: <LockOutlinedIcon />,
  PendingRemoval: <OutlinedFlagRoundedIcon />,
  CreationPending: <AccessTimeRoundedIcon />,
  New: <HorizontalRuleOutlinedIcon />,
};

export const toolStatusTooltipTitle: Record<ToolStatusEnum, string> = {
  Created: "Active",
  Archived: "Archived",
  NotFound: "Not found",
  PendingRemoval: "Pending removal",
  CreationPending: "Available in 2 days",
  New: "Tool will be added on save",
};
