import { Typography } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import SelfonboardingEditAlert from "../../components/SelfonboardingEditAlert/SelfonboardingEditAlert";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import styles from "./AddUsersStep.module.scss";
import AddUserSection from "../../components/AddUserSection/AddUserSection";
interface AddUsersStepProps {
  form: UseFormReturn<ProjectFormType>;
}
const AddUsersStep = ({ form }: AddUsersStepProps): JSX.Element => {
  const { watch } = form;
  watch("users");

  const shouldRenderSelfonboarding = !form.getValues("hideProject");
  return (
    <div className={styles.addUsers} style={{ marginRight: "80px" }}>
      {shouldRenderSelfonboarding && <SelfonboardingEditAlert form={form} />}
      <Typography variant={"h6"}>Add users</Typography>
      <Typography variant={"body2"} sx={{ marginBottom: "6px" }}>
        Find users to add to this project.
      </Typography>
      <AddUserSection form={form} />
    </div>
  );
};

export default AddUsersStep;
