import { BusinessOutlined } from "@mui/icons-material";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AttachMoneyIconOutlined from "@mui/icons-material/AttachMoneyOutlined";
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import HelpIcon from "@mui/icons-material/Help";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import HttpsIcon from "@mui/icons-material/Https";
import SearchIconOutlined from "@mui/icons-material/SearchOutlined";
import { Box, Collapse } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { selectHeiUser } from "../../../Auth/store/accountSlice";
import routes from "../../../Common/consts/routes";
import MenuFooter from "../MenuFooter/MenuFooter";
import MenuHeader from "../MenuHeader/MenuHeader";
import styles from "./Menu.module.scss";

interface IMenuButton {
  collapsed: boolean;
  Icon: any;
  text: string;
  path?: string;
  isActive: boolean;
}

const MenuButton: React.FunctionComponent<IMenuButton> = ({
  collapsed,
  Icon,
  text,
  path,
  isActive,
}) => {
  return (
    <Link to={path ?? "/not-found"}>
      <ListItemButton
        className={classNames(styles.link, { [styles.activeLink]: isActive })}
      >
        <ListItemIcon className={classNames({ [styles.linkIcon]: collapsed })}>
          <Icon />
        </ListItemIcon>
        {!collapsed && (
          <ListItemText
            sx={{ fontFamily: "Lucida Sans Unicode !important" }}
            primary={text}
          />
        )}
      </ListItemButton>
    </Link>
  );
};

const menuButtons = [
  {
    Icon: HomeRoundedIcon,
    text: "Projects",
    path: generatePath(routes.projects, { tab: "my" }),
  },
  {
    Icon: AddCircleIcon,
    text: "New project",
    path: routes.createProject,
  },
  {
    Icon: HttpsIcon,
    text: "Request access",
    path: generatePath(routes.projects, { tab: "other" }),
  },
  {
    Icon: AccessTimeFilledOutlinedIcon,
    text: "Requests",
    path: generatePath(routes.requests, { tab: "pending" }),
  },
  {
    Icon: HelpIcon,
    text: "Support",
    path: routes.faq,
  },
];

const adminMenuButtons = [
  {
    Icon: SearchIconOutlined,
    text: "User Search",
    path: routes.admin.userSearch
  },
  {
    Icon: BusinessOutlined,
    text: "OpCo's",
    path: routes.admin.opcos
  },
  {
    Icon: AttachMoneyIconOutlined,
    text: "Download billing",
    path: routes.admin.billing
  },
];

interface MenuProps {
  collapsed: boolean;
  setCollapsed: (collasped: boolean) => void;
}
const Menu = ({ collapsed, setCollapsed }: MenuProps) => {
  const location = useLocation();
  const heiUser = useSelector(selectHeiUser);

  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );
  const [adminSectionOpen, setOpen] = useState(true);
  // const [collapsed, setCollapsed] = useState(false);

  const handleClick = () => {
    setOpen(!adminSectionOpen);
  };

  const isActiveLink = (path: string) => {
    return location.pathname.includes(path);
  };
  return (
    <Box
      className={`${styles.menuContainer} ${
        collapsed ? styles.menuContainerCollapsed : ""
      }`}
    >
      <List
        sx={{
          bgcolor: "background.paper",
          height: "100%",
          p: 0,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <MenuHeader collapsed={collapsed} />

        <div className={styles.navigationElements}>
          <div>
            {menuButtons.map((mb, i) => (
              <MenuButton
                key={i}
                collapsed={collapsed}
                Icon={mb.Icon}
                text={mb.text}
                path={mb.path}
                isActive={isActiveLink(mb.path)}
              />
            ))}

            {isAdmin && <>
              <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <EngineeringOutlinedIcon />
              </ListItemIcon>
              <Collapse in={!collapsed} orientation="horizontal">
                <ListItemText primary="Admin" />
              </Collapse>

              {adminSectionOpen ? (
                <ExpandLessOutlinedIcon />
              ) : (
                <ExpandMoreOutlinedIcon  />
              )}
            </ListItemButton>
             <Collapse in={adminSectionOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {adminMenuButtons.map((mb, i) => (
                  <MenuButton
                    key={i}
                    collapsed={collapsed}
                    Icon={mb.Icon}
                    text={mb.text}
                    path={mb.path}
                    isActive={isActiveLink(mb.path)}
                  />
                ))}
              </List>
            </Collapse></>}
          </div>
          <MenuFooter setCollapsed={setCollapsed} collapsed={collapsed} />
        </div>
      </List>
    </Box>
  );
};

export default Menu;
