import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Button, TableCell, TableRow } from "@mui/material";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormGetValues,
} from "react-hook-form";
import { roleValidator } from "../../../../Common/validators/roleValidator";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import { RolesForSelect } from "../../../../Common/models/RolesForSelect";
import HeiSelection from "../../../../Common/components/HeiSelection/HeiSelection";

interface UserTableRowProps {
  control: Control<ProjectFormType, any>;
  user: FieldArrayWithId<ProjectFormType, "users", "id">;
  index: number;
  remove: UseFieldArrayRemove;
  getValues: UseFormGetValues<ProjectFormType>;
}

export const UserTableRow = ({
  control,
  index,
  user,
  remove,
  getValues,
}: UserTableRowProps) => {
  return (
    <TableRow key={user.id}>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.adPrinciple}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <HeiSelection
          control={control}
          fieldName={`users.${index}.roles`}
          getValues={getValues}
          options={RolesForSelect}
          label={"Roles"}
          multiple={true}
          rules={{
            required: "Please select at least 1 role for this user.",
            validate: (value: string[] | any) =>
              roleValidator(value, getValues("users")),
          }}
        />
      </TableCell>
      <TableCell>
        <Box display={"flex"} justifyContent="flex-end">
          <Button>
            <CloseOutlinedIcon
              color="secondary"
              onClick={() => remove(index)}
            />
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
};
