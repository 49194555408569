import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, IconButton } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

const useMultiCollapse = (getItemsFunc: () => number[], collapse?: boolean) => {
  const items: number[] = getItemsFunc();
  const [itemsCollapse, setItemsCollapse] = useState<Record<number, boolean>>(
    items.reduce((acc, curr) => ({ ...acc, [curr]: collapse ?? true }), {})
  );

  const canCollapseAll = useMemo(
    () => Object.values(itemsCollapse).some((c) => c),
    [itemsCollapse]
  );

  const toggleCollapseAll = useCallback((collapse: boolean) => {
    setItemsCollapse(
      getItemsFunc().reduce((acc, curr) => ({ ...acc, [curr]: collapse }), {})
    );
  }, []);

  const toggleItemOpen = useCallback(
    (index: number) => {
      setItemsCollapse((current) => ({
        ...current,
        [index]: !itemsCollapse[index],
      }));
    },
    [itemsCollapse]
  );

  const ToggleCollapseButton = () => (
    <Button
      variant="text"
      size="large"
      sx={{ m: 0, p: 0 }}
      onClick={() => toggleCollapseAll(!canCollapseAll)}
    >
      {canCollapseAll ? "Collapse all" : "Expand all"}
    </Button>
  );

  const ToggleItemButton = ({ index }: { index: number }) => (
    <IconButton
      sx={{
        background: "#f5f5f5",
        borderRadius: 0,
        height: "100%",
        aspectRatio: "1 !important",
        borderLeft: "1px solid #e5e5e5",
      }}
      onClick={() => toggleItemOpen(index)}
      aria-label="collapse row"
      color="secondary"
    >
      {itemsCollapse[index] ? (
        <KeyboardArrowUpIcon />
      ) : (
        <KeyboardArrowDownIcon />
      )}
    </IconButton>
  );

  const reloadItems = () => {
    const newItems: number[] = getItemsFunc();
    setItemsCollapse(newItems.reduce((acc, curr) => ({ ...acc, [curr]: collapse ?? false }), {}));
  }

  return {
    ToggleCollapseButton,
    ToggleItemButton,
    toggleItemOpen,
    itemsCollapse,
    reloadItems
  };
};

export default useMultiCollapse;
