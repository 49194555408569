import axios from "axios";
import { FetchDataRequest, FetchDataResult } from "../../../Common/models/grid";
import { SieveResponse } from "../../../Common/models/sieve";
import {
  getSieveRequestModel,
  mapToFetchDataResult,
} from "../../../Common/utils/sieveRequestModel";

export const getMyProjects = async (
  request: FetchDataRequest
): Promise<FetchDataResult> => {
  const sieveRequest = getSieveRequestModel(request);
  const { data } = await axios.get<SieveResponse>(
    `${window.configuration.apiUrl}api/Projects`,
    { params: sieveRequest }
  );

  const response = mapToFetchDataResult(data);
  return response;
};

export const getProjectsCostCenterCount = async (
  projectId: string
): Promise<number> => {
  const { data } = await axios.get<number>(
    `${window.configuration.apiUrl}api/Projects/${projectId}/sameCostCenterCount`
  );

  return data;
};

export const unflagProject = async (
  projectId: string,
  unflag: boolean,
  includeAllWithSameCostCenter: boolean
): Promise<void> => {
  await axios.put(
    `${window.configuration.apiUrl}api/Projects/${projectId}/setFlag`,
    {
      unflag,
      includeAllWithSameCostCenter,
    }
  );
};

export const toggleLock = async (
  projectId: string,
  isLocked: boolean
): Promise<void> => {
  await axios.put(
    `${window.configuration.apiUrl}api/Projects/${projectId}/toggleLock`,
    {
      isLocked
    }
  );
};