import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import useMultiCollapse from "../../../../Common/hooks/useMultiCollapse";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import styles from "./ProjectUsersTable.module.scss";
import ProjectUserTableRow from "../ProjectUserTableRow/ProjectUserTableRow";
import { ToolCapability, User } from "../../../../Common/models/FormModels";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  FetchDataRequest,
  FetchDataResult,
} from "../../../../Common/models/grid";
import { getProjectUsersTableColumns } from "./getProjectUsersTableColumns";
import { Order } from "../../api/models/ProjectUsersTable";
import { TableSortFilterForPagination } from "../../api/models/Table";
import { EntityState } from "../../../../Common/api/models/EntityState";
import { ProjectTool } from "../../../../Common/api/models/ProjectTool";
interface ProjectUsersTableProps {
  fetchTableData: (
    projectId: string,
    request: FetchDataRequest
  ) => Promise<FetchDataResult>;
  form: UseFormReturn<ProjectFormType>;
  handleDelete: (index: number) => void;
  projectId: string;
  searchValue: string;
  tableSortFilterForPagination: TableSortFilterForPagination;
  totalRows: number;
  setTotalRows: Dispatch<SetStateAction<number>>;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof User
  ) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  userCanEdit: boolean;
  projectTools: ProjectTool[];
}

const ProjectUsersTable = ({
  projectId,
  userCanEdit,
  form,
  handleDelete,
  searchValue,
  fetchTableData,
  tableSortFilterForPagination,
  totalRows,
  setTotalRows,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  projectTools,
}: ProjectUsersTableProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const { ToggleCollapseButton, ToggleItemButton, itemsCollapse } =
    useMultiCollapse(() => {
      return form.getValues("users").map((_, i) => i);
    });
  const columns = getProjectUsersTableColumns();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const { rows, totalRows } = await fetchTableData(projectId, {
      pagination: {
        pageNumber: tableSortFilterForPagination.page,
        pageSize: tableSortFilterForPagination.rowsPerPage,
      },
      sorting: {
        field: tableSortFilterForPagination.orderBy,
        sort: tableSortFilterForPagination.order,
      },
      search: searchValue,
    });

    const users = rows.map((u: User) => ({
      ...u,
      editStatus: EntityState.NotChanged,
      toolCaps: u.toolCaps?.map((tc: ToolCapability) => ({
        ...tc,
        toolCreationCompleted: getToolCreationCompleted(
          tc.toolId,
          tc.toolProjectId
        ),
      })),
    }));
    form.reset({
      users: users,
    } as ProjectFormType);
    setTotalRows(totalRows);
    setIsLoading(false);
  }, [tableSortFilterForPagination, searchValue, fetchTableData]);

  useEffect(() => {
    fetchData();
  }, [tableSortFilterForPagination, searchValue]);

  const getToolCreationCompleted = (toolId: string, toolProjectId: string) =>
    !!toolProjectId ||
    projectTools.find((pt) => pt.toolId === toolId)?.isManual;

  interface EnhancedTableProps {
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof User
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof User) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              sx={{ width: headCell.width }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                <b>{headCell.label}</b>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  <b>{headCell.label}</b>
                </TableSortLabel>
              )}
            </TableCell>
          ))}
          <TableCell>
            <div className={styles.userDetails__collapseHeaderCell}>
              <div className={styles.userDetails__tableHeader}></div>
              <ToggleCollapseButton />
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <TableContainer
        component={Paper}
        sx={{ width: "92%", pb: 5, paddingBottom: 0, marginBottom: "70px" }}
      >
        <Table>
          <EnhancedTableHead
            order={tableSortFilterForPagination.order}
            orderBy={tableSortFilterForPagination.orderBy}
            onRequestSort={handleRequestSort}
            rowCount={form.getValues("users").length}
          />
          <TableBody>
            {form.getValues("users").map((userInProject: User, i) => {
              const userOpen = itemsCollapse[i];
              return (
                <ProjectUserTableRow
                  form={form}
                  handleDelete={handleDelete}
                  rowIndex={i}
                  user={userInProject}
                  userCanEdit={userCanEdit}
                  userOpen={userOpen}
                  key={i}
                  ToggleItemButton={<ToggleItemButton index={i} />}
                />
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={tableSortFilterForPagination.rowsPerPage}
          page={tableSortFilterForPagination.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default ProjectUsersTable;
