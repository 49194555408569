import { ProjectFormType } from "../hooks/useCreateProject";
import { DynamicField, DynamicFieldResponse } from "../models/form";

export const mapCreateProjectFormField = (
  field: DynamicFieldResponse
): DynamicField<ProjectFormType> => {
  return {
    ...field,
    selectOptions: field.options || [],
    name: `packageCustomField.${field.name}`,
    pattern: field.pattern ? new RegExp(field.pattern) : undefined,
  };
};
