import * as React from "react";
import ToolchainLogo from "../../assets/Toolchain.svg";
import ToolchainHeaderLogo from "../../assets/ToolchainHeader.svg";
import styles from "./MenuHeader.module.scss";
interface IMenuHeaderProps {
  collapsed: boolean;
}

const MenuHeader: React.FunctionComponent<IMenuHeaderProps> = ({
  collapsed,
}) => {
  return (
    <div className={styles.header}>
      {collapsed && <img src={ToolchainLogo} alt="React Logo" />}
      {!collapsed && <img src={ToolchainHeaderLogo} alt="React Logo" />}
    </div>
  );
};

export default MenuHeader;
