import { UseFormReturn } from "react-hook-form";
import HeiDynamicField from "../Form/HeiDynamicField/HeiDynamicField";
import { DynamicField } from "../../models/form";
import { ProjectFormType } from "../../hooks/useCreateProject";
import styles from "./ToolCustomFields.module.scss";
import { ToolStatusEnum } from "../../api/models/ProjectTool";

interface ToolCustomFieldsProps {
  fields?: DynamicField<ProjectFormType>[];
  form: UseFormReturn<ProjectFormType>;
  toolIndex: number;
  userCanEdit: boolean;
  toolStatus?: ToolStatusEnum
}

const ToolCustomFields = ({
  fields,
  form,
  toolIndex,
  userCanEdit, toolStatus
}: ToolCustomFieldsProps): JSX.Element => {

  const disabledFields = (fieldName: string) => {
    return fieldName === "ServiceNowGroup" && toolStatus !== ToolStatusEnum.Created
  }

  if (!fields || fields.length === 0) return <></>;

  return (
    <div className={styles.fields}>
      {fields.map((f) => (
        <HeiDynamicField
          key={f.name}
          control={form.control}
          field={{
            ...f,
            name: `tools.${toolIndex}.${f.name}`,
            disabled: !userCanEdit || disabledFields(f.name),
          }}
        />
      ))}
    </div>
  );
};

export default ToolCustomFields;
