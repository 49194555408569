import { InputProps, SxProps, TextField, Theme } from "@mui/material";
import { FocusEventHandler } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";

interface HeiTextFieldProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  label: string;
  placeholder?: string;
  InputProps?: Partial<InputProps>;
  secret?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onBlur?: FocusEventHandler;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  size?: "small" | "medium";
}

const HeiTextField = <TForm extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  InputProps,
  sx,
  rules,
  secret,
  disabled,
  onBlur,
  size,
}: HeiTextFieldProps<TForm>): JSX.Element => {
  const required = rules?.required;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        const error = fieldState.error;
        const errorMessage = error?.message;

        return (
          <TextField
            required={!!required}
            {...field}
            InputLabelProps={{ shrink: true }}
            sx={sx || { width: "45%" }}
            label={label}
            size={size}
            variant="outlined"
            placeholder={placeholder}
            InputProps={InputProps}
            error={error !== undefined}
            onBlurCapture={onBlur}
            helperText={errorMessage}
            type={secret ? "password" : "text"}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export default HeiTextField;
