import { InputProps, SxProps, Theme, Typography } from "@mui/material";

import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import ReactQuill from "react-quill";

interface HeiTextFieldProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  label: string;
  placeholder?: string;
  InputProps?: Partial<InputProps>;
  secret?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const HeiRichTextField = <TForm extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  InputProps,
  sx,
  rules,
  secret,
  disabled,
}: HeiTextFieldProps<TForm>): JSX.Element => {
  const required = rules?.required;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        const error = fieldState.error;
        const errorMessage = error?.message;

        return (
          <>
            <Typography>
              {label}
              {required ? "*" : ""}
            </Typography>
            <ReactQuill
              style={{ height: "500px", backgroundColor: "white"}}
              modules={modules}
              formats={formats}
              theme="snow"
              {...field}
            />
            {error !== undefined ? errorMessage : ""}
          </>
        );
      }}
    />
  );
};

export default HeiRichTextField;
