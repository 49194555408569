import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { SelectOption } from "../../../models/form";

interface HeiRadioGroupProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  options: SelectOption[];
  sx?: SxProps<Theme>;
  row?: boolean;
  label?: string;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  radioGroupClassName?: string;
  labelClassName?: string;
  bigOptionLabel?: boolean;
  disabled?: boolean;
}

const HeiRadioGroup = <TForm extends FieldValues>({
  control,
  name,
  options,
  row,
  rules,
  label,
  labelClassName,
  radioGroupClassName,
  disabled,
  bigOptionLabel = true,
}: HeiRadioGroupProps<TForm>): JSX.Element => {
  const required = rules?.required;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControl>
            {label && (
              <FormLabel className={labelClassName}>
                {label} {required && <span>*</span>}
              </FormLabel>
            )}
            <RadioGroup
              className={radioGroupClassName}
              row={row}
              value={field.value ? field.value : null}
              onChange={field.onChange}
            >
              {options.map((o) => (
                <FormControlLabel
                  disabled={disabled || o.disabled}
                  key={o.label}
                  value={o.value}
                  control={<Radio />}
                  label={
                    bigOptionLabel ? (
                      o.label
                    ) : (
                      <span style={{ fontSize: "14px" }}>{o.label}</span>
                    )
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
};

export default HeiRadioGroup;
