import { GridEventListener } from "@mui/x-data-grid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router";
import HeiCheckbox from "../../../../Common/components/Form/HeiCheckbox/HeiCheckbox";
import HeiGrid from "../../../../Common/components/HeiGrid/HeiGrid";
import routes from "../../../../Common/consts/routes";
import usePageTitle from "../../../../Common/hooks/usePageTitle";
import { defaultSorting } from "../../api/models/defaultSorting";
import { useMyProjectsGrid } from "../../hooks/useMyProjectsGrid";
import styles from "../../views/ProjectList.module.scss";
import { getColumns } from "./getMyProjectsGridColumns";
import { useSelector } from "react-redux";
import { selectHeiUser } from "../../../../Auth/store/accountSlice";
import { IFilteringModel } from "../../../../Common/models/grid";

const MyProjectsGrid = () => {
  usePageTitle("My Projects");
  const { fetchGridData } = useMyProjectsGrid();
  const callForceRefresh = useRef(null);
  const columns = getColumns(callForceRefresh);
  const navigate = useNavigate();
  const heiUser = useSelector(selectHeiUser);

  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    const projectId = params.id;
    if (projectId) {
      navigate(
        generatePath(routes.projectDetails, {
          id: projectId.toString(),
          tab: "details",
        })
      );
    }
  };

  const archivedForm = useForm({
    defaultValues: { withArchived: false },
    mode: "onChange",
  });
  const withArchived = archivedForm.watch("withArchived");
  const filteringModel: IFilteringModel = {
    filters: [
      {
        column: "WithArchived",
        operator: "=",
        value: `${withArchived}`,
      },
    ],
  };

  return (
    <div className={styles.view}>
      {isAdmin && (
        <div className={styles.archivedCheckbox}>
          <HeiCheckbox
            control={archivedForm.control}
            label="Include archived"
            name={"withArchived"}
          ></HeiCheckbox>
        </div>
      )}
      <HeiGrid
        columns={columns}
        fetchGridData={fetchGridData}
        searchPlaceholder="Search project name, team members, cost center"
        defaultSorting={defaultSorting}
        refFunction={callForceRefresh}
        handleRowClick={handleRowClick}
        filtering={filteringModel}
      />
    </div>
  );
};

export default MyProjectsGrid;
