import { useAtom } from "jotai";
import { useCallback } from "react";
import { useQuery } from "react-query";
import {
  getOpcosOptions,
  validateProjectName
} from "../api/projectsHttpClient";
import { selectedPackageAtom } from "./useSelectPackage";

const useEnterDetails = () => {
  const [selectedPackage] = useAtom(selectedPackageAtom);
  const { data } = useQuery("getOpcos", getOpcosOptions);

  const validateUniqueProjectName = useCallback(
    async (projectName: string) => await validateProjectName(projectName),
    []
  );

  return {
    opcos: data || [],
    validateUniqueProjectName,
    dynamicFields: selectedPackage.fields,
    packageId: selectedPackage.id
  };
};

export default useEnterDetails;
