import axios from "axios";
import { FaqArticleFormType } from "../../Common/models/FaqArticle";

export const updateFaqArticle = async (
  faqForm: FaqArticleFormType,
  id: string | undefined
): Promise<void> => {
  await axios.put<FaqArticleFormType>(
    `${window.configuration.apiUrl}api/faq/${id}`,
    faqForm
  );
};
