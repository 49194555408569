import ToolSection from "../../components/ToolSection/ToolSection";
import { CircularProgress, Stack } from "@mui/material";
import { useAtom } from "jotai";
import { selectedPackageAtom } from "../../hooks/useSelectPackage";
import { useToolsForSelect } from "../../../../Common/hooks/useToolsForSelect";

const AddOptionalToolsStep = () => {
  const [{ id, name }] = useAtom(selectedPackageAtom);

  const { tools, optionalTools, isLoading, selectedTools, toggleToolSelect } =
    useToolsForSelect(id);

  return (
    <>
      {isLoading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && (
        <>
          <ToolSection
            title={"Tools"}
            description={"The package consists of the following tools."}
            tools={tools}
            selectedTools={selectedTools}
            toggleToolSelect={toggleToolSelect}
          />
          <ToolSection
            title={"Optional tools"}
            description={`Add optional tools to your ${name} package if you like. Note: you can add and remove tools later.`}
            toggleToolSelect={toggleToolSelect}
            tools={optionalTools}
            selectedTools={selectedTools}
          />
        </>
      )}
    </>
  );
};

export default AddOptionalToolsStep;
