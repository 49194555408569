import { Alert, AlertTitle } from "@mui/material";
import styles from "./SelfonboardingAlert.module.scss";

interface SelfonboardingAlertProps {
  action?: React.ReactNode;
}

const SelfonboardingAlert = ({
  action,
}: SelfonboardingAlertProps): JSX.Element => {
  return (
    <Alert
      className={styles.selfOnboarding}
      variant="standard"
      severity="info"
      sx={{ marginTop: 2 }}
      action={action}
    >
      <AlertTitle className={styles.selfOnboarding__title}>
        Self-onboarding is active
      </AlertTitle>
      <span className={styles.selfOnboarding__text}>
        Self-onboarding allows any user with a valid Entra ID account to use the
        Toolchain self-service portal to request access to your project in the
        role of team member or stakeholder.
      </span>
    </Alert>
  );
};

export default SelfonboardingAlert;
