import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "HEINEKEN Core",
    allVariants: {
      color: "#212121",
    },
    subtitle1: {
      fontFamily: "Lucida Sans Unicode",
    },
    subtitle2: {
      fontFamily: "Lucida Sans Unicode",
    },
    caption: {
      fontFamily: "Lucida Sans Unicode",
    },
    overline: {
      fontFamily: "Lucida Sans Unicode",
    },

    body1: {
      fontFamily: "Lucida Sans Unicode",
    },
    body2: {
      color: "rgba(0, 0, 0, 0.6)",
      fontFamily: "Lucida Sans Unicode",
    },
    h4: {
      fontSize: "24px"
    },
    h6: {
      fontWeight: 400,
    },
  },

  palette: {
    primary: {
      main: "#008200",
      light: "#66b466",
      dark: "#004c00",
      contrastText: "#ffffff",
      "50": "#80c180",
    },
    secondary: {
      main: "#205527",
      light: "#2e7a38",
      dark: "#153819",
      contrastText: "#ffffff",
    },
    error: {
      main: "#e52700",
      light: "#ff5533",
      dark: "#991a00",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#ff7900",
      light: "#ffa24c",
      dark: "#cc6100",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2a6ebb",
      light: "#5895da",
      dark: "#173d68",
      contrastText: "#ffffff",
    },
    success: {
      main: "#7ab800",
      light: "#b2ff19",
      dark: "#659900",
      contrastText: "#ffffff",
    },
    action: {
      active: "#757575",
      hover: "#f5f5f5",
      selected: "#ebebeb",
      disabled: "#bdbdbd",
      disabledBackground: "#e0e0e0",
      focus: "#e0e0e0",
    },
  },

  components: {
    MuiTooltip: {
      
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          padding: "12px",
        },
      },
      defaultProps: {
        placement: "top"
      }
    },
  },
});

export default theme;
