import { useState } from "react";
import { useForm } from "react-hook-form";
import { User } from "../models/FormModels";

const defaultValues = {
  projectName: "",
  costCenter: "",
  hideProject: false,
  opcoCrossCharginInformation: "",
  billingType: "CostCenter",
  packageId: "",
  packageCustomField: {},
  users: [],
  tools: [],
  teamMembersManualApproval: true,
  stakeholdersManualApproval: true
};

export type ProjectFormType = typeof defaultValues & {
  packageCustomField: Record<string, any>;
  users: User[];
  tools: Record<string, any>[];
  opcoId?: string;
};

const useCreateProject = () => {
  const [activeStep, setActiveStep] = useState(0);
  const form = useForm({ defaultValues, mode: "onBlur" });

  return {
    activeStep,
    setActiveStep,
    form,
  };
};

export default useCreateProject;
