import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Roles } from "../../../../Common/models/roles";
import styles from "./JoinProjectButton.module.scss";
import { useRef, useState } from "react";

import { JoinProjectRequest } from "../../api/models/JoinProjectRequest";
import { useJoinOpenProjects } from "../../hooks/useJoinOpenProjects";
import { CircularProgress } from "@mui/material";
interface JoinProjectButtonProps {
  projectId: string;
  roles: Roles[];
  isProvisioningFromApi: boolean;
  callForceRefresh: any;
  disabled?: boolean;
}
const JoinProjectButton = ({
  projectId,
  roles,
  isProvisioningFromApi,
  callForceRefresh,
  disabled
}: JoinProjectButtonProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { isProvisioning, isSaving, handleSentJoinOpenProjectHandler } =
    useJoinOpenProjects(isProvisioningFromApi, callForceRefresh);

  const handleMenuItemClick = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    const joinProjectRequest: JoinProjectRequest = {
      projectId: projectId,
      role: roles[index].replace(/\s/g, ""),
    };

    await handleSentJoinOpenProjectHandler(joinProjectRequest);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup disabled={disabled} variant="outlined" ref={anchorRef} aria-label="split button">
        <Button
          disabled={isProvisioning || isSaving}
          className={styles.fakeDisabled}
        >
          {isProvisioning ? "Provisioning" : roles[selectedIndex]}
          {isSaving && (
            <CircularProgress
              size={24}
              color={"inherit"}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>
        {!isProvisioning && !isSaving && (
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {roles.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 0}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default JoinProjectButton;
