import { Collapse, TableCell, TableRow, Typography } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { ProjectFormType } from "../../hooks/useCreateProject";
import { DynamicField, SelectOption } from "../../models/form";
import { CapabilityTool } from "../../models/tools";
import HeiCheckbox from "../Form/HeiCheckbox/HeiCheckbox";
import HeiRadioGroup from "../Form/HeiRadioGroup/HeiRadioGroup";
import ToolCustomFields from "../ToolCustomFields/ToolCustomFields";
import { ToolStatusEnum } from "../../api/models/ProjectTool";
import { useMemo } from "react";
import HeiGroupButton from "../Form/HeiGroupButton/HeiGroupButton";
import enumToKeyValue from "../../utils/enumToSelectOptions";
import { ToolVisibility } from "../../models/toolVisibilityEnum";

const inviteUserOptions = (isEdit = false) =>
  [
    {
      label: isEdit ? "Automatically" : "Automatically (upon creation)",
      value: "Automatically",
    },
    {
      label: isEdit ? "Manually" : "Manually (later)",
      value: "Manually",
    },
  ] as SelectOption[];

interface ToolDetailsFieldsTableRowProps {
  toolOpen: boolean;
  index: number;
  form: UseFormReturn<ProjectFormType>;
  CustomFields?: DynamicField<ProjectFormType>[];
  toolKey: CapabilityTool;
  isEdit?: boolean;
  userCanEdit: boolean;
  status?: ToolStatusEnum;
}

const ToolDetailsFieldsTableRow = ({
  form,
  toolOpen,
  index,
  toolKey,
  CustomFields,
  isEdit,
  userCanEdit,
  status,
}: ToolDetailsFieldsTableRowProps): JSX.Element => {
  const toolCustomFields = useMemo(
    () =>
      isEdit
        ? CustomFields
        : CustomFields?.filter((c) => c.label !== "ServiceNow group"),
    [isEdit, CustomFields]
  );
  return (
    <TableRow sx={{ background: "#f5f5f5" }}>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={isEdit ? 2 : 3}
      >
        <Collapse in={toolOpen}>
          <ToolCustomFields
            userCanEdit={userCanEdit}
            toolIndex={index}
            fields={toolCustomFields}
            form={form}
            toolStatus={status}
          />
        </Collapse>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
        <Collapse in={toolOpen}>
          <div style={{ padding: "8px" }}>
            <Typography variant="body2" sx={{ color: "#202020", mb: 1, mt: 1 }}>
              Invite users
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Select how you would like to invite users to this project.
            </Typography>

            <HeiRadioGroup
              disabled={toolKey == "BACKLOG:JIRA" || !userCanEdit}
              control={form.control}
              options={inviteUserOptions(isEdit)}
              name={`tools.${index}.inviteMode`}
            />
            {(!isEdit || status == ToolStatusEnum.New) &&
              toolKey == "MONITORING:NEWRELIC" && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ color: "#202020", mb: 1, mt: 1 }}
                  >
                    DBB connection
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    By checking this box, this New Relic project will be
                    connected to the DBB central account.
                  </Typography>

                  <Typography variant="body2" sx={{ mb: 1 }}>
                    This can only be set during project creation and adding New
                    Relic to an existing project.
                  </Typography>
                  <HeiCheckbox
                    control={form.control}
                    disabled={!userCanEdit}
                    name={`tools.${index}.isDbb`}
                    label="Is part of DBB"
                  />
                </>
              )}
            {isEdit &&
              status == ToolStatusEnum.Created &&
              toolKey == "BACKLOG:JIRA" && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ color: "#202020", mb: 1, mt: 1 }}
                  >
                    Hide project on Jira
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    By checking this box, this project will not be visible for
                    other users on Jira
                  </Typography>
                  <HeiCheckbox
                    control={form.control}
                    disabled={!userCanEdit}
                    name={`tools.${index}.isHidden`}
                    label="Hide project"
                  />
                </>
              )}
            {isEdit &&
              status == ToolStatusEnum.Created &&
              toolKey == "WIKI:CONFLUENCE" && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ color: "#202020", mb: 1, mt: 1 }}
                  >
                    Set confluence visibility
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    By changing this option, this project will be visible for
                    other Confluence users
                  </Typography>
                  <HeiGroupButton
                    control={form.control}
                    disabled={!userCanEdit}
                    name={`tools.${index}.toolVisibility`}
                    label=""
                    options={enumToKeyValue(ToolVisibility)}
                  />
                </>
              )}
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default ToolDetailsFieldsTableRow;
