import * as React from "react";
import { BillingDto } from "../api/models/billingDto";
import { getColumns } from "./getBillingGridColumns";
import { DataGrid } from "@mui/x-data-grid";
import { gridSX } from "../../../Common/consts/gridSx";

interface BillingGridProps {
  billings: BillingDto[];
}

const BillingGrid = ({ billings }: BillingGridProps): JSX.Element => {
  const columns = getColumns();
  return <DataGrid sx={gridSX} rows={billings} columns={columns} />;
};

export default BillingGrid;
