import { SelectOption } from "../models/form";

function enumToKeyValue(enumObj: any): SelectOption[] {
  const keyValuePairs = [];

  for (const key in enumObj) {
    if (isNaN(Number(key))) {
      keyValuePairs.push({ label: enumObj[key], value: key });
    }
  }

  return keyValuePairs;
}

export default enumToKeyValue;
