import * as React from "react";
import { useEffect } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import { Box } from "@mui/material";
import HeiUserTable from "../HeiUserTable/HeiUserTable";
import SearchAndAddUserSection from "../../../../Common/components/HeiUserSearch/SearchAndAddUserSection";
import { useSelector } from "react-redux";
import { selectHeiUser } from "../../../../Auth/store/accountSlice";
import { Roles } from "../../../../Common/models/roles";
import { User } from "../../../../Common/models/FormModels";
import { EntityState } from "../../../../Common/api/models/EntityState";

interface AddUsersStepProps {
  form: UseFormReturn<ProjectFormType>;
}
const userDefValues: User = {
  adPrinciple: "",
  email: "",
  name: "",
  roles: [],
  toolCaps: [],
  editStatus: EntityState.Add,
};

const formDefaultValues: ProjectFormType = {
  users: [userDefValues],
} as ProjectFormType;
export default function AddUserSection({ form }: AddUsersStepProps) {
  const loggedUser = useSelector(selectHeiUser);
  const { control, getValues } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "users",
  });

  useEffect(() => {
    if (getValues().users.length === 0) {
      const userToAdd: User = {
        name: loggedUser.name,
        adPrinciple: loggedUser.email,
        email: loggedUser.email,
        roles: [Roles["Product Owner"]],
        editStatus: EntityState.Add,
      };
      append(userToAdd);
    }
  }, []);

  return (
    <Box>
      <SearchAndAddUserSection
        addUser={append}
        getUsers={getValues}
        formDefaultValues={formDefaultValues}
        hideToolAccess
      />
      <HeiUserTable
        fields={fields}
        control={control}
        remove={remove}
        getValues={getValues}
      />
    </Box>
  );
}
