import { Button } from "@mui/material";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import SelfonboardingPopup from "../../../../Common/components/SelfonboardingPopup/SelfonboardingPopup";
import styles from "./SelfonboardingEditAlert.module.scss";
import SelfonboardingAlert from "../../../../Common/components/SelfonboardingAlert/SelfonboardingAlert";
interface SelfonboardingEditAlertProps {
  form: UseFormReturn<ProjectFormType>;
}

const SelfonboardingEditAlert = ({
  form,
}: SelfonboardingEditAlertProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(true);

  return isAlertOpen ? (
    <SelfonboardingAlert
      action={
        <div style={{ minWidth: "130px" }}>
          <Button
            className={styles.selfOnboarding__editBtn}
            variant="outlined"
            onClick={() => setIsModalOpen((open) => !open)}
            size="small"
          >
            EDIT
          </Button>
          <Button
            onClick={() => setIsAlertOpen((open) => !open)}
            size={"small"}
            className={styles.selfOnboarding__closeBtn}
          >
            <CloseOutlinedIcon />
          </Button>
          <SelfonboardingPopup
            open={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            form={form}
            title={"Edit self-onboarding"}
            displayAlertInfo={true}
          />
        </div>
      }
    />
  ) : (
    <div className={styles.editSelfOnboarding}>
      <Button
        variant="outlined"
        onClick={() => setIsAlertOpen((open) => !open)}
      >
        EDIT SELF-ONBOARDING
      </Button>
    </div>
  );
};

export default SelfonboardingEditAlert;
