import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface FlagProjectPopupProps {
  open: boolean;
  handleClose: () => void;
  sameCostCenterCount: number;
  handleSave?: () => void;
}

const FlagProjectPopup = ({
  open,
  handleClose,
  handleSave,
  sameCostCenterCount,
}: FlagProjectPopupProps): JSX.Element => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>Would you like to flag additional projects?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
          Besides the project you just flagged, there are {sameCostCenterCount} additional projects that use the same cost center. 
          Would you like to flag those projects as well?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>

        <Button variant="contained" onClick={handleSave} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlagProjectPopup;
