import React from "react";

export const packageAdditionalInfo: Record<string, React.ReactNode> = {
  Custom: (
    <p>
      With this package, you can choose any combination of tools to create your
      customized project environment.
      <br />
      It is the most flexible package, but does
      not include any preconfiguration for specific types of technology
      projects.
    </p>
  ),
  Collaborate: (
    <p>
      A set of tools to support you and your team to manage an agile business
      project. 
      <br />
      This package will give you collaboration related tools like Jira
      for backlog management and Confluence for team documentation. 
      <br />
      The package
      is intended for projects where you don't develop code.
    </p>
  ),
  "Data Prime": (
    <p>
      A set of tools to support you and your team in developing a project on the
      Data Prime project. 
      <br />
      This package includes preconfiguration specifically
      for Data Prime projects. 
      <br />
      <br />
      When starting this project, you will need to have
      your subscription name and sequence number ready, which you can get from
      the Data Prime team.
    </p>
  ),
  "Power Platform": (
    <p>
      A set of preconfigured tools to support you and your team on developing
      Power Apps in a managed environment. Due to manual work required, final
      configuration may take up to 2 working days. 
      <br />
      <br />
      When starting this project,
      you will need to have your production URL, Acceptance URL, Region,
      Application ID and Client Secret ready, which you can get from the Power
      Platform team.
    </p>
  ),
  Salesforce: (
    <p>
      A set of tools to support you and your team in developing Salesforce
      customizations. 
      <br />
      This package includes preconfiguration specifically for
      Salesforce projects.
    </p>
  ),
  UiPath: (
    <p>
      A set of preconfigured tools to support your federated team in automating
      business processes with UIPath. <br />
      This package includes preconfiguration
      specifically for UIpath projects.
    </p>
  ),
  "Uncage our Courage": (
    <p>
      This package is intended for InnerSourcing, i.e. sharing your code within
      the HEINEKEN organization so that it can be re-used by colleagues. <br />
      Sharing your code is free of charge. Any colleague in HEINEKEN will have access to
      your code so that they can re-use it.
    </p>
  ),
};
