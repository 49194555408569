import { useState } from "react";
import { useForm } from "react-hook-form";
import { createFaqArticle } from "../api/createFaqHttpClient";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import routes from "../../../Common/consts/routes";
import { FaqArticleFormType, defaultValues } from "../../Common/models/FaqArticle";

const useCreateFaq = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<FaqArticleFormType>({
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const submit = async () => {
    const values = form.getValues();

    setIsLoading(true);

    try {
      await createFaqArticle(values);

      enqueueSnackbar("Created new FAQ successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });

      navigate(routes.faq);
    } catch {
      enqueueSnackbar("Something went wrong while creating new FAQ", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { form, submit, isLoading };
};

export default useCreateFaq;
