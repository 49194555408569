import React from "react";
import SelfonboardingPopup from "../../../../Common/components/SelfonboardingPopup/SelfonboardingPopup";
import useProjectAccessEdit from "../../hooks/useProjectAccessEdit";
import { Backdrop, CircularProgress } from "@mui/material";
import { ProjectAccess } from "../../api/models/ProjectAccess";

interface EditProjectAccessPopupProps {
  open: boolean;
  handleClose: () => void;
  projectAccess: ProjectAccess;
}
const EditProjectAccessPopup = ({
  open,
  handleClose,
  projectAccess,
}: EditProjectAccessPopupProps): JSX.Element => {
  const { form, handleProjectAccessSave, isSaving } =
    useProjectAccessEdit(projectAccess);

  const handleSaveProjectAccess = async () => {
    await handleProjectAccessSave();
    handleClose();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={isSaving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SelfonboardingPopup
        open={open}
        handleClose={handleClose}
        form={form}
        title={"Edit project access"}
        displayAlertInfo={false}
        handleSave={handleSaveProjectAccess}
      />
    </>
  );
};

export default EditProjectAccessPopup;
