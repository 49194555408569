import { useState } from "react";
import { useForm } from "react-hook-form";
import { updateFaqArticle } from "../api/updateFaqHttpClient";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../../Common/consts/routes";
import { FaqArticleResponse } from "../../FaqArticle/api/models/FaqArticleResponse";
import { FaqArticleFormType } from "../../Common/models/FaqArticle";

const useCreateFaq = ( faqArticle: FaqArticleResponse) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const values = {
    title: faqArticle.faqArticle.title,
    synopsis: faqArticle.faqArticle.synopsis,
    article: faqArticle.faqArticle.body,
    alertTitle: faqArticle.faqAlertInfo.title ?? "",
    alertBody: faqArticle.faqAlertInfo.body ?? "",
    tools: faqArticle.faqTags ?? [],
  }

  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<FaqArticleFormType>({
    defaultValues: values,
    mode: "onBlur",
  });

  const submit = async () => {
   
    if (await form.trigger()) {
    setIsLoading(true);

    try {
      const formValues = form.getValues();
      await updateFaqArticle(formValues, id);

      enqueueSnackbar("Update FAQ successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });

      navigate(`${routes.faq}/${id}`);
    } catch {
      enqueueSnackbar("Something went wrong while updateing FAQ", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  }

  };

  return { form, submit, isLoading };
};

export default useCreateFaq;
