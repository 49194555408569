import * as React from "react";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormGetValues,
} from "react-hook-form";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import {
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
} from "@mui/material";
import { UserTableRow } from "./UserTableRow";

interface HeiUserTableProps {
  fields: FieldArrayWithId<ProjectFormType, "users", "id">[];
  control: Control<ProjectFormType, any>;
  remove: UseFieldArrayRemove;
  getValues: UseFormGetValues<ProjectFormType>;
}

export default function HeiUserTable({
  fields,
  control,
  remove,
  getValues,
}: HeiUserTableProps) {
  return (
    <Grid item xs={12} sx={{ mb: "40px", mt: "30px" }}>
      <Typography display="inline" variant="h6">
        Users
        <Typography
          display="inline"
          variant={"body1"}
          sx={{ color: "#616161" }}
        >
          ({getValues().users.length})
        </Typography>
      </Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "35%" }}>
                <b>Name</b>
              </TableCell>
              <TableCell sx={{ width: "30%" }}>
                <b>Heiway account</b>
              </TableCell>
              <TableCell sx={{ width: "30%" }}>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>Role</b>
              </TableCell>
              <TableCell sx={{ width: "5%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((user, index) => {
              return (
                <UserTableRow
                  key={index}
                  {...{
                    control,
                    index,
                    user,
                    remove,
                    getValues,
                  }}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
