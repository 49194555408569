import axios from "axios";
import { AdminUserSearchDto } from "./models/adminUserSearchDto";

export const getUsers = async (searchValue?: string): Promise<AdminUserSearchDto[]> => {
  const { data: users } = await axios.get<AdminUserSearchDto[]>(
    `${window.configuration.apiUrl}api/users/admin/search?searchValue=${searchValue}`,
  );

  return users;
};
