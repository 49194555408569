export interface UserProjectPermissions {
  isAdmin: boolean;
  projectRoles: ProjectUserRole[];
}

export enum ProjectUserRole {
  TeamMember = "TeamMember",
  ProductOwner = "ProductOwner",
  StakeHolder = "StakeHolder",
  ScrumMaster = "ScrumMaster",
}
