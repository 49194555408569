import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import routes from "../../Common/consts/routes";
import FaqList from "../../Faq/FaqList/views/FaqList";
import BaseLayout from "../../Layout/components/BaseLayout/BaseLayout";
import CreateProject from "../../Projects/CreateProject/views/CreateProject/CreateProject";
import ProjectDetails from "../../Projects/ProjectDetails/views/ProjectDetails/ProjectDetails";
import ProjectList from "../../Projects/ProjectsList/views/ProjectList";
import Requests from "../../Projects/SelfOnboarding/views/Requests";
import { useAppEntry } from "../hooks/useAppEntry";
import "./AppEntry.scss";
import FaqArticle from "../../Faq/FaqArticle/views/FaqArticle";
import CreateFaqArticle from "../../Faq/CreateFaqArticle/views/CreateFaqArticle";
import { selectHeiUser } from "../../Auth/store/accountSlice";
import { useSelector } from "react-redux";
import AdminBillingView from "../../Admin/Billing/views/AdminBillingView";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import AdminUserSearchView from "../../Admin/UserSearch/views/AdminUserSearchView";
import AdminOpcosView from "../../Admin/Opcos/views/AdminOpcosView";

interface AppEntryProps {
  appInsights: ApplicationInsights;
}

const AppEntry = ({ appInsights }: AppEntryProps) => {
  const { tokenSetup } = useAppEntry();
  const heiUser = useSelector(selectHeiUser);

  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );
  useEffect(() => {
    if (tokenSetup) {
      appInsights.setAuthenticatedUserContext(heiUser.email, heiUser.email);
    }
  }, [appInsights, tokenSetup]);

  return tokenSetup ? (
    <BaseLayout>
      <Routes>
        <Route path={routes.projects} element={<ProjectList />} />
        <Route path={routes.createProject} element={<CreateProject />} />
        <Route
          path={routes.projectDetails_v1}
          element={<ProjectDetailsRedirect />}
        />
        <Route path={routes.projectDetails} element={<ProjectDetails />} />
        <Route path={routes.faq} element={<FaqList />} />
        <Route path={routes.faqArticle} element={<FaqArticle />} />
        <Route path={routes.createFaqArticle} element={<CreateFaqArticle />} />
        <Route path={routes.requests} element={<Requests />} />
        {isAdmin && (
          <>
            <Route path={routes.admin.billing} element={<AdminBillingView />} />
            <Route path={routes.admin.userSearch} element={<AdminUserSearchView />} />
            <Route path={routes.admin.opcos} element={<AdminOpcosView />} />
          </>
        )}
      </Routes>
    </BaseLayout>
  ) : (
    <></>
  );
};

const ProjectDetailsRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => navigate(`/project/${id}/details`), [id]);
  return null;
};

export default AppEntry;
