import HeiGrid from "../../../../Common/components/HeiGrid/HeiGrid";
import styles from "../../views/ProjectList.module.scss";
import { getOpenProjectColumns } from "./getOpenProjectsGridColumns";
import { useOpenProjectsGrid } from "../../hooks/useOpenProjectsGrid";
import { useRef } from "react";
import { defaultSorting } from "../../api/models/defaultSorting";
import usePageTitle from "../../../../Common/hooks/usePageTitle";

const OtherProjectsGrid = () => {
  usePageTitle("Open Projects");

  const { fetchGridData } = useOpenProjectsGrid();
  const callForceRefresh = useRef(null);
  const columns = getOpenProjectColumns(callForceRefresh);

  return (
    <div className={styles.view}>
      <HeiGrid
        columns={columns}
        fetchGridData={fetchGridData}
        searchPlaceholder="Search project name, team members, cost center"
        defaultSorting={defaultSorting}
        refFunction={callForceRefresh}
      />
    </div>
  );
};

export default OtherProjectsGrid;
