import HeiClientSideGrid from "../../../../Common/components/HeiClientSideGrid/HeiClientSideGrid";
import * as React from "react";
import { ProjectCostResponse } from "../../../ProjectsList/api/models/ProjectToolCost";
import { getProjectCostColumns } from "./getProjectCostColumns";
import { costValueFormat } from "../../../../Common/utils/costValueFormat";

interface ProjectCostGridProps {
  projectCostResponse: ProjectCostResponse;
  isLoading: boolean;
}

const ProjectCostGrid = ({
  projectCostResponse,
  isLoading,
}: ProjectCostGridProps) => {
  const columns = getProjectCostColumns(projectCostResponse.licenseModes);
  const costAggregationFooter = () => {
    return (
      <strong>
        Total cost per month:{" "}
        {costValueFormat(projectCostResponse.totalCostEstimate)}
      </strong>
    );
  };
  return (
    <HeiClientSideGrid
      columns={columns}
      rows={projectCostResponse.toolCosts}
      isLoading={isLoading}
      customFooter={costAggregationFooter}
    />
  );
};

export default ProjectCostGrid;
