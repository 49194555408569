import { Avatar, IconButton } from "@mui/material";
import * as React from "react";
import styles from "./MenuFooter.module.scss";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useSelector } from "react-redux";
import { selectHeiUser } from "../../../Auth/store/accountSlice";

interface IMenuFooterProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const MenuFooter: React.FunctionComponent<IMenuFooterProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const heiUser = useSelector(selectHeiUser);
  return (
    <div className={styles.footerWrapper}>
      <Avatar>{heiUser.initials}</Avatar>
      <IconButton onClick={() => setCollapsed(!collapsed)}>
        {collapsed && <KeyboardDoubleArrowRightIcon />}
        {!collapsed && <KeyboardDoubleArrowLeftIcon />}
      </IconButton>
    </div>
  );
};

export default MenuFooter;
