import HeiGrid from "../../../../Common/components/HeiGrid/HeiGrid";
import styles from "./ProcessedRequestsGrid.module.scss";
import { getProcessedRequestsColumns } from "./ProcessedRequestsGridColumns";
import { useProcessedRequestsGrid } from "../../hooks/useProcessedRequestsGrid";
import { processedRequestsDefaultSorting } from "../../api/models/processedRequestsdefaultSorting";
import usePageTitle from "../../../../Common/hooks/usePageTitle";

const ProcessedRequestsGrid = () => {
  usePageTitle("Self Onboarding Processed Requests");
  const { fetchGridData } = useProcessedRequestsGrid();
  const columns = getProcessedRequestsColumns();

  return (
    <div className={styles.view}>
      <HeiGrid
        columns={columns}
        fetchGridData={fetchGridData}
        searchPlaceholder="Search name, email, project, role"
        defaultSorting={processedRequestsDefaultSorting}
      />
    </div>
  );
};

export default ProcessedRequestsGrid;
