import { Chip } from "@mui/material";
import { EntityState } from "../../../../Common/api/models/EntityState";

interface ChipParams {
  label: string;
  color: "error" | "info" | "warning";
}

const chipColorAndLabel: Record<EntityState, ChipParams> = {
  NotChanged: { label: "New", color: "info" },
  Add: { label: "Draft", color: "warning" },
  Update: { label: "Edited", color: "info" },
  Delete: { label: "Delete", color: "error" },
};

interface ToolStatusChipProps {
  toolEditStatus: EntityState;
  isNew: boolean;
}

const ToolStatusChip = ({ toolEditStatus, isNew }: ToolStatusChipProps) => {
  return toolEditStatus === EntityState.NotChanged && !isNew ? (
    <></>
  ) : (
    <>
      <Chip
        sx={{ ml: "12px" }}
        size="small"
        color={chipColorAndLabel[toolEditStatus].color}
        label={chipColorAndLabel[toolEditStatus].label}
      />
    </>
  );
};

export default ToolStatusChip;
