import { Collapse, TableCell, TableRow, Typography } from "@mui/material";
import HeiSelection from "../../../../Common/components/HeiSelection/HeiSelection";
import { RolesForSelect } from "../../../../Common/models/RolesForSelect";
import * as React from "react";
import { Roles } from "../../../../Common/models/roles";
import { UseFormReturn } from "react-hook-form";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import ToolAccess from "../ToolAccess/ToolAccess";
import { roleValidator } from "../../../../Common/validators/roleValidator";

interface ProjectUserDetailsFieldsTableRowProps {
  userOpen: boolean;
  index: number;
  isEdit?: boolean;
  userCanEdit: boolean;
  form: UseFormReturn<ProjectFormType>;
}

const ProjectUserDetailsFieldsTableRow = ({
  userOpen,
  index,
  isEdit,
  userCanEdit,
  form,
}: ProjectUserDetailsFieldsTableRowProps): JSX.Element => {
  const { control, getValues } = form;

  const accessTools = getValues(`users.${index}.toolCaps`);

  return (
    <TableRow sx={{ background: "#f5f5f5" }}>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: "top" }}
        colSpan={2}
      >
        <Collapse in={userOpen}>
          <div style={{ padding: "8px" }}>
            <Typography variant="body2" sx={{ color: "#202020", mb: 1, mt: 1 }}>
              Select role
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Select the role(s) for this user. Read details about roles.
            </Typography>
            <HeiSelection
              control={control}
              fieldName={`users.${index}.roles`}
              disabled={!userCanEdit}
              getValues={getValues}
              options={RolesForSelect}
              label={"Roles"}
              multiple={true}
              rules={{
                required: "Please select at least 1 role for this user.",
                // validate: (value: string[] | any) =>
                //   roleValidator(value, getValues("users")),
              }}
            />
          </div>
        </Collapse>
      </TableCell>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: "top" }}
        colSpan={4}
      >
        <Collapse in={userOpen}>
          <div style={{ padding: "8px" }}>
            <Typography variant="body2" sx={{ color: "#202020", mb: 1, mt: 1 }}>
              Set tool access
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Select a tool to send an invite to this user or deselect a tool to
              revoke access.
            </Typography>
            {accessTools?.map((st, i) => {
              return (
                <ToolAccess
                  key={i}
                  tool={st}
                  toolIndex={i}
                  userIndex={index}
                  form={form}
                  userCanEdit={userCanEdit}
                />
              );
            })}
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default ProjectUserDetailsFieldsTableRow;
