import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";

import styles from "./HeiClientSideGrid.module.scss";
import { JSXElementConstructor } from "react";
import { gridSX } from "../../consts/gridSx";

interface ProjectCostGridProps {
  isLoading: boolean;
  columns: GridColDef[];
  rows: any[];
  customFooter?: JSXElementConstructor<React.ReactNode>;
}
const HeiClientSideGrid = ({
  isLoading,
  columns,
  rows,
  customFooter,
}: ProjectCostGridProps): JSX.Element => {
  return (
    <div data-testid="data-grid" className={styles.tableWrapper}>
      <DataGrid
        sx={gridSX}
        loading={isLoading}
        rows={rows}
        columns={columns}
        pageSize={100}
        disableSelectionOnClick
        rowCount={rows.length}
        components={{
          Pagination: customFooter,
        }}
        autoHeight={true}
      />
    </div>
  );
};

export default HeiClientSideGrid;
