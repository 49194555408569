import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { DynamicField } from "../../../Common/models/form";
import { mapCreateProjectFormField } from "../../../Common/utils/dynamicFieldUtil";
import { packageAdditionalInfo } from "../../../Common/utils/packageUtil";
import { getPackages } from "../api/packagesHttpClient";
import { Package } from "../models/Package";
import { ProjectFormType } from "../../../Common/hooks/useCreateProject";
import { selectedToolsAtom } from "../../../Common/hooks/useToolsForSelect";

export const selectedPackageAtom = atom<{
  id: string;
  name: string;
  fields?: DynamicField<ProjectFormType>[];
}>({ id: "", name: "" });

const useSelectPackage = (setActiveStep: (step: number) => void) => {
  const [_, setSelectedPackage] = useAtom(selectedPackageAtom);
  const [__, setSelectedTools] = useAtom(selectedToolsAtom);

  const [packages, setPackages] = useState<Package[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState<React.ReactNode>();
  const [popupTitle, setPopupTitle] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getPackages()
      .then((packages) => {
        setPackages(packages);
        setSelectedTools([]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onMoreInfoClickHandler = (packageName: string) => {
    setIsPopupOpen(true);
    const packageMoreInfo =
      packageAdditionalInfo[packageName] || `${packageName} additional info`;
    setPopupContent(packageMoreInfo);
    setPopupTitle(`About the ${packageName} package`);
  };

  const closePopupHandler = () => setIsPopupOpen(false);

  const selectPackageHandler = (selectedPackage: Package) => {
    setSelectedPackage({
      ...selectedPackage,
      fields: selectedPackage.CustomFields?.map(mapCreateProjectFormField),
    });
    if (selectedPackage.optionalTools?.length > 0) {
      setActiveStep(1);
    } else {
      setActiveStep(1);
      setTimeout(() => {
        setActiveStep(2);
      });
    }
  };

  return {
    packages,
    isLoading,
    onMoreInfoClickHandler,
    isPopupOpen,
    closePopupHandler,
    popupContent,
    selectPackageHandler,
    popupTitle,
  };
};

export default useSelectPackage;
