import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ProjectFormType } from "../../../Common/hooks/useCreateProject";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ToolCapability, User } from "../../../Common/models/FormModels";
import _ from "lodash";
import { useQueryClient } from "react-query";
import {
  getProjectUsersTable,
  putProjectUsers,
} from "../api/projectDetailsHttpClient";
import { EntityState } from "../../../Common/api/models/EntityState";
import { TableSortFilterForPagination } from "../api/models/Table";
import { AxiosError } from "axios";
import { ProjectTool } from "../../../Common/api/models/ProjectTool";

const useProjectUsersForm = (
  projectUsers: User[],
  tableSortFilterForPagination: TableSortFilterForPagination,
  setTotalRows: any,
  projectTools: ProjectTool[]
) => {
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const getToolCreationCompleted = (toolId: string, toolProjectId: string) =>
    !!toolProjectId ||
    projectTools.find((pt) => pt.toolId === toolId)?.isManual;
  const getFormUsers = (users: User[]) =>
    users.map((u) => ({
      ...u,
      editStatus: EntityState.NotChanged,
      toolCaps: u.toolCaps?.map((tc: ToolCapability) => ({
        ...tc,
        toolCreationCompleted: getToolCreationCompleted(
          tc.toolId,
          tc.toolProjectId
        ),
      })),
    }));

  const defaultValues = {
    users: getFormUsers(projectUsers),
  } as ProjectFormType;

  const form = useForm<ProjectFormType>({
    defaultValues: defaultValues,
    mode: "onBlur",
  });
  const {
    control,
    watch,
    formState: { isValid },
  } = form;
  watch("users");

  const { append, remove } = useFieldArray({
    control,
    name: "users",
  });

  const handleProjectUsersSubmit = async () => {
    const users: User[] = form.getValues("users");
    if (!isValid) {
      enqueueSnackbar("Please correct the validation errors", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      return;
    }
    const initialUsers = form.formState.defaultValues?.users as
      | User[]
      | undefined;
    if (initialUsers) {
      for (const user of users) {
        const formUser = user;
        if (
          formUser.editStatus !== EntityState.NotChanged &&
          formUser.editStatus !== EntityState.Update
        ) {
          continue;
        }

        const initialUser = initialUsers.filter(
          (iu) => iu.email == formUser.email
        )[0];
        if (
          !_.isEqual(
            _.omit(initialUser, "editStatus"),
            _.omit(formUser, "editStatus")
          )
        ) {
          formUser.editStatus = EntityState.Update;
        } else {
          formUser.editStatus = EntityState.NotChanged;
        }
      }
    }
    const groupedUsers = _.groupBy(users, "editStatus");

    setIsSaving(true);

    try {
      await putProjectUsers(id!, groupedUsers);
      queryClient.invalidateQueries(["project_users", id!]);

      const newProjectUsers = await getProjectUsersTable(id!, {
        pagination: {
          pageNumber: tableSortFilterForPagination.page,
          pageSize: tableSortFilterForPagination.rowsPerPage,
        },
        sorting: {
          field: tableSortFilterForPagination.orderBy,
          sort: tableSortFilterForPagination.order,
        },
        search: tableSortFilterForPagination.search,
      });
      const users: User[] = newProjectUsers.rows;
      setTotalRows(newProjectUsers.totalRows);
      form.reset({
        users: getFormUsers(users),
      } as ProjectFormType);

      enqueueSnackbar("Users have been updated!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch (error) {
      let errorMsg = "";
      if (error instanceof AxiosError) {
        errorMsg = Object.values(error?.response?.data.errors).flat().join();
      } else {
        errorMsg = "Users have not been updated!";
      }
      enqueueSnackbar(errorMsg, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });

      console.error("Something went wrong");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = (index: number) => {
    const userEditStatus = form.getValues(
      `users.${index}.editStatus`
    ) as EntityState;

    if (userEditStatus === EntityState.Add) {
      remove(index);
      return;
    }

    if (userEditStatus === EntityState.Delete) {
      form.resetField(`users.${index}`);
    } else {
      form.setValue(`users.${index}.editStatus`, EntityState.Delete, {
        shouldDirty: true,
      });
    }
  };

  return {
    form,
    append,
    handleDelete,
    handleProjectUsersSubmit,
    isSaving,
  };
};

export default useProjectUsersForm;
