import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import usePageTitle from "../../../../../Common/hooks/usePageTitle";
import { useProjectHistoryGrid } from "../../../hooks/useProjectHistoryGrid";
import HeiGrid from "../../../../../Common/components/HeiGrid/HeiGrid";
import {
  FetchDataRequest,
  FetchDataResult,
} from "../../../../../Common/models/grid";
import ProjectHistoryGrid from "../../../components/ProjectHistoryGrid/ProjectHistoryGrid";

interface ProjectHistoryTabProps {
  id: string;
}

const ProjectHistoryTab = ({ id }: ProjectHistoryTabProps): JSX.Element => {
  usePageTitle("Project | History");

  return (
    <Box sx={{ pl: "80px", pt: "24px", height: "100%", width: "90%" }}>
      <Typography variant={"h6"}>History</Typography>
      <Typography variant={"body2"} sx={{ marginBottom: "16px" }}>
        Overview of the estimated costs. To change your billing type, go to the
        Details tab.
      </Typography>

      <ProjectHistoryGrid id={id} />
    </Box>
  );
};

export default ProjectHistoryTab;
