import { CircularProgress, Stack, Typography } from "@mui/material";
import Popup from "../../../../Common/components/Popup/Popup";
import { orderTools } from "../../../../Common/utils/toolUtil";
import Package from "../../components/Package/Package";
import useSelectPackage from "../../hooks/useSelectPackage";
import styles from "./SelectPackage.module.scss";

interface SelectPackageProps {
  setActiveStep: (step: number) => void;
}

const SelectPackageStep = ({
  setActiveStep,
}: SelectPackageProps): JSX.Element => {
  const {
    packages,
    isLoading,
    onMoreInfoClickHandler,
    isPopupOpen,
    closePopupHandler: handleClosePopup,
    popupContent,
    popupTitle,
    selectPackageHandler,
  } = useSelectPackage(setActiveStep);

  return (
    <>
      <Typography variant="h6">Packages</Typography>
      <Typography variant={"body2"} sx={{ marginBottom: 2 }}>
        Choose the package that suits your project. Not sure which one to
        choose? Select the Custom project. and choose from all tools.
      </Typography>
      <Popup
        open={isPopupOpen}
        handleClose={handleClosePopup}
        content={popupContent}
        title={popupTitle}
      />

      {isLoading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      <div className={styles.packages}>
        {!isLoading &&
          packages.map((p, i) => (
            <Package
              selectPackageHandler={() => selectPackageHandler(p)}
              key={i}
              name={p.name}
              description={p.description}
              moreInfo={p.description}
              tools={orderTools(p.tools)}
              optionalTools={orderTools(p.optionalTools)}
              onMoreInfoClick={() => onMoreInfoClickHandler(p.name)}
            />
          ))}
      </div>
    </>
  );
};

export default SelectPackageStep;
