import axios from "axios";
import { FetchDataRequest, FetchDataResult } from "../../../Common/models/grid";
import { SieveResponse } from "../../../Common/models/sieve";
import {
  getSieveRequestModel,
  mapToFetchDataResult,
} from "../../../Common/utils/sieveRequestModel";
import { JoinProjectRequest } from "./models/JoinProjectRequest";

export const getOpenProjects = async (
  request: FetchDataRequest
): Promise<FetchDataResult> => {
  const sieveRequest = getSieveRequestModel(request);
  const { data } = await axios.get<SieveResponse>(
    `${window.configuration.apiUrl}api/Selfonboarding/openProjects`,
    { params: sieveRequest }
  );

  const response = mapToFetchDataResult(data);
  return response;
};

export const postJoinRequest = async (
  joinProjectRequest: JoinProjectRequest
): Promise<string> => {
  const { data: isProcessing } = await axios.post<string>(
    `${window.configuration.apiUrl}api/Selfonboarding`,
    joinProjectRequest
  );

  return isProcessing;
};
