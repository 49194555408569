import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { RequestStatus } from "../../models/requestStatus";
import RequestStatusBadge from "../RequestStatusBadge/RequestStatusBadge";
export const getProcessedRequestsColumns = (): GridColDef[] => {
  return [
    {
      field: "memberName",
      headerName: "Name",
      disableColumnMenu: true,
      flex: 0.8,
    },
    {
      field: "memberEmail",
      headerName: "Email",
      flex: 0.9,
      disableColumnMenu: true,
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 0.9,
      disableColumnMenu: true,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.9,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Status",
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        const status = params.row.status as RequestStatus;
        const updatedBy = params.row.updatedBy;

        return <RequestStatusBadge status={status} updatedBy={updatedBy} />;
      },
    },
    {
      field: "updatedOn",
      headerName: "Date",
      flex: 0.9,
      disableColumnMenu: true,
    },
  ];
};
