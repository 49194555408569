import { useCallback } from "react";
import { useQuery } from "react-query";
import { getPackageActions } from "../api/projectDetailsHttpClient";

const usePackageActions = (id: string) => {
  const getProjectToolsCallback = useCallback(
    () => getPackageActions(id),
    [id]
  );

  const { data: packageActions, isLoading } = useQuery(
    ["package_actions", id],
    getProjectToolsCallback
  );
  return { packageActions, isLoading };
};

export default usePackageActions;
