import { Switch, FormControlLabel, SxProps, Theme } from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";

interface HeiSwitchProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  startLabel?: string;
  endLabel?: string;
  sx?: SxProps<Theme>;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}
const HeiSwitch = <TForm extends FieldValues>({
  control,
  name,
  startLabel,
  endLabel,
  sx,
  rules,
}: HeiSwitchProps<TForm>): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, ...field } }) => (
        <div
          style={{ marginTop: "16px", display: "flex", alignItems: "center" }}
        >
          <span style={{ marginRight: "10px" }}>{startLabel}</span>
          <FormControlLabel
            control={
              <Switch
                sx={sx}
                onChange={onChange}
                checked={value}
                defaultChecked
                {...field}
              />
            }
            label={endLabel}
          />
        </div>
      )}
    />
  );
};

export default HeiSwitch;
