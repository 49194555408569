import classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import Menu from "../Menu/Menu";
import styles from "./BaseLayout.module.scss";
interface IBaseLayoutProps {
  children: React.ReactNode;
}

const BaseLayout: React.FunctionComponent<IBaseLayoutProps> = ({
  children,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={styles.layout}>
      <div
        className={classNames(styles.navigation, {
          [styles.navigationCollapsed]: collapsed,
        })}
      >
        <Menu collapsed={collapsed} setCollapsed={setCollapsed} />
      </div>
      <div
        className={classNames(styles.mainContent, {
          [styles.mainContentCollapsed]: collapsed,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default BaseLayout;
