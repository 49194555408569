import { CapabilityTool } from "../../models/tools";
import { toolNameMap } from "../../utils/toolUtil";

export const RemainingToolsList = ({
  remainingTools,
  title
}: {
  remainingTools: CapabilityTool[];
  title: string;
}) => (
  <div>
    <b>{title}</b>
    {remainingTools.map((ot, i) => (
      <li key={i}> {toolNameMap[ot]}</li>
    ))}
  </div>
);
