import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectHeiUser } from "../../../../Auth/store/accountSlice";
import { ProjectTool } from "../../../../Common/api/models/ProjectTool";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import useMultiCollapse from "../../../../Common/hooks/useMultiCollapse";
import { ToolModel } from "../../../CreateProject/models/ToolTile";
import { ProjectFinalizeFormType } from "../../hooks/useProjectDetails";
import FinalizeProjectPopup from "../FinalizeProjectPopup/FinalizeProjectPopup";
import ProjectToolTableRow from "../ProjectToolTableRow/ProjectToolTableRow";
import styles from "./ProjectToolsTable.module.scss";

interface ProjectToolsTableProps {
  tools: ProjectTool[];
  mandatoryTools: ToolModel[];
  userCanEdit: boolean;
  form: UseFormReturn<ProjectFormType>;
  handleDelete: (index: number) => void;
  handleFinalize: (values: ProjectFinalizeFormType) => Promise<void>;
}

const ProjectToolsTable = ({
  tools,
  mandatoryTools,
  userCanEdit,
  form,
  handleDelete,
  handleFinalize
}: ProjectToolsTableProps): JSX.Element => {
  const { ToggleCollapseButton, ToggleItemButton, itemsCollapse } =
    useMultiCollapse(() => {
      return tools.map((_, i) => i);
    });
    
  const [isFinalizeProjectPopupOpen, setIsFinalizeProjectPopupOpen] = useState(false);
  
  const finalizeForm = useForm<ProjectFinalizeFormType>({
    defaultValues: {toolProjectDeeplink: "", toolProjectId: "", toolId: "", capabilityId: ""},
    mode: "onBlur",
  });
  
  const heiUser = useSelector(selectHeiUser);
  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );

  const handleFinalizeSubmit = async () => {
    const valid = await finalizeForm.trigger();
    if (!valid) {
      return;
    }
    setIsFinalizeProjectPopupOpen(false);
    
    await handleFinalize(finalizeForm.getValues());
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ width: "92%", pb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <b>Tool</b>
              </TableCell>

              <TableCell>
                <div className={styles.toolDetails__tableHeader}>
                  <AccountCircleOutlinedIcon />

                  <b>Invite users</b>
                </div>
              </TableCell>
              <TableCell>
                <div className={styles.toolDetails__collapseHeaderCell}>
                  <div className={styles.toolDetails__tableHeader}>
                    <b>Status</b>
                  </div>
                  <ToggleCollapseButton />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tools.map((st, i) => {
              const toolOpen = itemsCollapse[i];
              const toolInviteMode = tools[i].inviteMode;
              const isHidden = tools[i].isHidden;
              const toolIsMandatory = mandatoryTools.some(
                (x) => x.capabilityToolKey == tools[i].capabilityToolKey
              );
              return (
                <ProjectToolTableRow
                  form={form}
                  handleDelete={handleDelete}
                  rowIndex={i}
                  tool={st}
                  toolInviteMode={toolInviteMode}
                  toolIsHidden={isHidden}
                  toolIsMandatory={toolIsMandatory}
                  userCanEdit={userCanEdit}
                  toolOpen={toolOpen}
                  key={i}
                  finalizeForm={finalizeForm}
                  ToggleItemButton={<ToggleItemButton index={i} />}
                  userIsAdmin={!!isAdmin}
                  setIsFinalizeProjectPopupOpen={setIsFinalizeProjectPopupOpen}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isAdmin && (
        <>
          <FinalizeProjectPopup
            open={isFinalizeProjectPopupOpen}
            form={finalizeForm}
            handleClose={() => setIsFinalizeProjectPopupOpen(false)}
            handleSubmit={handleFinalizeSubmit}/>
        </>
      )}
    </>
  );
};

export default ProjectToolsTable;
