import { CapabilityTool, toolOrder } from "../models/tools";

export const toolNameMap: Record<CapabilityTool, string> = {
  "ARTIFACT:VSTS": "Azure Artifacts",
  "BACKLOG:JIRA": "Jira + XRay",
  "BROWAPPAUT:BROWSER": "BrowserStack App Automate",
  "BROWAPPLIV:BROWSER": "BrowserStack App Live",
  "BROWAUT:BROWSER": "BrowserStack Automate",
  "BROWLIV:BROWSER": "BrowserStack Live",
  "BROWTEST:BROWSER": "BrowserStack",
  "CI:VSTS": "Azure Pipelines",
  "COLLAB:SLACK": "Slack",
  "CS:SONARQUBE": "SonarQube",
  "MONITORING:NEWRELIC": "New Relic",
  "MONITORING:SPLUNK": "Splunk",
  "REPO:VSTS": "Azure Repos",
  "UX:FIGMA": "Figma",
  "WIKI:CONFLUENCE": "Confluence",
  "TESTMAN:VSTS": "Azure Test Plans",
  "IAC:TERRAFORM": "Terraform",
  "COPILOT:GITHUBCOPILOT": "GitHub Copilot",
  "AZUREDEVOPS:VSTS": "Azure DevOps",
};

export const orderTools: (tools: CapabilityTool[]) => CapabilityTool[] = (
  tools
) => tools.sort((a, b) => toolOrder.indexOf(a) - toolOrder.indexOf(b));
