import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Box, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, debounce } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { AdminUserSearchDto, AdminUserSearchDtoProject } from "../api/models/adminUserSearchDto";
import { getColumns } from "./getAdminUsersGridColumns";

interface AdminUsersGridProps {
  users: AdminUserSearchDto[];
  setUsers: React.Dispatch<React.SetStateAction<AdminUserSearchDto[]>>;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

type Order = "asc" | "desc";

const AdminUsersGrid = ({ users, setUsers, setSearchValue }: AdminUsersGridProps): JSX.Element => {
  const columns = getColumns();

const [order, setOrder] = useState("asc" as Order);
const [orderBy, setOrderBy] = useState("adPrinciple" as keyof AdminUserSearchDto);

const compare = ( a: string, b: string ): number => {
  if ( a < b ){
    return -1;
  }
  if ( a > b ){
    return 1;
  }
  return 0;
}

const handleRequestSort = (
  _event: React.MouseEvent<unknown>,
  property: keyof AdminUserSearchDto,
  direction?: Order
) => {
  if (!direction) {
    if (orderBy == property) 
      direction = order == "asc" ? "desc" : "asc"
    else 
      direction = "asc";
  }

  setOrder(direction);
  setOrderBy(property);

  users.sort((a: AdminUserSearchDto, b: AdminUserSearchDto) => {
    return compare(a[property] as string, b[property] as string) * (direction == "desc" ? -1 : 1);
  });
  setUsers(users);
};

const createSortHandler =
(property: keyof AdminUserSearchDto) => (event: React.MouseEvent<unknown>) => {
  handleRequestSort(event, property);
};

const searchInputRef = useRef();

const performSearch = () => {
  const inputValue: string = searchInputRef.current!["value"];
  if (inputValue.length < 3)
    return;
  setSearchValue(inputValue);
  handleRequestSort(null as any, "name", "asc");
};

const debouncedHandleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key == "Enter" || e.key == "Tab")
    return;

  debouncedCallback();
};

const debouncedCallback = useCallback(debounce(() => {
  performSearch();
}, 500), []);

return (
  <>
      <Box sx={{ pl: "80px", pt: "24px", height: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mt: 2,
            mb: 3,
            ml: -1,
          }}
      >
        <IconButton
          type="submit"
          sx={{ p: "5px", marginTop: "14px" }}
          aria-label="search"
          onClick={performSearch}
        >
          <SearchRoundedIcon />
        </IconButton>
        <TextField
          sx={{
            width: 450,
            mr: 1,
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.38)",
            },
            "& .MuiInputBase-root.MuiInput-root.MuiInput-underline:before": {
              borderBottom: "1px solid rgba(0, 0, 0, 0.38)",
            },
          }}
          type="search"
          variant="standard"
          label={"Search name, Heiway account, email"}
          onKeyDown={debouncedHandleSearchKeyDown}
          inputRef={searchInputRef}
        />
      </Stack>
      <TableContainer
        component={Paper}
        sx={{ width: "92%", paddingBottom: 0, marginBottom: "70px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((headCell) => (
                <TableCell sx={{ width: headCell.width }} >
                    {headCell.sortable && 
                      <TableSortLabel
                        active={orderBy === headCell.field}
                        direction={orderBy === headCell.field ? order : "asc"}
                        onClick={createSortHandler(headCell.field as keyof AdminUserSearchDto)}>
                        <b>{headCell.headerName}</b>
                      </TableSortLabel>
                    }
                    {!headCell.sortable &&
                      <b>{headCell.headerName}</b>
                    }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: AdminUserSearchDto) => {
              return (
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {user.name}
                    </div>
                  </TableCell>
                  <TableCell> {user.adPrinciple}</TableCell>
                  <TableCell> {user.email}</TableCell>
                  <TableCell>{
                    user.projects.map((project: AdminUserSearchDtoProject) => {
                      return <>
                        <a href={`/project/${project.projectId}/details`}>
                        {(project.isArchived ? <s>{project.projectName}</s> : project.projectName)} - {project.roleName}
                        </a>
                        <br/>
                      </>;
                    })
                  }</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </>
  );
};

export default AdminUsersGrid;
