import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

interface DeleteFaqArticleModalWindowProps {
    open: boolean;
    handleClose: () => void;
    handleDelete: () => void;
  }

export const DeleteFaqArticleModalWindow = ({
    open,
    handleClose,
    handleDelete
  }: DeleteFaqArticleModalWindowProps): JSX.Element => {

    const navigate = useNavigate();
    
    const handleConfirmDelete = async () => {
      handleDelete();
      navigate(-1); 
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "900px",
            maxWidth: "none",
          },
        }}
      >
       <DialogTitle id="alert-dialog-title">
      {"Confirm Deletion"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to delete this FAQ article?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={handleClose}>Cancel</Button>
      <Button variant="contained" onClick={handleConfirmDelete} autoFocus color="error">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
    );
  };

