import { Skeleton, TableCell, TableRow } from "@mui/material";
import * as React from "react";

interface FaqResultSkeletonProps {
  numberOfRows?: number;
}

const FaqResultSkeleton = ({
  numberOfRows = 8,
}: FaqResultSkeletonProps): JSX.Element => {
  return (
    <>
      {new Array(numberOfRows).fill(0).map((_, i) => (
        <TableRow key={i}>
          <TableCell colSpan={5}>
            <Skeleton
              animation="wave"
              variant="text"
              width={"50%"}
              height={21}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default FaqResultSkeleton;
