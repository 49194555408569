import { CircularProgress, IconButton } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { downloadBillingCsv } from "../api/billingClient";
import { useState } from "react";

interface DownloadBillingButtonProps {
  id: any;
  fileName: any;
}

const DownloadBillingButton = ({
  id,
  fileName,
}: DownloadBillingButtonProps): JSX.Element => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await downloadBillingCsv(id, fileName);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <IconButton
      aria-label="download"
      color="primary"
      onClick={async () => await handleDownload()}
    >
      {isDownloading ? <CircularProgress /> : <FileDownloadOutlinedIcon />}
    </IconButton>
  );
};

export default DownloadBillingButton;
