import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { ToolModel } from "../../../CreateProject/models/ToolTile";
import ToolTile from "../../../CreateProject/components/ToolTile/ToolTile";
import styles from "./AddToolsPopup.module.scss";
interface AddToolsPopupProps {
  open: boolean;
  handleClose: () => void;
  handleAddTool: (tool: ToolModel) => void;
  tools: ToolModel[];
}

const AddToolsPopup = ({
  open,
  handleClose,
  handleAddTool,
  tools,
}: AddToolsPopupProps): JSX.Element => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle>Add tool(s)</DialogTitle>
      <DialogContent className={styles.dialog__content}>
        <Typography variant={"body2"} sx={{ marginBottom: "16px" }}>
          Choose the tool(s) you want to add to the project.
        </Typography>
        <div className={styles.dialog__tools}>
          {tools.map((tool, index) => {
            return (
              <ToolTile
                buttonLabel={"ADD"}
                alternateButtonLabel={""}
                key={index}
                tool={tool}
                toggleToolSelect={() => handleAddTool(tool)}
                selected={false}
              />
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToolsPopup;
