import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Backdrop, CircularProgress, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ProjectStatus } from "../../../../Common/models/project";
import {
  getProjectsCostCenterCount,
  toggleLock,
  unflagProject,
} from "../../api/myProjectsHttpClient";
import { useMemo, useState } from "react";
import FlagProjectPopup from "../FlagProjectPopup/FlagProjectPopup";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { selectHeiUser } from "../../../../Auth/store/accountSlice";

interface ProjectGridActionButtonProps {
  projectId: string;
  status: ProjectStatus;
  callForceRefresh: any;
}

const ProjectGridActionButton = ({
  projectId,
  status,
  callForceRefresh,
}: ProjectGridActionButtonProps): JSX.Element => {
  const isFlagged = useMemo(() => status === "Flagged", [status]);
  const isBlocked = useMemo(() => status === "Blocked", [status]);
  const [sameCostCenterCount, setSameCostCenterCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const heiUser = useSelector(selectHeiUser);

  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleFlag = async () => {
    setIsLoading(true);
    try {
      if (isFlagged) {
        await handleUnflagProject();
      } else {
        await handleFlagProject();
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } finally {
      setIsLoading(false);
      setAnchorEl(null);
    }
  };

  const handleUnflagProject = async () => {
    await unflagProject(projectId, isFlagged, false);
    enqueueSnackbar("Project has been successfuly unflagged", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    callForceRefresh.current();
  };

  const handleFlagProject = async () => {
    const sameCostCenterCountResponse = await getProjectsCostCenterCount(
      projectId
    );
    if (sameCostCenterCountResponse > 0) {
      setSameCostCenterCount(sameCostCenterCountResponse);
      setIsModalOpen(true);
    } else {
      await flagProjects();
    }
  };

  const flagProjects = async (multiple = false) => {
    await unflagProject(projectId, isFlagged, multiple);

    enqueueSnackbar(
      `${multiple ? "Projects" : "Project"} has been successfuly flagged`,
      {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      }
    );
    callForceRefresh.current();
  };

  const handleToggleLock = async () => {
    setIsLoading(true);
    try {
      if (isBlocked) {
        await handleUnlockProject();
      } else {
        await handleLockProject();
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } finally {
      setIsLoading(false);
      setAnchorEl(null);
    }
  };

  const handleUnlockProject = async () => {
    await toggleLock(projectId, false);
    enqueueSnackbar("Project has been successfuly unblocked", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    callForceRefresh.current();
  };

  const handleLockProject = async () => {
    await toggleLock(projectId, true);
    enqueueSnackbar("Project has been successfuly blocked", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    callForceRefresh.current();
  };

  if (!isAdmin) return <></>;
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FlagProjectPopup
        open={isModalOpen}
        handleClose={() => flagProjects()}
        handleSave={() => flagProjects(true)}
        sameCostCenterCount={sameCostCenterCount}
      />
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={async (event) => {
            event.stopPropagation();
            await handleToggleFlag();
          }}
        >
          {isFlagged ? "Unflag" : "Flag"}
        </MenuItem>
        <MenuItem
          onClick={async (event) => {
            event.stopPropagation();
            await handleToggleLock();
          }}
        >
          {isBlocked ? "Unblock" : "Block"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProjectGridActionButton;
