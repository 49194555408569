import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { IconButton, Stack, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridSortModel,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { gridSX } from "../../consts/gridSx";
import {
  FetchDataRequest,
  FetchDataResult,
  IFilterOption,
  IFilteringModel,
  IPaginationModel,
  ISortingModel,
} from "../../models/grid";
import styles from "./HeiGrid.module.scss";

interface HeiGridProps {
  columns: GridColDef[];
  fetchGridData: (request: FetchDataRequest) => Promise<FetchDataResult>;
  searchPlaceholder?: string;
  defaultSorting?: ISortingModel;
  handleRowClick?: GridEventListener<"rowClick">;
  refFunction?: any;
  filtering?: IFilteringModel;
  disableSearch?: boolean;
}
const ROW_HEIGHT = 52;
const HeiGrid = ({
  columns,
  fetchGridData,
  searchPlaceholder,
  defaultSorting,
  handleRowClick,
  refFunction,
  filtering,
  disableSearch,
}: HeiGridProps): JSX.Element => {
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [sortingModel, setSortingModel] =
    useState<ISortingModel | undefined>(defaultSorting);
  const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [filteringModel, setFilteringModel] = useState<IFilteringModel>(
    filtering ?? {
      filters: [],
    }
  );
  const [searchModel, setSearchModel] = useState<string>();
  const searchInpuRef = useRef();

  const debouncedHandleSearchKeyDown = useCallback(
    debounce(() => {
      submitSearch();
    }, 500),
    []
  );

  const submitSearch = () => {
    const searchValue = searchInpuRef.current!["value"];
    setSearchModel(searchValue);
    setFilteringModel({ filters: [] });
  };

  const fetchData = useCallback(async () => {
    setRows([]);
    setIsLoading(true);
    const { rows, totalRows } = await fetchGridData({
      pagination: paginationModel,
      sorting: sortingModel,
      search: searchModel,
      filtering: filteringModel,
    });
    setTotalRows(totalRows);
    setRows(rows);
    setIsLoading(false);
  }, [
    sortingModel,
    paginationModel,
    searchModel,
    fetchGridData,
    filteringModel,
  ]);

  useEffect(() => {
    fetchData();
    if (refFunction) refFunction.current = fetchData;
  }, [
    sortingModel,
    paginationModel,
    filteringModel,
    searchModel,
    fetchData,
    filteringModel,
  ]);

  const handleSortChange = (model: GridSortModel) => {
    const selectedSorting = model[0];
    if (selectedSorting) {
      setSortingModel({
        field: selectedSorting.field,
        sort: selectedSorting.sort,
      });
    } else {
      setSortingModel(undefined);
    }
  };

  const handlePageChange = (page: number) => {
    setPaginationModel((pm) => ({ ...pm, pageNumber: page + 1 }));
  };

  const handleFilterChange = (activeFilters: IFilterOption[]) => {
    setFilteringModel({ filters: activeFilters });
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPaginationModel((pm) => ({ pageNumber: 1, pageSize: pageSize }));
  };

  useEffect(() => {
    if (filtering) handleFilterChange(filtering.filters);
  }, [filtering]);

  return (
    <div>
      {!disableSearch && (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mt: 2,
            mb: 3,
            ml: -1,
          }}
        >
          <IconButton
            type="submit"
            sx={{ p: "5px", marginTop: "14px" }}
            aria-label="search"
            onClick={submitSearch}
          >
            <SearchRoundedIcon />
          </IconButton>
          <TextField
            sx={{
              width: 450,
              mr: 1,
              "& .MuiFormLabel-root.MuiInputLabel-root": {
                color: "rgba(0, 0, 0, 0.38)",
              },
              "& .MuiInputBase-root.MuiInput-root.MuiInput-underline:before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.38)",
              },
            }}
            type="search"
            variant="standard"
            label={searchPlaceholder || "Search"}
            onKeyDown={debouncedHandleSearchKeyDown}
            inputRef={searchInpuRef}
          />
        </Stack>
      )}
      <div
        data-testid="data-grid"
        className={styles.tableWrapper}
        style={{
          height: 111 + (rows.length > 0 ? rows.length * ROW_HEIGHT : 50),
        }}
      >
        <DataGrid
          sx={gridSX}
          rowHeight={ROW_HEIGHT}
          loading={isLoading}
          rows={rows}
          columns={columns}
          pageSize={paginationModel.pageSize}
          page={paginationModel.pageNumber - 1}
          rowsPerPageOptions={[10, 25, 50]}
          disableSelectionOnClick
          onSortModelChange={handleSortChange}
          sortModel={sortingModel ? [sortingModel] : []}
          sortingMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onRowClick={handleRowClick}
          paginationMode="server"
          rowCount={totalRows}
          filterMode="server"
        />
      </div>
    </div>
  );
};

export default HeiGrid;
