import axios from "axios";

import { UserSearch } from "../../../Common/models/UserSearch";

export const getUsers = async (
  filter: string,
  customFilterParams?: string
): Promise<UserSearch[]> => {
  const { data } = await axios.get<UserSearch[]>(
    `${window.configuration.apiUrl}api/Users/search?Filter=${filter}${customFilterParams}`
  );

  return data;
};
