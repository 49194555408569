import { Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import ProjectStatusBadge from "../../../../Common/components/ProjectStatusBadge/ProjectStatusBadge";
import { ProjectStatus } from "../../../../Common/models/project";
import { CapabilityTool } from "../../../../Common/models/tools";
import GridTools from "../GridTools/GridTools";
import ProjectGridActionButton from "../ProjectGridActionButton/ProjectGridActionButton";
import { orderTools } from "../../../../Common/utils/toolUtil";

const getStatusColor = (
  status: string
):
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning" => {
  switch (status) {
    case "Active":
      return "primary";
    case "Processing":
      return "info";
    case "Blocked":
      return "error";

    default:
      return "warning";
  }
};

export const getColumns = (callForceRefresh?: any): GridColDef[] => {
  const baseColumns = [
    {
      field: "name",
      headerName: "Project name",
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams<string>) => {
        const name = params.row.name;
        const isNew = params.row.isNew;

        return (
          <span>
            {name}
            {!!isNew && (
              <Chip sx={{ ml: "12px" }} size="small" color="info" label="New" />
            )}
          </span>
        );
      },
    },

    {
      field: "productOwner",
      headerName: "Product owner",
      disableColumnMenu: true,
      flex: 0.8,
    },

    {
      field: "costCentre",
      headerName: "Cost center",
      flex: 0.9,
      disableColumnMenu: true,
    },

    {
      field: "tools",
      headerName: "Tools",
      width: 180,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (!params.row.tools) {
          return <></>;
        }
        const tools: CapabilityTool[] = params.row.tools.split(",");
        return <GridTools tools={orderTools(tools)} />;
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 110,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<string>) => {
        const status = params.row.status as ProjectStatus;

        return <ProjectStatusBadge status={status} />;
      },
    },
  ];

  if (callForceRefresh) {
    return [
      ...baseColumns,
      {
        field: "projectAction",
        headerName: "",
        width: 60,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<string>) => {
          const id = params.row.id;
          const status = params.row.status as ProjectStatus;

          return (
            <ProjectGridActionButton
              projectId={id}
              status={status}
              callForceRefresh={callForceRefresh}
            />
          );
        },
      },
    ];
  }

  return baseColumns;
};
