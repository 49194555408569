import { Typography } from "@mui/material";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import HeiCheckbox from "../../../../Common/components/Form/HeiCheckbox/HeiCheckbox";
import HeiDynamicField from "../../../../Common/components/Form/HeiDynamicField/HeiDynamicField";
import HeiTextField from "../../../../Common/components/Form/HeiTextField/HeiTextField";
import ProjectTypeSelection from "../../components/ProjectTypeSelection/ProjectTypeSelection";
import ToolDetails from "../../components/ToolDetails/ToolDetails";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import useEnterDetails from "../../hooks/useEnterDetails";
import styles from "./EnterDetailsStep.module.scss";
interface EnterDetailsStepProps {
  form: UseFormReturn<ProjectFormType>;
}

const EnterDetailsStep = ({ form }: EnterDetailsStepProps): JSX.Element => {
  const { control } = form;

  const { opcos, validateUniqueProjectName, dynamicFields, packageId } =
    useEnterDetails();

  useEffect(() => {
    form.setValue("packageId", packageId);
  }, []);

  return (
    <div className={styles.details}>
      <Typography className={styles.details__title} variant="h6">
        Project details
      </Typography>

      <HeiTextField
        control={control}
        name="projectName"
        label="Project name"
        rules={{
          required: "Project name is required",
          maxLength: {
            value: 80,
            message: "Project name cannot be longer then 80 characters",
          },
          pattern: {
            value: /^[A-Za-z0-9-][A-Za-z0-9\s-]+[A-Za-z0-9-]+$/,
            message:
              "Project name is invalid. Please don`t use preceding spaces. Use only latin characters, digits, -",
          },
          validate: {
            projectNameUnique: async (value: string) =>
              (await validateUniqueProjectName(value))
                ? undefined
                : `Project name ${value} is taken`
          },
        }}
      />

      <HeiCheckbox
        control={control}
        name="hideProject"
        label="Hide this project"
        tooltip="Hide this project if it should not be publicly visible within Heineken. Note: team members cannot onboard themselves on hidden projects."
      />

      <ProjectTypeSelection form={form} opcos={opcos} />

      {dynamicFields && (
        <>
          <Typography className={styles.packageDetails__title} variant="h6">
            Package details
          </Typography>

          {dynamicFields.map((df) => (
            <HeiDynamicField control={control} field={df} />
          ))}
        </>
      )}

      <br />

      <ToolDetails form={form} />
    </div>
  );
};

export default EnterDetailsStep;
