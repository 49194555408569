import { DynamicField } from "../../models/form";
import { CapabilityTool } from "../../models/tools";
import { ProjectFormType } from "../../hooks/useCreateProject";
import { EntityState } from "./EntityState";
import { AvailableRoles } from "../../models/FormModels";

export enum ToolStatusEnum {
  NotFound = "NotFound",
  CreationPending = "CreationPending",
  Created = "Created",
  PendingRemoval = "PendingRemoval",
  Archived = "Archived",
  New = "New",
}

export interface ProjectTool {
  projectId: string;
  toolId: string;
  capabilityId: string;
  toolProjectId: string;
  toolProjectName: string;
  toolProjectDeeplink: string;
  customBillingMetric: number;
  optOut: boolean;
  status: ToolStatusEnum;
  inviteMode: string;
  toolVisibility: string;
  isHidden: boolean;
  ServiceNowGroup: string;
  capabilityToolKey: CapabilityTool;
  toolName: string;
  toolDisplayName: string;
  capabilityName: string;
  toolEditStatus: EntityState;
  CustomFields?: DynamicField<ProjectFormType>[];
  isToolNew: boolean;
  roles?: AvailableRoles[];
  isManual: boolean;
}
