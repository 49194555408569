import { getProjectCost } from "../api/projectDetailsHttpClient";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { ProjectToolCost } from "../../ProjectsList/api/models/ProjectToolCost";

export const useProjectCostGrid = (id: string) => {
  const getProjectCostCallback = useCallback(() => getProjectCost(id), [id]);

  const { data: projectCost, isLoading } = useQuery(
    ["project_cost", id],
    getProjectCostCallback
  );
  const azureDevOpsName = "Azure DevOps";
  const azureDevOpsTool = projectCost?.toolCosts.find((item: ProjectToolCost) => item.toolName === azureDevOpsName);
  if (azureDevOpsTool) {
    azureDevOpsTool.toolDisplayName = azureDevOpsName;
    azureDevOpsTool.capabilityToolKey = "AZUREDEVOPS:VSTS";
  }
  return { projectCost, isLoading };
};
