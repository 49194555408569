import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface SelfonboardingPopupProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  projectName: string;
}

const RemoveProjectPopup = ({
  open,
  handleClose,
  projectName,
  handleSubmit,
}: SelfonboardingPopupProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      data-testid="removeprojectpopup"
    >
      <DialogTitle>Do you want to remove this project?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
          You are about to remove the project {projectName}? <br />
          This action can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="error">
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveProjectPopup;
