import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const gridSX: SxProps<Theme> = {
  "&": {
    color: "black",
  },
  "& .MuiDataGrid-columnHeader": {
    padding: 0,
  },
  ".MuiDataGrid-cell:focus": {
    outline: "none",
  },
  ".MuiDataGrid-cell": {
    paddingLeft: "16px",
  },
  "& .MuiDataGrid-row:hover": {
    color: "primary.main",
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell:hover": {
    color: "primary.main",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    paddingLeft: "16px",
    fontWeight: "bold",
    fontFamily: "HEINEKEN Core",
  },
  "& .MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
};
