import * as React from 'react';
import styles from './TopBar.module.scss'

interface TopBarProps {
    children: React.ReactNode
}

const TopBar = (props: TopBarProps) => {
  return <div className={styles.topBar}>
    {props.children}
  </div>;
};

export default TopBar;
