import { Box, Tab, Tabs } from "@mui/material";
import React, { useMemo } from "react";
import { generatePath, Link } from "react-router-dom";
import routes from "../../../../Common/consts/routes";
import MyProjectsGrid from "../MyProjectsGrid/MyProjectsGrid";
import OpenProjectsGrid from "../OpenProjectsGrid/OpenProjectsGrid";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`project-details-tabpanel-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

interface ProjectsTabPanelsProps {
  routeTabIndex: number;
}

const ProjectsTabPanels = ({ routeTabIndex }: ProjectsTabPanelsProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          pl: "100px",
          background: "white",
        }}
      >
        <Tabs value={routeTabIndex}>
          <Link
            to={generatePath(routes.projects, {
              tab: "my",
            })}
          >
            <Tab label={"MY PROJECTS"} />
          </Link>

          <Link
            to={generatePath(routes.projects, {
              tab: "other",
            })}
          >
            <Tab label={"OTHER PROJECTS"} />
          </Link>
        </Tabs>
      </Box>
      <TabPanel value={routeTabIndex} index={0}>
        <MyProjectsGrid />
      </TabPanel>
      <TabPanel value={routeTabIndex} index={1}>
        <OpenProjectsGrid />
      </TabPanel>
    </Box>
  );
};

export default ProjectsTabPanels;
