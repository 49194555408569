import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useRef, useState } from "react";
interface FaqSearchProps {
  setSearchValue: (value: string | undefined) => void;
}

const FaqSearch = ({ setSearchValue }: FaqSearchProps): JSX.Element => {
  const searchInpuRef = useRef<HTMLInputElement>();

  const submitSearch = () => {
    const searchValue = searchInpuRef.current!["value"];
    setSearchValue(searchValue);
  };
  const handleSearchKeyDown = (event: any) => {
    if (event.key === "Enter") {
      submitSearch();
    }
  };

  const [hasFilter, setHasFilter] = useState(false);

  const onChange = () => {
    const searchValue = searchInpuRef.current!["value"];
    setHasFilter(!!searchValue);
  };

  const handleClear = () => {
    searchInpuRef.current!.value = "";
    setSearchValue(undefined);
    setHasFilter(false);
  };

  return (
    <FormControl sx={{ mt: 2, mb: 2, width: "95%" }} variant="outlined">
      <InputLabel htmlFor="faq-search-input">Search</InputLabel>
      <OutlinedInput
        onChange={onChange}
        onKeyDown={handleSearchKeyDown}
        inputRef={searchInpuRef}
        label="Search"
        sx={{ background: "white" }}
        id="faq-search-input"
        data-testid="faq-search-input"
        placeholder="Enter a topic or tool"
        endAdornment={
          <InputAdornment position="end">
            {hasFilter ? (
              <IconButton aria-label="Clear" onClick={handleClear}>
                <CancelRoundedIcon />
              </IconButton>
            ) : (
              <IconButton aria-label="Search" onClick={submitSearch}>
                <SearchRoundedIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
        aria-describedby="faq-search-input-text"
        inputProps={{
          "aria-label": "weight",
        }}
      />
      <FormHelperText id="faq-search-input-text">
        Press enter to search
      </FormHelperText>
    </FormControl>
  );
};

export default FaqSearch;
