import HeiGrid from "../../../../Common/components/HeiGrid/HeiGrid";
import { useProjectHistoryGrid } from "../../hooks/useProjectHistoryGrid";
import { getProjectHistoryColumns } from "./projectHistoryGridColumns";

interface ProjectHistoryGridProps {
  id: string;
}

const ProjectHistoryGrid = ({ id }: ProjectHistoryGridProps): JSX.Element => {
  const columns = getProjectHistoryColumns();
  const { getProjectHistory } = useProjectHistoryGrid(id);

  return (
    <HeiGrid
      disableSearch
      columns={columns}
      fetchGridData={getProjectHistory}
      defaultSorting={{ field: "executedOn", sort: "desc" }}
    />
  );
};

export default ProjectHistoryGrid;
