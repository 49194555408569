import {
  Button,
  Collapse,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

interface FaqResultRowProps {
  open: boolean;
  synopsis: string;
  id: string;
}

const FaqResultRow = ({
  open,
  synopsis,
  id,
}: FaqResultRowProps): JSX.Element => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/faq/" + id);

  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={5}>
        <Collapse in={open} sx={{ width: "100%" }}>
          <div style={{ padding: "24px", background: "#f5f5f5" }}>
            <Typography variant="body2">{synopsis}</Typography>

            <Button variant="text" sx={{ mt: 1 }} onClick={handleClick}>
              FULL ARTICLE
            </Button>
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default FaqResultRow;
