import { InfoOutlined } from "@mui/icons-material";
import { InputAdornment, Tooltip } from "@mui/material";
import * as DOMPurify from "dompurify";
import { Control, FieldValues } from "react-hook-form";
import { DynamicField } from "../../../models/form";
import HeiAsyncTypeahead from "../HeiAsyncTypeahead/HeiAsyncTypeahead";
import HeiAutocomplete from "../HeiAutocomplete/HeiAutocomplete";
import HeiTextField from "../HeiTextField/HeiTextField";

interface HeiDynamicFieldProps<TForm extends FieldValues> {
  field: DynamicField<TForm>;
  control: Control<TForm>;
}

const HeiDynamicField = <TForm extends FieldValues>({
  control,
  field: {
    name,
    label,
    type,
    placeholder,
    pattern,
    required,
    selectOptions,
    tooltip,
    secret,
    title,
    description,
    endpoint,
    disabled,
  },
}: HeiDynamicFieldProps<TForm>): JSX.Element => {
  const rules = {
    pattern: pattern
      ? { value: pattern, message: `Enter valid ${label} value` }
      : undefined,
    required: required ? `${label} is required` : undefined,
  };

  const inputSx = { mr: "24px", mb: "24px", width: "45%" };

  return (
    <>
      {type === "Text" && (
        <HeiTextField
          control={control}
          name={name}
          label={label}
          rules={rules}
          disabled={disabled}
          placeholder={placeholder}
          sx={inputSx}
          secret={secret}
          InputProps={
            tooltip
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(tooltip),
                            }}
                          />
                        }
                        arrow
                      >
                        <InfoOutlined fontSize="small" color="action" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }
              : undefined
          }
        />
      )}

      {type == "Dropdown" && (
        <HeiAutocomplete
          control={control}
          name={name}
          label={label}
          options={selectOptions || []}
          rules={rules}
          sx={inputSx}
          disabled={disabled}
        />
      )}

      {type == "AsyncTypeahead" && (
        <HeiAsyncTypeahead
          control={control}
          name={name}
          label={label}
          rules={rules}
          title={title}
          description={description}
          endpoint={endpoint || ""}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default HeiDynamicField;
