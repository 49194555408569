import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import HeiTextField from "../../../../Common/components/Form/HeiTextField/HeiTextField";
import { ProjectFinalizeFormType } from "../../hooks/useProjectDetails";

interface FinalizeProjectPopupProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  form: UseFormReturn<ProjectFinalizeFormType>;
}

const FinalizeProjectPopup = ({
  open,
  handleClose,
  handleSubmit,
  form
}: FinalizeProjectPopupProps): JSX.Element => {
  const { control } = form;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      data-testid="finalizeptcpopup"
    >
      <DialogTitle>Finalizing project creation</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: "12px", pb: "6px", height: "100%" }}>
          <HeiTextField control={control} name="toolProjectId" label="Tool Project Id" sx={{ mt:"6px", width: "100%" }} 
            rules={{
              required: "Tool Project Id is required"
            }}/>
          <HeiTextField control={control} name="toolProjectDeeplink" label="Tool Project Deeplink" sx={{ mt:"18px", width: "100%" }}
            rules={{
              required: "Tool Project Deeplink is required",
              pattern: {
                value: /^https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i,
                message: "Enter valid URL",
              },
            }}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="success">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinalizeProjectPopup;
