import { Button, Typography } from "@mui/material";
import AdditionalToolIcon from "../../../../Common/components/AdditionalToolIcon/AdditionalToolIcon";
import ToolIcon from "../../../../Common/components/ToolIcon/ToolIcon";
import { IconSize } from "../../../../Common/models/iconSize";
import { CapabilityTool } from "../../../../Common/models/tools";
import { toolNameMap } from "../../../../Common/utils/toolUtil";
import styles from "./Package.module.scss";
import { RemainingToolsList } from "../../../../Common/components/RemainingToolsList/RemainingToolsList";
interface PackageProps {
  name: string;
  description: string;
  moreInfo: string;
  tools: CapabilityTool[];
  optionalTools: CapabilityTool[];
  onMoreInfoClick: () => void;
  selectPackageHandler: () => void;
}


const Package = ({
  name,
  description,
  tools,
  optionalTools,
  onMoreInfoClick,
  selectPackageHandler,
}: PackageProps): JSX.Element => {
  const mainToolsSource = tools.length > 0 ? tools : optionalTools.slice(0, 5);
  return (
    <div className={styles.package}>
      <div className={styles.package__content}>
        <div className={styles.package__tools}>
          {mainToolsSource.map((t, i) => (
            <div key={i} style={{ marginLeft: -8, fontSize: "16px" }}>
              {" "}
              <ToolIcon
                tool={t}
                size={IconSize.Large}
                tooltipTitle={toolNameMap[t]}
              />
            </div>
          ))}

          {optionalTools.length > 0 && (
            <AdditionalToolIcon
              additionalToolsCount={
                name === "Custom"
                  ? optionalTools.length - 5
                  : optionalTools.length
              }
              tooltipTitle={
                <RemainingToolsList title="Optional tools" remainingTools={optionalTools} />
              }
            />
          )}
        </div>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body2" className={styles.package__description}>
          {description}
        </Typography>
      </div>
      <div className={styles.package__actionButtons}>
        <Button
          sx={{ marginRight: "8px" }}
          color="secondary"
          variant="outlined"
          onClick={onMoreInfoClick}
        >
          More info
        </Button>
        <Button variant="contained" onClick={selectPackageHandler}>
          SELECT
        </Button>
      </div>
    </div>
  );
};

export default Package;
