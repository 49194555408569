import HeiGrid from "../../../../Common/components/HeiGrid/HeiGrid";
import styles from "./PendingRequestsGrid.module.scss";

import { pendingRequestsDefaultSorting } from "../../api/models/pendingRequestsDefaultSorting";
import { getPendingRequestsColumns } from "./PendingRequestsGridColumns";
import { usePendingRequestsGrid } from "../../hooks/usePendingRequestsGrid";
import usePageTitle from "../../../../Common/hooks/usePageTitle";

const PendingRequestsGrid = () => {
  usePageTitle("Self Onboarding Pending Requests");
  const { fetchGridData } = usePendingRequestsGrid();
  const columns = getPendingRequestsColumns();

  return (
    <div className={styles.view}>
      <HeiGrid
        columns={columns}
        fetchGridData={fetchGridData}
        searchPlaceholder="Search name, email, project, role"
        defaultSorting={pendingRequestsDefaultSorting}
      />
    </div>
  );
};

export default PendingRequestsGrid;
