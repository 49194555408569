import { InfoOutlined } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";

interface HeiCheckboxProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  label: string;
  tooltip?: string | React.ReactNode;
  sx?: SxProps<Theme>;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  disabled?: boolean;
}
const HeiCheckbox = <TForm extends FieldValues>({
  control,
  name,
  label,
  tooltip,
  sx,
  rules,
  disabled,
}: HeiCheckboxProps<TForm>): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, ...field } }) => (
        <div
          style={{ marginTop: "16px", display: "flex", alignItems: "center" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                sx={sx}
                onChange={onChange}
                checked={value}
                {...field}
              />
            }
            label={label}
          />
          {tooltip && (
            <Tooltip title={tooltip} arrow>
              <InfoOutlined sx={{ ml: -1 }} fontSize="small" color="action" />
            </Tooltip>
          )}
        </div>
      )}
    />
  );
};

export default HeiCheckbox;
