import { Alert, AlertTitle } from "@mui/material";

import styles from "./InformationAlert.module.scss";
interface InformationAlertProps {
  alertTitle?: string;
  alertBodyText?: string;
}

const InformationAlert = ({
  alertTitle,
  alertBodyText,
}: InformationAlertProps): JSX.Element => {
  return (
    <Alert
      className={styles.informationAlert}
      variant="standard"
      severity="info"
      sx={{ marginTop: 2 }}
    >
      <AlertTitle className={styles.informationAlert__title}>
        {alertTitle}
      </AlertTitle>
      <span className={styles.informationAlert__text}>{alertBodyText}</span>
    </Alert>
  );
};

export default InformationAlert;
