export const defaultValues = {
    title: "",
    synopsis: "",
    article: "",
    alertTitle: "",
    alertBody: "",
    tools: [""],
  };
  
  export type FaqArticleFormType = typeof defaultValues & {
    tools: string[];
  };