import {
  FetchDataRequest,
  FetchDataResult
} from "../models/grid";
import { SieveRequest, SieveResponse } from "../models/sieve";

export const getSieveRequestModel = (
  request: FetchDataRequest
): SieveRequest => {
  let sorts = "";

  const { sorting, search, pagination, filtering } = request;

  if (sorting && sorting.sort) {
    sorts = `${sorting.sort === "asc" ? "" : "-"}${sorting.field}`;
  }

  let filters = "";
  if (filtering && filtering.filters) {
    filtering.filters.forEach(
      (f) =>
        (filters += `${f.column}${f.operator}${f.value
          .replaceAll(",", "\\,")
          .replaceAll("|", "\\|")},`)
    );
  }

  if (search) {
    filters += `GlobalSearch==${search
      .replaceAll(",", "\\,")
      .replaceAll("|", "\\|")}`;
  }

  return {
    page: pagination.pageNumber,
    pageSize: pagination.pageSize,
    sorts: sorts,
    filters: filters,
  };
};

export const mapToFetchDataResult = (
  result: SieveResponse
): FetchDataResult => {
  return {
    rows: result.data,
    totalRows: result.paging.totalRecordCount,
  };
};
