import { Tooltip } from "@mui/material";
import classNames from "classnames";
import {
  toolStatusIcon,
  toolStatusTooltipTitle,
} from "../../../../Common/utils/toolStatusUtil";
import { ToolStatusEnum } from "../../../../Common/api/models/ProjectTool";
import styles from "./ToolStatusBadge.module.scss";

interface ToolStatusProps {
  status: ToolStatusEnum;
}

const ToolStatusBadge = ({ status }: ToolStatusProps) => (
  <Tooltip title={toolStatusTooltipTitle[status]} arrow placement="top">
    <div className={classNames(styles.badge, styles[status])}>
      {toolStatusIcon[status]}
    </div>
  </Tooltip>
);

export default ToolStatusBadge;
