import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {
  AccountCircleOutlined,
  EuroOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ToolIcon from "../../../../Common/components/ToolIcon/ToolIcon";
import { IconSize } from "../../../../Common/models/iconSize";

import classNames from "classnames";
import { ToolModel } from "../../models/ToolTile";
import styles from "./ToolTile.module.scss";

interface ToolTileSelectorProps {
  tool: ToolModel;
  selected: boolean;
  toggleToolSelect: (tool: ToolModel) => void;
  buttonLabel: string;
  alternateButtonLabel: string;
}

const ToolTileSelector = ({
  tool,
  selected,
  toggleToolSelect,
  buttonLabel,
  alternateButtonLabel,
}: ToolTileSelectorProps) => {
  return (
    <div
      className={classNames(styles.toolTile, {
        [styles.toolTile__selected]: selected,
      })}
    >
      <ToolIcon tool={tool.capabilityToolKey} size={IconSize.Large} tooltipTitle={tool.toolName} />

      <div className={styles.toolTile__content}>
        <div className={styles.toolTile__capabilityWrapper}>
          <Typography
            className={styles.toolTile__capability}
            color="text.secondary"
          >
            {tool.capabilityName}
          </Typography>
          <Tooltip title={tool.description} arrow>
            <InfoOutlined fontSize="small" color={"action"} />
          </Tooltip>
        </div>

        <Typography variant="h6" className={styles.toolTile__toolName}>
          {tool.toolDisplayName ?? tool.toolName}
        </Typography>

        <div className={styles.toolTile__selection}>
          <div className={styles.toolTile__selection__icons}>
            <Tooltip
              title={
                tool.isImmediateAccess
                  ? "Instantly available"
                  : "Available in 2 days"
              }
              arrow
            >
              <AccessTimeOutlinedIcon
                sx={{ marginRight: 1 }}
                fontSize="small"
                color={"action"}
              />
            </Tooltip>
            <Tooltip
              title={
                tool.isUserAutoAdd
                  ? "Users are automatically invited upon creation of this project"
                  : "Users can be manually invited later"
              }
              arrow
            >
              <AccountCircleOutlined
                sx={{ marginRight: 1 }}
                fontSize="small"
                color={"action"}
              />
            </Tooltip>
            <Tooltip title={tool.cost} arrow>
              <EuroOutlined
                sx={{ marginRight: 1 }}
                fontSize="small"
                color={"action"}
              />
            </Tooltip>
          </div>

          <Button
            variant="contained"
            onClick={() => toggleToolSelect(tool)}
            disabled={!tool.isOptional}
            size={"small"}
          >
            {selected ? alternateButtonLabel : buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ToolTileSelector;
