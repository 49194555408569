import { IconButton, Tooltip } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

interface ToolLaunchIconProps {
  toolProjectDeeplink?: string;
}

const ToolLaunchIcon = ({
  toolProjectDeeplink,
}: ToolLaunchIconProps): JSX.Element => {
  return (
    <>
      {toolProjectDeeplink && (
        <Tooltip title="Link to project in the tool" arrow placement="top">
          <IconButton
            aria-label="open"
            onClick={() => window.open(toolProjectDeeplink)}
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default ToolLaunchIcon;
