import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import HeiSwitch from "../Form/HeiSwitch/HeiSwitch";
import { ProjectFormType } from "../../hooks/useCreateProject";

interface SelfonboardingPopupProps {
  open: boolean;
  handleClose: () => void;
  form: UseFormReturn<ProjectFormType>;
  title: string;
  contentText?: string;
  displayAlertInfo: boolean;
  handleSave?: () => void;
}

const CellWrapper = ({ children }: { children: React.ReactNode }) => (
  <div style={{ display: "flex", justifyContent: "flex-end" }}>{children}</div>
);

const SelfonboardingPopup = ({
  open,
  handleClose,
  title,
  contentText,
  form,
  displayAlertInfo,
  handleSave,
}: SelfonboardingPopupProps): JSX.Element => {
  const { control } = form;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3, whiteSpace: "pre-wrap" }}>
          {contentText || (
            <span>
              Self-onboarding allows any user with a valid Entra ID account to
              use the Toolchain self-service portal to request access to your
              project in the role of team member or stakeholder. <br />
              While Self-onboarding can be configured to add users without
              explicit approval of the team's product owner or scrum master,
              caution is advised though, as it will automatically grant
              permissions to access your team's tools for which Invite users has
              been set to Automatically.
            </span>
          )}
        </DialogContentText>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <b>Role</b>
                </TableCell>
                <TableCell align="right">
                  <b>Manual approval?</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>Team members</TableCell>
                <TableCell align="right">
                  <CellWrapper>
                    <HeiSwitch
                      control={control}
                      name="teamMembersManualApproval"
                      startLabel="No"
                      endLabel="Yes"
                    />
                  </CellWrapper>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Stakeholders</TableCell>
                <TableCell align="right">
                  <CellWrapper>
                    <HeiSwitch
                      control={control}
                      name="stakeholdersManualApproval"
                      startLabel="No"
                      endLabel="Yes"
                    />
                  </CellWrapper>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {displayAlertInfo && (
          <Alert variant="standard" severity="info" sx={{ marginTop: 3 }}>
            <AlertTitle>
              Product owners and scrum masters can never onboard themselves.
            </AlertTitle>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          CLOSE
        </Button>
        {handleSave && (
          <Button
            disabled={!form.formState.isDirty}
            variant="contained"
            onClick={handleSave}
            autoFocus
          >
            SAVE
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SelfonboardingPopup;
