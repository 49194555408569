import axios from "axios";
import { BillingDto } from "./models/billingDto";
import { saveAs } from "file-saver";

export const getBillings = async (): Promise<BillingDto[]> => {
  const { data: billings } = await axios.get<BillingDto[]>(
    `${window.configuration.apiUrl}api/billing`
  );

  return billings;
};

export const downloadBillingCsv = async (id: string, fileName: string) => {
  try {
    const response = await axios({
      method: "get",
      url: `${window.configuration.apiUrl}api/Billing/${id}/csv`,
      responseType: "blob",
    });

    saveAs(response.data, fileName);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const generateNewBilling = async () => {
  try {
     await axios({
      method: "post",
      url: `${window.configuration.apiUrl}api/Billing/generate`,
    });
  } catch (error) {
    console.error("Error generating report:", error);
  }
};
