import {
  AccountInfo,
  IPublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import store from "../../App/store/store";
import {
  HeiUser,
  selectToken,
  setHeiUser,
  setToken,
} from "../store/accountSlice";
import axios from "axios";

const registerSession = async () => {
  await axios.get(`${window.configuration.apiUrl}api/Users/session`);
};

export const getTokenAndStore = async (
  publicClient: IPublicClientApplication,
  account: AccountInfo,
  forceRefresh?: boolean
) => {
  const token = selectToken(store.getState());
  if (token && !forceRefresh) {
    return token;
  }

  try {
    const clientId = window.configuration.apiClientId;

    const request: SilentRequest = {
      scopes: [`api://${clientId}/User.Read`],
      forceRefresh: forceRefresh || false,
      account: account,
    };

    const response = await publicClient?.acquireTokenSilent(request);

    const newToken = response?.accessToken;
    store.dispatch(setToken(newToken));

    const jwtPayload = getJwtPayload(newToken);

    const userName =
      [jwtPayload["given_name"], jwtPayload["family_name"]]
        .filter((p) => p)
        .join(" ") || jwtPayload["name"];

    const heiUser: HeiUser = {
      name: userName,
      email: jwtPayload["email"] || jwtPayload["unique_name"],
      roles: jwtPayload["roles"] || [],
      initials: getFirstLetters(userName),
    };
    store.dispatch(setHeiUser(heiUser));

    // setTimeout(async () => {
    //   await registerSession();
    //   setInterval(async () => {
    //     await registerSession();
    //   }, 1000 * 60 * 5);
    // }, 5000);

    //   setTimeout(async () => {
    //     await getTokenAndStore(publicClient, account, true)
    //   }, 1000 * 60 * 15)

    return newToken;
  } catch (error) {
    console.error(error);

    // localStorage.clear();
    // sessionStorage.clear();
    // window.location.reload();

    return "";
  }
};

export default function getJwtPayload(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function getFirstLetters(str: string) {
  const words = str.split(" ");
  const result = [];

  for (const word of words) {
    result.push(word[0]);
  }
  return result.join("");
}
