import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { getOpcosOptions } from "../../CreateProject/api/projectsHttpClient";
import { ProjectFormType } from "../../../Common/hooks/useCreateProject";
import { ProjectDetails } from "../api/models/ProjectDetails";
import { updateProjectDetails } from "../api/projectDetailsHttpClient";

const useUpdateProjectDetails = (project: ProjectDetails) => {
  const { data } = useQuery("getOpcos", getOpcosOptions);

  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const mappedProject = {
    ...project,
    packageCustomField: project.projectCustomFields,
  };

  const form = useForm<ProjectFormType>({
    defaultValues: mappedProject,
    mode: "onBlur",
  });

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (await form.trigger()) {
      setIsSaving(true);
      try {
        const updatedProject = form.getValues();
        await updateProjectDetails(project.id, updatedProject);
        form.reset({}, { keepValues: true });

        enqueueSnackbar("Project has been updated!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        queryClient.invalidateQueries(["project_details", project.id]);
      } catch (error: any) {
        const errorList = error?.response?.data?.errors;
        Object.entries(errorList).forEach(([key, value]) => {
          const errors = value as string[];
          errors.forEach((error) => {
            enqueueSnackbar(key + ": " + error, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            });
          });
        });
      } finally {
        setIsSaving(false);
      }
    }
  };

  return { handleSubmit, isSaving, form, opcos: data };
};

export default useUpdateProjectDetails;
