import { useForm } from "react-hook-form";
import { removeOpco, saveOpco } from "../../../api/adminOpcoClient";
import { OperationType } from "../AdminOpcoRow/AdminOpcoRow";
import { EmptyGuid } from "../AdminOpcosGrid";
import { AdminOpcoDto } from "../../../api/models/AdminOpcoDto";
import { useSnackbar } from "notistack";
import { useState } from "react";

export const useAdminOpcoRow = (
    opco: AdminOpcoDto, 
    reloadData: () => Promise<void>, 
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, 
    edited?: boolean, 
    setAdding?: React.Dispatch<React.SetStateAction<boolean>>) => {

    const { enqueueSnackbar } = useSnackbar();
    const [isEdited, setIsEdited] = useState(edited ?? false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const opcoForm = useForm({defaultValues: {name: opco.name, infoRequired: opco.infoRequired}, mode: "onBlur"});

    const handleSave = async () => {
        opco.name = opcoForm.getValues("name");
        opco.infoRequired = opcoForm.getValues("infoRequired");
        if (opco.id != EmptyGuid) {
            await handleOperation("update");
            setIsEdited(false);
        }
        else if (await handleOperation("create")) {
            await reloadData();
            setAdding && setAdding(false);
        }
    };
    const handleCancel = () => {
        opcoForm.reset();
        if (opco.id)
            setIsEdited(false);
        if (setAdding)
            setAdding(false);
    };
    const handleOperation = async (operation: OperationType): Promise<boolean> => {
        const operationString = operation.substring(0, operation.length - 1);
        try {
            setIsLoading(true);
            await (operation == "remove" ? removeOpco(opco.id) : saveOpco(opco));
            enqueueSnackbar(`OpCo ${operation}d`, {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                });
    
            setIsLoading(false);
            return true;
        } catch (exception) {
            enqueueSnackbar(`Something went wrong while ${operationString}ing OpCo`, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                });
            setIsLoading(false);
            return false;
        }
    }
    const handleDelete = async () => {
        if (await handleOperation("remove")) {
            setShowConfirmationModal(false);
            await reloadData();
        }
    }

    return {
        opcoForm,
        isEdited,
        setIsEdited,
        handleCancel,
        handleDelete,
        handleSave,
        showConfirmationModal,
        setShowConfirmationModal,
        enqueueSnackbar
    };
}
