import axios from "axios";
import { FaqArticleFormType } from "../../Common/models/FaqArticle";

export const createFaqArticle = async (
  faqForm: FaqArticleFormType
): Promise<void> => {
  await axios.post<FaqArticleFormType>(
    `${window.configuration.apiUrl}api/faq/`,
    faqForm
  );
};
