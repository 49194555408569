import { Typography } from "@mui/material";
import * as React from "react";
import { IconSize } from "../../models/iconSize";
import { CapabilityTool } from "../../models/tools";
import ToolIcon from "../ToolIcon/ToolIcon";
import styles from "./ToolCard.module.scss";

interface ToolCardProps {
  toolKey: CapabilityTool;
  capabilityName: string;
  toolName: string;
  tooltip: string;
}

const ToolCard = ({
  toolKey,
  capabilityName,
  toolName,
  tooltip = ""
}: ToolCardProps): JSX.Element => {
  return (
    <div className={styles.toolDetails__tool}>
      <ToolIcon tool={toolKey} size={IconSize.Large} tooltipTitle={tooltip} />

      <div className={styles.toolDetails__info}>
        <div className={styles.toolDetails__capabilityWrapper}>
          <Typography
            className={styles.toolDetails__capability}
            color="text.secondary"
          >
            {capabilityName}
          </Typography>
        </div>
        <Typography variant="h6" className={styles.toolDetails__toolName}>
          {toolName}
        </Typography>
      </div>
    </div>
  );
};

export default ToolCard;
