import { Tooltip } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import styles from "./RequestStatusBadge.module.scss";
import { RequestStatus } from "../../models/requestStatus";
import { requestStatusIcon } from "../../utils/requestStatusUtil";

interface ProjectStatusBadgeProps {
  status: RequestStatus;
  updatedBy: string;
}

const RequestStatusBadge = ({
  status,
  updatedBy,
}: ProjectStatusBadgeProps): JSX.Element => {
  const toolTipText = getToolTipText(status, updatedBy);
   
  return (
    <Tooltip title={toolTipText} arrow placement="top">
      <div className={classNames(styles.badge, styles[status])}>
        {requestStatusIcon[status]}
      </div>
    </Tooltip>
  );
};

const getToolTipText = (status: RequestStatus, updatedBy: string): string => {

  switch (status) { 

    case "AutoApproved":
      return "Auto Approved";
    case "AddedToProject":
      return `Added to project by ${updatedBy}`;
    default:
      return `${status} by ${updatedBy}`;
  }
}


export default RequestStatusBadge;
