import { Tooltip } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { ProjectStatus } from "../../models/project";
import { projectStatusIcon } from "../../utils/projectStatusUtil";
import styles from "./ProjectStatusBadge.module.scss";

interface ProjectStatusBadgeProps {
  status: ProjectStatus;
}

const ProjectStatusBadge = ({
  status,
}: ProjectStatusBadgeProps): JSX.Element => {
  return (
    <Tooltip title={status} arrow placement="top">
      <div className={classNames(styles.badge, styles[status])}>
        {projectStatusIcon[status]}
      </div>
    </Tooltip>
  );
};

export default ProjectStatusBadge;
