import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { removeOpcoOwner, saveOpcoOwner } from "../../../api/adminOpcoClient";
import { AdminOpcoManagerDto } from "../../../api/models/AdminOpcoDto";
import { OperationType } from "../AdminOpcoRow/AdminOpcoRow";

export const useAdminOpcoManagersGrid = (
    managers: AdminOpcoManagerDto[],
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    enqueueSnackbar: any,
    opcoId: string) => {

    const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
    const [ addingManager, setAddingManager ] = useState(false);
    const managerForm = useForm({ defaultValues: { adPrinciple: "" }, mode: "onBlur" });
    const [ managerToDelete, setManagerToDelete ] = useState("");
    const [ managersData, setManagersData ] = useState(managers);
    

    const handleSave = async () => {
        setIsLoading(true);
        const adPrinciple = managerForm.getValues("adPrinciple");
        
        handleOperation("create", {adPrinciple: adPrinciple, opcoId: opcoId});

        managersData.push({adPrinciple: adPrinciple, opcoId: opcoId})
        managerForm.reset();
        setAddingManager(false);
        setIsLoading(false);
    };
    const handleCancel = () => {
        managerForm.reset();
        setAddingManager(false);
    };
    const handleOperation = async (operation: OperationType, opcoOwner: AdminOpcoManagerDto): Promise<boolean> => {
        const operationString = operation.substring(0, operation.length - 1);
        try {
            setIsLoading(true);
            await (operation == "remove" ? removeOpcoOwner(opcoOwner) : saveOpcoOwner(opcoOwner));
            enqueueSnackbar(`Manager ${operation}d`, {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                });
    
            setIsLoading(false);
            return true;
        } catch (exception) {
            enqueueSnackbar(`Something went wrong while ${operationString}ing Manager`, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                });
            setIsLoading(false);
            return false;
        }
    }
    const handleDelete = async (adPrinciple: string) => {
        handleOperation("remove", {adPrinciple: adPrinciple, opcoId: opcoId});
        managers = managersData.filter((m) => m.adPrinciple != adPrinciple);
        setManagersData(managers);
        setShowConfirmationModal(false);
    }

    return {
        addingManager, 
        setAddingManager, 
        showConfirmationModal, 
        setShowConfirmationModal, 
        handleCancel, 
        handleDelete, 
        handleSave, 
        managerToDelete, 
        setManagerToDelete, 
        managerForm, 
        managersData
    };
}
