import { Roles } from "../models/roles";
import { User } from "../models/FormModels";
import { EntityState } from "../api/models/EntityState";

export const roleValidator = (value: string[] | any, users: User[]) => {
  if (value !== undefined) {
    const poId = Roles["Product Owner"];
    const po = users.filter(
      (user) =>
        user.roles.some((role) => role.toString() === poId) &&
        user.editStatus != EntityState.Delete
    );
    return po.length > 1 ? "This project already has one Product Owner" : true;
  }
  return false;
};
