import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TopBar from "../../../../Layout/components/TopBar/TopBar";
import { tabs } from "../../api/models/ProjectDetailTabs";
import { ProjectUserRole } from "../../api/models/ProjectUserPermission";
import ProjectTabPanels from "../../components/ProjectTabPanels/ProjectTabPanels";
import RemoveProjectPopup from "../../components/RemoveProjectPopup/RemoveProjectPopup";
import useProjectDetails from "../../hooks/useProjectDetails";
import styles from "./ProjectDetails.module.scss";

const ProjectDetails = () => {
  const { id, tab } = useParams();
  const [isRemoveProjectPopupOpen, setIsRemoveProjectPopupOpen] =
    useState(false);
  const { isLoading, projectDetails, permissions, handleRemove } =
    useProjectDetails(id!);
  const isArchived = projectDetails?.isArchived ?? false;
  const userCanEdit = useMemo(
    () =>
      permissions &&
      (permissions.isAdmin ||
        permissions.projectRoles.some(
          (pr) =>
            pr == ProjectUserRole.ProductOwner ||
            pr == ProjectUserRole.ScrumMaster
        )) && !isArchived,
    [permissions, projectDetails]
  );

  return (
    <div data-testid="projectdetails" style={{ height: "100%" }}>
      <TopBar>
        <div className={styles.details__topBarContainer}>
          {projectDetails && (
            <Typography variant="h4">{projectDetails.projectName}</Typography>
          )}

          {userCanEdit && (
            <>
              <Button
                variant="text"
                onClick={() => setIsRemoveProjectPopupOpen(true)}
                startIcon={<DeleteOutlinedIcon />}
              >
                Remove
              </Button>
              <RemoveProjectPopup
                projectName={projectDetails?.projectName || ""}
                open={isRemoveProjectPopupOpen}
                handleClose={() => setIsRemoveProjectPopupOpen(false)}
                handleSubmit={async () => {
                  setIsRemoveProjectPopupOpen(false);
                  await handleRemove();
                }}
              />
            </>
          )}
        </div>
      </TopBar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {projectDetails && (
        <ProjectTabPanels
          project={projectDetails}
          userCanEdit={userCanEdit || false}
          routeTabIndex={tabs.indexOf(tab || "details")}
        />
      )}
    </div>
  );
};

export default ProjectDetails;
