import { InfoOutlined } from "@mui/icons-material";
import { InputAdornment, Tooltip } from "@mui/material";
import { useCallback, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import HeiAutocomplete from "../../../../Common/components/Form/HeiAutocomplete/HeiAutocomplete";
import HeiRadioGroup from "../../../../Common/components/Form/HeiRadioGroup/HeiRadioGroup";
import HeiTextField from "../../../../Common/components/Form/HeiTextField/HeiTextField";
import { SelectOption } from "../../../../Common/models/form";
import {
  ProjectFormType,
} from "../../../../Common/hooks/useCreateProject";
import styles from "./ProjectTypeSelection.module.scss";
import { validateCostCenter } from "../../api/projectsHttpClient";

type ProjectType = "Cost Center" | "Opco";

const getBillingTypeOptions = (isEdit: boolean) =>
  [
    {
      label: "Charging code",
      value: "CostCenter",
    },
    {
      label: "Opco",
      value: "Opco",
    },
    {
      label: "I will enter this later",
      value: "EnterBillingLater",
      disabled: isEdit,
    },
  ] as SelectOption[];

interface ProjectTypeSelectionProps {
  form: UseFormReturn<ProjectFormType>;
  opcos: SelectOption[];
  isEdit?: boolean;
  canUserEdit?: boolean;
}

const ProjectTypeSelection = ({
  opcos,
  form,
  isEdit = false,
  canUserEdit = true,
}: ProjectTypeSelectionProps): JSX.Element => {
  const { control, watch } = form;

  const opcoIdValue = watch("opcoId");

  const selectedProjectTypeValue = watch("billingType");

  const validateCostCenterFunc = useCallback(
    async (costCenter: string) => await validateCostCenter(costCenter),
    []
  );

  const processLCode = (value: string) => {
    switch (value.length) {
      case 8:
        value = [value.slice(0, 1), value.slice(1, 5), value.slice(5, 8)].join('.');
        break;
      case 10:
        value = [value.slice(0, 1), value.slice(1, 5), value.slice(5, 8), value.slice(8, 10)].join('.');
        break;
      case 12:
        value = [value.slice(0, 1), value.slice(1, 5), value.slice(5, 8), value.slice(8, 10), value.slice(10, 12)].join('.');
        break;
    }
    return value;
  }

  useEffect(() => {
    if (opcoIdValue) {
      const costCenter = opcos.filter((o) => o.value == opcoIdValue)[0].label;
      form.setValue("costCenter", costCenter);
    }
  }, [opcoIdValue]);

  useEffect(() => {
    if (selectedProjectTypeValue !== "Opco") {
      if (
        selectedProjectTypeValue === "EnterBillingLater" ||
        form.getValues("opcoId")
      ) {
        form.setValue("costCenter", "");
      }
      form.setValue("opcoId", "");
      form.setValue("opcoCrossCharginInformation", "");
    }
  }, [selectedProjectTypeValue]);

  return (
    <div className={styles.projectTypeSelection}>
      <HeiRadioGroup
        row
        disabled={!canUserEdit}
        labelClassName={styles.projectTypeSelection__label}
        radioGroupClassName={styles.projectTypeSelection__radioGroup}
        control={control}
        options={getBillingTypeOptions(isEdit)}
        name="billingType"
        label="Select billing type"
        sx={{ width: "200px" }}
        rules={{
          required: "Billing type is required",
        }}
      />

      {selectedProjectTypeValue === "CostCenter" && (
        <div>
          <HeiTextField
            disabled={!canUserEdit}
            control={control}
            name="costCenter"
            label="Charging code"
            placeholder="i.e. 5.0123"
            sx={{ width: "200px" }}
            rules={{
              required: "Charging code is required",
              pattern: {
                value: /^(?:[0-9].[0-9]{4}|L.[0-9]{4}|F.[0-9]{4}|J.[0-9]{6}.[0-9]{2}|L.[0-9]{4}.[0-9]{3}|L.[0-9]{4}.[0-9]{3}.[0-9]{2}|L.[0-9]{4}.[0-9]{3}.[0-9]{2}.[0-9]{2})$/,
                message: "Enter valid charging code",
              },
              validate: {
                costCenterValid: async (value: string) => (await validateCostCenterFunc(value))
                ? undefined
                : `Charging code ${value} is invalid`
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      <span>
                        Accepted formats are: <br/>
                        - {9}.{9999}<br/>
                        - F.{9999}<br/>
                        - J.{999999}.{99}<br/>
                        - L.{9999}<br/>
                        - L.{9999}.{999}<br/>
                        - L.{9999}.{999}.{99}<br/>
                        - L.{9999}.{999}.{99}.{99}<br/><br/>
                        For J and L codes  include the project phase at the end as follows: <br/>
                        L.012345.03 (for Startup phase) <br/>
                        J.012345.06 (for Delivery phase) <br/>
                        L.012345.09 (for Establishing phase)
                      </span>
                    }
                    arrow
                  >
                    <InfoOutlined fontSize="small" color="action" />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onBlur={(e) => {
              let value: string = control._formValues["costCenter"].toUpperCase();
              if (!value.includes("."))
              {
                if (value.length == 5 && (value.startsWith("F") || value.startsWith("L") || !isNaN(+value.charAt(0))))
                  value = [value.slice(0, 1), value.slice(1, 5)].join('.');
                else if (value.startsWith("L")) {
                  value = processLCode(value);
                } else if (value.length == 9 && value.startsWith("J")) {
                  value = [value.slice(0, 1), value.slice(1, 7), value.slice(7, 9)].join('.');
                }
              }
              form.setValue("costCenter", value);
            }}
          />
        </div>
      )}

      {selectedProjectTypeValue === "Opco" && (
        <>
          <HeiAutocomplete
            disabled={!canUserEdit}
            control={control}
            name="opcoId"
            options={opcos}
            label="Opco"
            sx={{ width: "45%", mr: 3 }}
            rules={{
              required: "Opco is required",
              pattern: {
                value: /.*/,
                message: "Enter valid cost center",
              },
            }}
          />

          <HeiTextField
            disabled={!canUserEdit}
            control={control}
            name="opcoCrossCharginInformation"
            label="Opco specific cross-charging information"
            sx={{ width: "45%", mt: 2 }}
          />
        </>
      )}
    </div>
  );
};

export default ProjectTypeSelection;
