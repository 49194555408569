import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import ToolIcon from "../../../../Common/components/ToolIcon/ToolIcon";
import { IconSize } from "../../../../Common/models/iconSize";
import * as React from "react";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import styles from "./ProjectCostGrid.module.scss";
import { costValueFormat } from "../../../../Common/utils/costValueFormat";
export const getProjectCostColumns = (
  licenseModels: string[]
): GridColDef[] => {
  return [
    {
      field: "capabilityToolKey",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <>
            <ToolIcon
              tool={params.row.capabilityToolKey}
              size={IconSize.Medium}
              tooltipTitle={params.row.toolName}
            />
          </>
        );
      },
    },
    {
      field: "toolName",
      headerName: "Tool name",
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <>{`${params.row.toolDisplayName ?? params.row.toolName}`}</>;
      },
    },
    {
      field: "cost",
      headerName: "Cost",
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params: GridRenderCellParams<string>) => {
        const cost = params.row.cost;
        return (
          <>
            {params.row.cost === 0 ? "Free of charge" : costValueFormat(cost)}
          </>
        );
      },
    },

    {
      field: "licenseMode",
      headerName: "Licence model",
      flex: 0.9,
      align: "left",
      disableColumnMenu: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          <strong>{"Licence model"}</strong>
          <Tooltip
            title={
              <>
                Every tool has it’s own license model:
                <ul>
                  {licenseModels.map((licenceModel) => (
                    <li>{licenceModel}</li>
                  ))}
                </ul>
              </>
            }
            arrow
          >
            <InfoOutlined fontSize="small" color={"action"} />
          </Tooltip>
        </>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "totalCost",
      headerAlign: "right",
      headerName: "Total Cost",
      width: 180,
      align: "right",
      disableColumnMenu: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          <strong>Total cost</strong>
        </>
      ),
      renderCell: (params: GridRenderCellParams<string>) => {
        return <>{costValueFormat(params.row.totalCost)}</>;
      },
    },
  ];
};
