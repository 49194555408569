import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import useMultiCollapse from "../../../../Common/hooks/useMultiCollapse";
import { AdminOpcoDto } from "../../api/models/AdminOpcoDto";
import AdminOpcoRow from "./AdminOpcoRow/AdminOpcoRow";

interface AdminOpcosGridProps {
  opcos: AdminOpcoDto[];
  reloadData: () => Promise<void>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const AdminOpcosGrid = ({ opcos, reloadData, setIsLoading }: AdminOpcosGridProps): JSX.Element => {

  const { ToggleCollapseButton, ToggleItemButton, itemsCollapse, reloadItems } =
  useMultiCollapse(() => {
    return opcos.map((_, i) => i);
  });

  const newOpcoData: AdminOpcoDto = {
    id: EmptyGuid,
    name: "",
    infoRequired: false,
    managers: []
  };
  
  const [ addingOpco, setAddingOpco ] = useState(false);

  useEffect(() => {
    reloadItems();
  }, [reloadData]);

  return (
    <div data-testid="admin-opcos-grid">
      <Box pl="80px" pt="24px" height="100%">
        <Box width="92%" height="36.5px">
          <Button
            variant="contained"
            onClick={() => setAddingOpco((adding) => !adding)}
            sx={{float:"right"}}
            size="medium"
          >
            ADD OPCO
          </Button>
        </Box>
        <TableContainer
          component={Paper}
          sx={{ width: "92%", pb: 0, mb: "70px", mt: "10px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "60%" }} >
                    <b>Name</b>
                </TableCell>
                <TableCell sx={{ width: "15%" }} >
                    <b>Add. info req.</b>
                </TableCell>
                <TableCell sx={{ width: "15%" }} ></TableCell>
                <TableCell>
                  <ToggleCollapseButton />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addingOpco && 
                <AdminOpcoRow 
                  opco={newOpcoData} 
                  edited={true} 
                  setAdding={setAddingOpco} 
                  reloadData={reloadData} 
                  key={`${newOpcoData.id}-opco-row`}
                  setIsLoading={setIsLoading} />
                }
              {opcos.map((opco: AdminOpcoDto, i) => {
                const opcoExpanded = itemsCollapse[i];
                return (
                  <AdminOpcoRow 
                    opco={opco} 
                    opcoExpanded={opcoExpanded} 
                    ToggleItemButton={<ToggleItemButton index={i} />} 
                    reloadData={reloadData} 
                    key={`${opco.id}-opco-row`}
                    setIsLoading={setIsLoading} />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default AdminOpcosGrid;
