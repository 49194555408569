import { GridColDef } from "@mui/x-data-grid";

export const getColumns = (): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: "Name",
      sortable: true
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true
    },
    {
      field: "adPrinciple",
      headerName: "AD Principal Name",
      sortable: true
    },
    {
      field: "projects",
      headerName: "Projects",
      sortable: false
    }
  ];
}
