import { useCallback } from "react";
import { useQuery } from "react-query";
import { getFaqArticle, rateArticle, deleteFaqArticle } from "../api/faqArticleHttpClient";
import { useSnackbar } from "notistack";

export const useFaqArticle = (id: string) => {
  const getProjectCostCallback = useCallback(() => getFaqArticle(id), [id]);
  const { enqueueSnackbar } = useSnackbar();

  const { data: faqArticle, isLoading } = useQuery(
    ["faq_article", id],
    getProjectCostCallback
  );

  const submitRating = async (value: boolean) => {
    await rateArticle(id, value);
    enqueueSnackbar("Thank you for rating the article!", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  }
  const submitDeleteArticle = async (id: string) => {
    await deleteFaqArticle(id);
    enqueueSnackbar("FAQ article has been deleted!", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  } 

  return { faqArticle, isLoading, submitRating, submitDeleteArticle };
};
