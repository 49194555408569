import styles from "./AdminBillingView.module.scss";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { useAdminBillings } from "../hooks/useAdminBillings";
import BillingGrid from "../components/BillingsGrid";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import usePageTitle from "../../../Common/hooks/usePageTitle";

const AdminBillingView = (): JSX.Element => {
  const { isLoading, isGenerating, generateBilling, billings } =
    useAdminBillings();
  usePageTitle("Admin | Billing");

  return (
    <div data-testid="adminbilling" style={{ height: "100%" }}>
      <TopBar>
        <div className={styles.details__topBarContainer}>
          <Typography variant="h4">Billing</Typography>
          <Button
            onClick={() => generateBilling()}
            disabled={isGenerating}
            startIcon={<SettingsOutlinedIcon />}
          >
            Generate billing
            {isGenerating && (
              <CircularProgress
                size={24}
                color={"inherit"}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </div>
      </TopBar>
      {isLoading && <Backdrop open={isLoading} />}

      <BillingGrid billings={billings} />
    </div>
  );
};

export default AdminBillingView;
