import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import ToolCard from "../../../../Common/components/ToolCard/ToolCard";
import ToolDetailsFieldsTableRow from "../../../../Common/components/ToolDetailsFieldsTableRow/ToolDetailsFieldsTableRow";
import useMultiCollapse from "../../../../Common/hooks/useMultiCollapse";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import styles from "./ToolDetails.module.scss";
import { selectedToolsAtom } from "../../../../Common/hooks/useToolsForSelect";
interface ToolDetailsProps {
  form: UseFormReturn<ProjectFormType>;
}

const ToolDetails = ({ form }: ToolDetailsProps): JSX.Element => {
  const [selectedTools] = useAtom(selectedToolsAtom);

  const { ToggleCollapseButton, ToggleItemButton, itemsCollapse } =
    useMultiCollapse(() => { return selectedTools.map((_, i) => i) });

  useEffect(() => {
    selectedTools.forEach((st, i) => {
      if (!form.getValues(`tools.${i}.capabilityToolKey`)) {
        form.setValue(`tools.${i}.capabilityToolKey`, st.capabilityToolKey);
        form.setValue(`tools.${i}.toolId`, st.toolId);
        form.setValue(`tools.${i}.capabilityId`, st.capabilityId);
        form.setValue(
          `tools.${i}.inviteMode`,
          st.isImmediateAccess ? "Automatically" : "Manually"
        );
      }
    });
  }, [selectedTools, form]);

  if (selectedTools.length === 0) return <></>;

  return (
    <div>
      <Typography variant="h6" sx={{ pt: 2 }}>
        Tool details
      </Typography>

      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Enter any details for the tools and optionally adjust how you want to
        invite users to this project.
      </Typography>

      <TableContainer component={Paper} sx={{ width: "92%", mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <b>Tool</b>
              </TableCell>
              <TableCell>
                <div className={styles.toolDetails__tableHeader}>
                  <AccessTimeOutlinedIcon />

                  <b>Availablity</b>
                </div>
              </TableCell>
              <TableCell>
                <div className={styles.toolDetails__tableHeader}>
                  <AccountCircleOutlinedIcon />

                  <b>Invite users</b>
                </div>
              </TableCell>
              <TableCell>
                <div className={styles.toolDetails__collapseHeaderCell}>
                  <div className={styles.toolDetails__tableHeader}>
                    <EuroOutlinedIcon />

                    <b>Costs</b>
                  </div>
                  <ToggleCollapseButton />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedTools.map((st, i) => (
              <>
                <TableRow>
                  <TableCell colSpan={2}>
                    <ToolCard
                      capabilityName={st.capabilityName}
                      toolKey={st.capabilityToolKey}
                      toolName={st.toolDisplayName ?? st.toolName}
                      tooltip={st.toolName}
                    />
                  </TableCell>
                  <TableCell>
                    {st.isImmediateAccess ? "Instant" : "In 2 days"}
                  </TableCell>
                  <TableCell>
                    {st.isUserAutoAdd ? "Automatically" : "Manually"}
                  </TableCell>
                  <TableCell sx={{ p: 0, height: "100%" }}>
                    <div className={styles.toolDetails__collapseCell}>
                      <span style={{ padding: "16px" }}>{st.shortCost}</span>
                      <ToggleItemButton index={i} />
                    </div>
                  </TableCell>
                </TableRow>
                <ToolDetailsFieldsTableRow
                  userCanEdit={true}
                  form={form}
                  index={i}
                  toolKey={st.capabilityToolKey}
                  CustomFields={st.CustomFields}
                  toolOpen={itemsCollapse[i]}
                />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ToolDetails;
