import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RequestStatus } from "../models/requestStatus";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const requestStatusIcon: Record<RequestStatus, any> = {
  Approved: <CheckCircleOutlineRoundedIcon />,
  AutoApproved: <CheckCircleOutlineRoundedIcon />,
  Denied: <CloseOutlinedIcon />,
  AddedToProject: <AddCircleOutlineIcon />,
  Pending: undefined,
};
