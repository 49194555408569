import { ProjectAccess, PutProjectAccess } from "./models/ProjectAccess";
import axios from "axios";

export const getProjectAccess = async (
  projectId: string
): Promise<ProjectAccess> => {
  const { data: projectAccess } = await axios.get<ProjectAccess>(
    `${window.configuration.apiUrl}api/Selfonboarding/${projectId}/accessconfig`
  );
  return projectAccess;
};
export const putProjectAccess = async (
  projectId: string,
  data: PutProjectAccess
): Promise<void> => {
  await axios.put(
    `${window.configuration.apiUrl}api/Selfonboarding/${projectId}/accessconfig`,
    data
  );
};
