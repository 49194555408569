import { useState } from "react";
import { AdminUserSearchDto } from "../api/models/adminUserSearchDto";
import { getUsers } from "../api/adminUserSearchClient";

export const useAdminUserSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<AdminUserSearchDto[]>([]);

  const fetchUsers = async (searchValue?: string) => {
    setIsLoading(true);
    try {
      setUsers(await getUsers(searchValue));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchUsers,
    isLoading,
    users,
    setUsers
  };
};
