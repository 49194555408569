import { AddOutlined, CancelOutlined, DeleteForeverOutlined, SaveOutlined } from "@mui/icons-material";
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import ActionConfirmation from "../../../../../Common/components/ActionConfirmation/ActionConfirmation";
import HeiTextField from "../../../../../Common/components/Form/HeiTextField/HeiTextField";
import { AdminOpcoManagerDto } from "../../../api/models/AdminOpcoDto";
import { useAdminOpcoManagersGrid } from "../hooks/useAdminOpcoManagersGrid";

interface AdminOpcosGridProps {
    opcoId: string;
    managers: AdminOpcoManagerDto[];
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    enqueueSnackbar: any;
  }

const AdminOpcoManagersGrid = ({ opcoId, managers, setIsLoading, enqueueSnackbar }: AdminOpcosGridProps): JSX.Element => {
    const {addingManager, setAddingManager, showConfirmationModal, setShowConfirmationModal, handleCancel, handleDelete, 
        handleSave, managerToDelete, setManagerToDelete, managerForm, managersData} = 
            useAdminOpcoManagersGrid(managers, setIsLoading, enqueueSnackbar, opcoId);

    return <>
        <Button disabled={addingManager} size="small" onClick={() => setAddingManager(true)}>
            <AddOutlined/>
        </Button>
        <ActionConfirmation
            title={"Do you want to remove this manager?"}
            description=""
            when={showConfirmationModal}
            confirm={() => handleDelete(managerToDelete)}
            cancel={() => setShowConfirmationModal(false)}
        />
        <TableContainer style={{padding: "10px"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>AD Principle</b>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }} ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {addingManager && <TableRow key={`${opcoId}-new-manager`}>
                        <TableCell>
                            <HeiTextField sx={{width: "75%"}} 
                                control={managerForm.control} 
                                name="adPrinciple" 
                                label={"Ad Principle"}
                                rules={{
                                    required:
                                    "Ad Principle required",
                                    pattern: {
                                    value: /^([\w.-]+)@([\w-]+)((.(\w){2,3})+)$/,
                                    message: "Enter valid email",
                                    },
                                }} />
                        </TableCell>
                        <TableCell>
                            <IconButton onClick={() => {handleSave()}}><SaveOutlined/></IconButton>
                            <IconButton onClick={() => {handleCancel()}}><CancelOutlined/></IconButton>
                        </TableCell>
                    </TableRow>
                    }
                    {managersData?.map((manager: AdminOpcoManagerDto) => {
                        return (
                            <TableRow key={`${opcoId}-manager-${manager.adPrinciple}`}>
                                <TableCell>
                                    {manager.adPrinciple}
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => {setManagerToDelete(manager.adPrinciple); setShowConfirmationModal(true);}}><DeleteForeverOutlined/></IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export default AdminOpcoManagersGrid;
