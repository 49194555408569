import ToolIcon from "../../../../Common/components/ToolIcon/ToolIcon";
import { CapabilityTool } from "../../../../Common/models/tools";
import styles from "./GridTools.module.scss";
import { IconSize } from "../../../../Common/models/iconSize";
import { Tooltip } from "@mui/material";
import { toolNameMap } from "../../../../Common/utils/toolUtil";
import { RemainingToolsList } from "../../../../Common/components/RemainingToolsList/RemainingToolsList";

interface GridToolsProps {
  tools: CapabilityTool[];
  displayCount?: number;
}

const GridTools = ({
  tools,
  displayCount = 4,
}: GridToolsProps): JSX.Element => {
  const toolsCount = tools.length;
  const additionalToolsCount = toolsCount - displayCount;
  const additionalTools = tools.slice(displayCount, tools.length);
  if (toolsCount > displayCount + 1) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {tools.slice(0, displayCount).map((t, i) => (
          <Tooltip title={toolNameMap[t]} key={i} arrow placement="top">
            <div style={{ marginLeft: -6 }}>
              <ToolIcon key={i} tool={t} size={IconSize.Medium} />
            </div>
          </Tooltip>
        ))}

        <Tooltip
          title={
            <RemainingToolsList
              title={"Other tools"}
              remainingTools={additionalTools}
            />
          }
          key={additionalToolsCount}
          arrow
          placement="top"
        >
          <div className={styles.additionalTools} style={{ marginLeft: -6 }}>
            +{additionalToolsCount}
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {tools.map((t, i) => (
        <Tooltip title={toolNameMap[t]} key={i} arrow placement="top">
          <div style={{ marginLeft: -6 }}>
            <ToolIcon tool={t} size={IconSize.Medium} />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default GridTools;
