import axios from "axios";
import { SelectOption } from "../models/form";

export const getOptions = async (
  endpoint: string,
  input: string
): Promise<SelectOption[]> => {
  const { data: options } = await axios.get<SelectOption[]>(
    `${window.configuration.apiUrl}${endpoint}`,
    { params: { input } }
  );

  return options;
};
