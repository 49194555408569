import { useState } from "react";
import { getOpcos } from "../api/adminOpcoClient";
import { AdminOpcoDto } from "../api/models/AdminOpcoDto";

export const useAdminOpcos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [opcos, setOpcos] = useState<AdminOpcoDto[]>([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
        setOpcos(await getOpcos());
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchData,
    isLoading,
    setIsLoading,
    opcos
  };
};
