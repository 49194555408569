import { Backdrop, Box, CircularProgress } from "@mui/material";
import ProjectUsersForm from "../../../components/ProjectUsersForm/ProjectUsersForm";
import useProjectTools from "../../../hooks/useProjectTools";
import useProjectAccess from "../../../hooks/useProjectAccess";
import { useProjectUsersTable } from "../../../hooks/useProjectUsersTable";
import usePageTitle from "../../../../../Common/hooks/usePageTitle";

interface ProjectUsersTabProps {
  userCanEdit: boolean;
  id: string;
}

const ProjectUsersTab = ({ id, userCanEdit }: ProjectUsersTabProps) => {
  usePageTitle("Project | Users");
  const { projectTools, isLoading: isProjectToolsLoading } =
    useProjectTools(id);
  const { projectAccess, isLoading: isProjectAccessLoading } =
    useProjectAccess(id);
  const { fetchGridData } = useProjectUsersTable();
  return (
    <Box sx={{ pl: "80px", pt: "24px", height: "100%" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProjectToolsLoading || isProjectAccessLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {projectTools && projectAccess && (
        <ProjectUsersForm
          projectId={id}
          userCanEdit={userCanEdit}
          projectTools={projectTools}
          projectAccess={projectAccess}
          fetchGridData={fetchGridData}
        />
      )}
    </Box>
  );
};

export default ProjectUsersTab;
