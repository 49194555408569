import { Typography } from "@mui/material";
import { IconSize } from "../../models/iconSize";
import { CapabilityTool } from "../../models/tools";
import ToolIcon from "../ToolIcon/ToolIcon";
import styles from "./ToolCardSimple.module.scss";

export interface ToolCardSimpleProps {
  toolKey: CapabilityTool;
  toolName: string;
  toolDisplayName: string;
}

const ToolCardSimple = ({
  toolKey,
  toolName,
  toolDisplayName
}: ToolCardSimpleProps): JSX.Element => {
  return (
    <div className={styles.toolDetails__tool}>
      <ToolIcon tool={toolKey} size={IconSize.Medium} tooltipTitle={toolName} />

      <div className={styles.toolDetails__info}>
        <Typography variant="body2" className={styles.toolDetails__toolName}>
          {toolDisplayName ?? toolName}
        </Typography>
      </div>
    </div>
  );
};

export default ToolCardSimple;
