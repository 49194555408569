import axios from "axios";
import { ProjectTool } from "../../../Common/api/models/ProjectTool";
import { ProjectFormType } from "../../../Common/hooks/useCreateProject";
import { FetchDataRequest, FetchDataResult } from "../../../Common/models/grid";
import { SieveResponse } from "../../../Common/models/sieve";
import {
  getSieveRequestModel,
  mapToFetchDataResult,
} from "../../../Common/utils/sieveRequestModel";
import { ProjectCostResponse } from "../../ProjectsList/api/models/ProjectToolCost";
import { ActionRequest, PackageAction } from "./models/PackageActions";
import { ProjectDetails } from "./models/ProjectDetails";
import { UserProjectPermissions } from "./models/ProjectUserPermission";
export const getProjectDetails = async (
  projectId: string
): Promise<ProjectDetails> => {
  const { data: project } = await axios.get<ProjectDetails>(
    `${window.configuration.apiUrl}api/Projects/${projectId}`
  );

  return project;
};

export const getUserProjectRoles = async (
  projectId: string
): Promise<UserProjectPermissions> => {
  const { data: permissions } = await axios.get<UserProjectPermissions>(
    `${window.configuration.apiUrl}api/Projects/${projectId}/userRoles`
  );

  return permissions;
};

export const updateProjectDetails = async (
  projectId: string,
  project: ProjectFormType
): Promise<void> => {
  await axios.patch(
    `${window.configuration.apiUrl}api/Projects/${projectId}`,
    project
  );
};

export const getProjectTools = async (
  projectId: string
): Promise<ProjectTool[]> => {
  const { data: projectTools } = await axios.get<ProjectTool[]>(
    `${window.configuration.apiUrl}api/Projects/${projectId}/tools`
  );

  return projectTools;
};

export const putProjectTools = async (
  projectId: string,
  data: any
): Promise<void> => {
  await axios.put(
    `${window.configuration.apiUrl}api/Projects/${projectId}/tools`,
    data
  );
};
export const getProjectUsersTable = async (
  projectId: string,
  request: FetchDataRequest
): Promise<FetchDataResult> => {
  const sieveRequest = getSieveRequestModel(request);
  const { data: users } = await axios.get<SieveResponse>(
    `${window.configuration.apiUrl}api/Projects/${projectId}/members`,
    { params: sieveRequest }
  );

  return mapToFetchDataResult(users);
};

export const finalizeProjectsToolCreation = async (
  projectId: string,
  toolId: string,
  capabilityId: string,
  toolProjectId: string,
  toolProjectDeeplink: string
): Promise<void> => {
  await axios.patch(
    `${window.configuration.apiUrl}api/Projects/${projectId}/tools/finalize`,
    { toolId, capabilityId, toolProjectId, toolProjectDeeplink }
  );
};

export const removeProject = async (projectId: string): Promise<void> => {
  await axios.put(
    `${window.configuration.apiUrl}api/Projects/${projectId}/archive`
  );
};

export const putProjectUsers = async (
  projectId: string,
  data: any
): Promise<void> => {
  data.ProjectId = projectId;
  await axios.put(
    `${window.configuration.apiUrl}api/Projects/${projectId}/members`,
    data
  );
};

export const getProjectCost = async (
  projectId: string
): Promise<ProjectCostResponse> => {
  const { data } = await axios.get<ProjectCostResponse>(
    `${window.configuration.apiUrl}api/Projects/${projectId}/costs`
  );
  return data;
};

export const getProjectHistory = (
  projectId: string
): ((request: FetchDataRequest) => Promise<FetchDataResult>) => {
  return async (request: FetchDataRequest) => {
    const sieveRequest = getSieveRequestModel(request);

    const { data } = await axios.get<SieveResponse>(
      `${window.configuration.apiUrl}api/Projects/${projectId}/history`,
      { params: sieveRequest }
    );

    const response = mapToFetchDataResult(data);
    return response;
  };
};

export const getPackageActions = async (
  packageId: string
): Promise<PackageAction[]> => {
  const { data: packageActions } = await axios.get<PackageAction[]>(
    `${window.configuration.apiUrl}api/Projects/packages/${packageId}/actions`
  );
  return packageActions;
};

export const postPackageAction = async (
  projectId: string,
  actionRequest: ActionRequest
): Promise<void> => {
  await axios.post(
    `${window.configuration.apiUrl}api/Projects/${projectId}/invokePackageAction`,
    actionRequest
  );
};
