import * as React from "react";
import { UserSearch } from "../../models/UserSearch";
import { autocompleteClasses } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TextField from "@mui/material/TextField";
import { useSearchUser } from "../../../Projects/CreateProject/hooks/useSearchUser";
import { User } from "../../models/FormModels";
import { UseFieldArrayAppend, UseFormGetValues } from "react-hook-form";
import { ProjectFormType } from "../../hooks/useCreateProject";
import { NoOptionItem } from "./NoOptionItem";
import { UserOptionItem } from "./UserOptionItem";
import debounce from "lodash/debounce";

interface HeiUserAutocompleteProps {
  autoComplete: React.RefObject<HTMLInputElement>;
  addUser: UseFieldArrayAppend<ProjectFormType, "users">;
  getUsers: UseFormGetValues<ProjectFormType>;
  handleChange: (selectedUser: UserSearch) => void;
  handleCreateNewAccount: () => void;
  width?: string;
  customFilterParams?: string;
}

const HeiUserAutocomplete = ({
  autoComplete,
  getUsers,
  handleChange,
  handleCreateNewAccount,
  width,
  customFilterParams,
}: HeiUserAutocompleteProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly UserSearch[]>([]);
  const [inputFilled, setInputFilled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { getUsersFromAd } = useSearchUser(customFilterParams ?? "");

  const filterOptions = createFilterOptions({
    stringify: (option: UserSearch) =>
      `${option.name}${option.email}${option.adPrinciple}`,
  });

  const setAllSearchStates = (value: boolean) => {
    setInputFilled(value);
    setOpen(value);
    setIsLoading(value);
  };

  const getOptionLabel = (userInput: UserSearch) => {
    return `${userInput.name} (${userInput.adPrinciple}${
      userInput.email === null ? "" : "/" + userInput.email
    })`;
  };

  const handleInputChange = debounce((value: string) => {
    const minCharTyped = 3;
    if (value.length < minCharTyped) {
      setAllSearchStates(false);
      setOptions([]);
    } else {
      setAllSearchStates(true);

      (async () => {
        const usersAdded = getUsers("users") as User[];
        const users = await getUsersFromAd(value.trim());
        users.forEach((user) => {
          const foundUser = usersAdded.find(
            (addedUser) =>
              addedUser.adPrinciple.trim().toLowerCase() ===
                user.adPrinciple.toLowerCase() ||
              addedUser.email.trim().toLowerCase() === user.email?.toLowerCase()
          );

          user.isInProject = user.isInProject || !!foundUser;
        });
        setOptions(users);
        setIsLoading(false);
      })();
    }
  }, 500);

  return (
    <Autocomplete
      id={"searchUser"}
      ref={autoComplete}
      filterOptions={filterOptions}
      size={"small"}
      sx={{
        width: width ?? "45%",
        marginRight: "8px",
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: "none",
        },
      }}
      open={open && inputFilled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_event, value) => (value ? handleChange(value) : null)}
      onInputChange={(_event, value) => {
        handleInputChange(value);
      }}
      popupIcon={<SearchOutlinedIcon />}
      getOptionLabel={(option) => getOptionLabel(option)}
      noOptionsText={
        <NoOptionItem
          onClick={() => {
            handleCreateNewAccount();
          }}
        />
      }
      renderOption={(props, option) => (
        <UserOptionItem
          key={option.adPrinciple}
          {...props}
          props={props}
          option={option}
        />
      )}
      options={options}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          placeholder={"Name, heiway account, email"}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
export default HeiUserAutocomplete;
