import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ProjectFormType } from "../../../Common/hooks/useCreateProject";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { ProjectAccess } from "../api/models/ProjectAccess";
import {
  getProjectAccess,
  putProjectAccess,
} from "../api/selfOnboardingHttpClient";

const useProjectAccessEdit = ({
  teamMembersManualApproval,
  stakeholdersManualApproval,
}: ProjectAccess) => {
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const defaultValues = {
    teamMembersManualApproval: teamMembersManualApproval,
    stakeholdersManualApproval: stakeholdersManualApproval,
  } as ProjectFormType;

  const form = useForm<ProjectFormType>({
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const handleProjectAccessSave = async () => {
    setIsSaving(true);

    try {
      await putProjectAccess(id!, form.getValues());
      queryClient.invalidateQueries(["project_access", id!]);

      const newProjectAccess = await getProjectAccess(id!);
      form.reset(newProjectAccess);

      enqueueSnackbar("Settings saved!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch {
      enqueueSnackbar("Settings have not been updated!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      console.error("Something went wrong");
    } finally {
      setIsSaving(false);
    }
  };

  return {
    form,
    handleProjectAccessSave,
    isSaving,
  };
};

export default useProjectAccessEdit;
