import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ToolTile from "../../components/ToolTile/ToolTile";
import { ToolModel } from "../../models/ToolTile";

interface ToolSectionProps {
  title: string;
  description: string;
  tools: ToolModel[];
  selectedTools?: ToolModel[];
  toggleToolSelect: (tool: ToolModel) => void;
}

const ToolSection = ({
  tools,
  title,
  description,
  toggleToolSelect,
  selectedTools,
}: ToolSectionProps): JSX.Element => {
  if (tools.length === 0) {
    return <></>;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={"h6"}>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body2"} sx={{ marginBottom: 2 }}>
          {description}
        </Typography>
      </Grid>
      {tools.map((tool, index) => {
        return (
          <div key={index}>
            <ToolTile
              buttonLabel={"SELECT"}
              alternateButtonLabel={"DESELECT"}
              tool={tool}
              toggleToolSelect={toggleToolSelect}
              selected={
                selectedTools !== undefined &&
                selectedTools.filter(
                  (t) => t.capabilityToolKey == tool.capabilityToolKey
                ).length > 0
              }
            />
          </div>
        );
      })}
    </Grid>
  );
};

export default ToolSection;
