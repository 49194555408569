import { useCallback } from "react";
import { getUsers } from "../api/usersHttpClient";

export const useSearchUser = (customFilterParams?: string) => {
  const getUsersFromAd = useCallback(
    async (filter: string) => getUsers(filter, customFilterParams),
    []
  );
  return {
    getUsersFromAd,
  };
};
