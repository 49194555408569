import axios from 'axios';

import { ToolModel } from '../models/ToolTile';

export const getTools = async (packageId: string): Promise<ToolModel[]> => {
  const { data } = await axios.get<ToolModel[]>(
    `${window.configuration.apiUrl}api/Projects/packages/${packageId}/tools`
  );

  return data;
};
