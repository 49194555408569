import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import routes from "../../../../Common/consts/routes";
import PendingRequestsGrid from "../PendingRequestsGrid/PendingRequestsGrid";
import ProcessedRequestsGrid from "../ProcessedRequestsGrid/ProcessedRequestsGrid";
import { TabPanelsProps } from "../../../../Common/models/tabPanels";
import { TabPanel } from "../../../../Common/components/TabPanel/TabPanel";

const RequestsTabPanels = ({ routeTabIndex }: TabPanelsProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          pl: "100px",
          background: "white",
        }}
      >
        <Tabs value={routeTabIndex}>
          <Link
            to={generatePath(routes.requests, {
              tab: "pending",
            })}
          >
            <Tab label={"PENDING"} />
          </Link>

          <Link
            to={generatePath(routes.requests, {
              tab: "processed",
            })}
          >
            <Tab label={"PROCESSED"} />
          </Link>
        </Tabs>
      </Box>
      <TabPanel value={routeTabIndex} index={0}>
        <PendingRequestsGrid />
      </TabPanel>
      <TabPanel value={routeTabIndex} index={1}>
        <ProcessedRequestsGrid />
      </TabPanel>
    </Box>
  );
};

export default RequestsTabPanels;
