import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { SelectOption } from "../../../models/form";

interface HeiAutocompleteProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  label: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  options: SelectOption[];
  disabled?: boolean;
}

const HeiAutocomplete = <TForm extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  sx,
  options,
  rules,
  disabled,
}: HeiAutocompleteProps<TForm>): JSX.Element => {
  const required = rules?.required;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ fieldState, field: { ref, onChange, ...field } }) => {
        const error = fieldState.error;
        const errorMessage = error?.message;

        const [autoCompleteValue, setAutoCompleteValue] = useState(
          options.filter((o) => o.value == field.value)[0]
        );

        useEffect(() => {
          if (field.value) {
            const option = options.filter((o) => o.value == field.value)[0];
            setAutoCompleteValue(option);
          }
        }, [field.value]);

        return (
          <Autocomplete
            disabled={disabled}
            // defaultValue={options.filter((o) => o.value == field.value)[0]}
            onChange={(_, data) => onChange(data?.value)}
            options={options}
            value={autoCompleteValue}
            renderInput={(params) => (
              <TextField
                {...params}
                {...field}
                fullWidth
                inputRef={ref}
                required={!!required}
                InputLabelProps={{ shrink: true }}
                sx={sx || { width: "45%" }}
                label={label}
                variant="outlined"
                placeholder={placeholder}
                error={error !== undefined}
                helperText={errorMessage}
              />
            )}
          />
        );
      }}
    />
  );
};

export default HeiAutocomplete;
