import { ToolCardSimpleProps } from "../components/ToolCardSimple/ToolCardSimple";

export interface FaqToolOption extends ToolCardSimpleProps {
  value?: string;
}

export const faqToolOptions: FaqToolOption[] = [
  {
    toolKey: "ARTIFACT:VSTS",
    toolName: "Azure Artifacts",
  },
  {
    toolKey: "UX:FIGMA",
    toolName: "Figma",
  },
  {
    toolKey: "TESTMAN:VSTS",
    toolName: "Azure Test Plans",
  },
  {
    toolKey: "BACKLOG:JIRA",
    toolName: "Jira",
  },
  {
    toolKey: "CI:VSTS",
    toolName: "Azure Pipelines",
  },
  {
    toolKey: "MONITORING:NEWRELIC",
    toolName: "New Relic",
  },
  {
    toolKey: "REPO:VSTS",
    toolName: "Azure Repos",
  },
  {
    toolKey: "CS:SONARQUBE",
    toolName: "SonarQube",
  },
  {
    toolKey: "BROWAPPAUT:BROWSER",
    toolName: "App Automate",
  },
  {
    toolKey: "BROWAPPLIV:BROWSER",
    toolName: "App Live",
  },
  {
    toolKey: "BROWAUT:BROWSER",
    toolName: "Automate",
  },
  {
    toolKey: "BROWLIV:BROWSER",
    toolName: "Live",
  },
  {
    toolKey: "MONITORING:SPLUNK",
    toolName: "Splunk",
  },
  {
    toolKey: "WIKI:CONFLUENCE",
    toolName: "Confluence",
  },
  {
    toolKey: "COPILOT:GITHUBCOPILOT",
    toolName: "Github Copilot",
  },
  {
    toolKey: "IAC:TERRAFORM",
    toolName: "Terraform",
  },
] as FaqToolOption[];
