import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCallback, useRef, useState } from "react";
import { ProjectTool } from "../../../../Common/api/models/ProjectTool";
import ActionConfirmation from "../../../../Common/components/ActionConfirmation/ActionConfirmation";
import NavigationPrompt from "../../../../Common/components/NavigationPrompt/NavigationPrompt";
import { ToolCapability, User } from "../../../../Common/models/FormModels";
import {
  FetchDataRequest,
  FetchDataResult,
} from "../../../../Common/models/grid";
import { ProjectAccess } from "../../api/models/ProjectAccess";
import { TableSortFilterForPagination } from "../../api/models/Table";
import useProjectUsersForm from "../../hooks/useProjectUsersForm";
import AddProjectUsersPopup from "../AddProjectUsersPopup/AddProjectUsersPopup";
import EditProjectAccessPopup from "../EditProjectAccessPopup/EditProjectAccessPopup";
import styles from "../ProjectToolsTable/ProjectToolsTable.module.scss";
import ProjectUsersTable from "../ProjectUsersTable/ProjectUsersTable";
import { debounce } from "lodash";
import SelfonboardingAlert from "../../../../Common/components/SelfonboardingAlert/SelfonboardingAlert";

interface ProjectUsersFormProps {
  projectId: string;
  userCanEdit: boolean;
  projectTools: ProjectTool[];
  projectAccess: ProjectAccess;
  fetchGridData: (
    projectId: string,
    request: FetchDataRequest
  ) => Promise<FetchDataResult>;
}
const ProjectUsersForm = ({
  projectId,
  userCanEdit,
  projectTools,
  projectAccess,
  fetchGridData,
}: ProjectUsersFormProps) => {
  const tablePaginationDef: TableSortFilterForPagination = {
    order: "asc",
    orderBy: "name",
    page: 1,
    rowsPerPage: 10,
    search: "",
  };
  const [tableSortFilterForPagination, setTableSortFilterForPagination] =
    useState<TableSortFilterForPagination>(tablePaginationDef);
  const [totalRows, setTotalRows] = useState(0);
  const { form, append, handleDelete, handleProjectUsersSubmit, isSaving } =
    useProjectUsersForm(
      [],
      tableSortFilterForPagination,
      setTotalRows,
      projectTools
    );
  const {
    getValues,
    formState: { isDirty },
  } = form;

  const [searchValue, setSearchValue] = useState("");
  const searchInputRef = useRef();

  const submitSearch = () => {
    const searchValue = searchInputRef.current!["value"];
    setSearchValue(searchValue);
    setShowConfirmationModal(false);
  };

  const performSearch = () => {
    setTableSortFilterForPagination({
      ...tableSortFilterForPagination,
      page: 1,
    });
    handleShowActionConfirmation(() => submitSearch());
  };

  const debouncedHandleSearchKeyDown = useCallback(
    debounce(() => {
      performSearch();
    }, 500),
    []
  );

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof User
  ) => {
    const handleSortChange = () => {
      const isAsc =
        tableSortFilterForPagination.orderBy === property &&
        tableSortFilterForPagination.order === "asc";

      setTableSortFilterForPagination({
        ...tableSortFilterForPagination,
        page: 1,
        orderBy: property,
        order: isAsc ? "desc" : "asc",
      });
      setShowConfirmationModal(false);
    };

    handleShowActionConfirmation(() => handleSortChange());
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    const changePageChange = () => {
      setTableSortFilterForPagination({
        ...tableSortFilterForPagination,
        page: newPage + 1,
      });
      setShowConfirmationModal(false);
    };

    handleShowActionConfirmation(() => changePageChange());
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const handleRowsChange = () => {
      {
        setTableSortFilterForPagination({
          ...tableSortFilterForPagination,
          page: 1,
          rowsPerPage: parseInt(event.target.value, 10),
        });
        setShowConfirmationModal(false);
      }
    };

    handleShowActionConfirmation(() => handleRowsChange());
  };
  const handleShowActionConfirmation = (funcToHandle: any) => {
    if (isDirty) {
      setTableModalFunc(() => funcToHandle);
      setShowConfirmationModal(true);
    } else {
      funcToHandle();
    }
  };
  const addUserTemplate: ToolCapability[] = projectTools
    ? projectTools?.map((pt) => {
        const toolCreated = !!pt.toolProjectId || pt.isManual;

        const toolCap: ToolCapability = {
          availableRoles: pt.roles ?? [],
          capabilityToolKey: pt.capabilityToolKey,
          cost: "",
          hasAccess: toolCreated ? pt.optOut : false,
          status: "",
          toolCapRoleId: "",
          toolName: pt.toolName,
          toolDisplayName: pt.toolDisplayName,
          toolId: pt.toolId,
          toolProjectId: pt.toolProjectId,
          capabilityId: pt.capabilityId,
          toolCustomRole: "Basic",
          toolCreationCompleted: toolCreated,
        };
        return toolCap;
      })
    : [];

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isProjectAccessModalOpen, setProjectAccessModalIsOpen] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [tableModalFunc, setTableModalFunc] = useState<() => void>(
    () => handleChangePage
  );

  return (
    <>
      <NavigationPrompt
        when={isDirty}
        title="Do you want to leave this page?"
        description="You have unsaved changes that will be lost if you decide to continue."
      />

      <ActionConfirmation
        title={"Do you want to change page or filters?"}
        description={
          "You have unsaved changes that will be lost if you decide to continue."
        }
        when={isDirty && showConfirmationModal}
        confirm={tableModalFunc}
        cancel={() => setShowConfirmationModal(false)}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSaving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {!projectAccess.projectIsHidden && (
        <div style={{ width: "92%" }}>
          <SelfonboardingAlert />
        </div>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={"92%"}
      >
        <Typography variant={"h6"}>Users ({totalRows})</Typography>
        {userCanEdit && (
          <div style={{ textAlign: "right" }}>
            {!projectAccess.projectIsHidden && (
              <Button
                variant="outlined"
                onClick={() => setProjectAccessModalIsOpen((open) => !open)}
                size="medium"
                sx={{ marginRight: "8px" }}
              >
                EDIT PROJECT ACCESS
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => setIsAddUserModalOpen((open) => !open)}
              size="medium"
            >
              ADD USERS
            </Button>
          </div>
        )}
      </Box>

      <AddProjectUsersPopup
        projectId={projectId}
        open={isAddUserModalOpen}
        handleClose={() => setIsAddUserModalOpen(false)}
        handleAddUser={append}
        getValues={getValues}
        toolCaps={addUserTemplate}
      />
      <EditProjectAccessPopup
        open={isProjectAccessModalOpen}
        handleClose={() => setProjectAccessModalIsOpen(false)}
        projectAccess={projectAccess}
      />
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          mt: 2,
          mb: 3,
          ml: -1,
        }}
      >
        <IconButton
          type="submit"
          sx={{ p: "5px", marginTop: "14px" }}
          aria-label="search"
          onClick={performSearch}
        >
          <SearchRoundedIcon />
        </IconButton>
        <TextField
          sx={{
            width: 450,
            mr: 1,
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.38)",
            },
            "& .MuiInputBase-root.MuiInput-root.MuiInput-underline:before": {
              borderBottom: "1px solid rgba(0, 0, 0, 0.38)",
            },
          }}
          type="search"
          variant="standard"
          label={"Search name, Heiway account, email"}
          onKeyDown={debouncedHandleSearchKeyDown}
          inputRef={searchInputRef}
        />
      </Stack>
      <form>
        <ProjectUsersTable
          projectId={projectId}
          form={form}
          userCanEdit={userCanEdit}
          handleDelete={handleDelete}
          searchValue={searchValue}
          fetchTableData={fetchGridData}
          tableSortFilterForPagination={tableSortFilterForPagination}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalRows={totalRows}
          setTotalRows={setTotalRows}
          projectTools={projectTools}
        />
        {isDirty && userCanEdit && (
          <div className={styles.toolDetails__footer}>
            <Button
              variant="contained"
              onClick={() => handleProjectUsersSubmit()}
              size="large"
              sx={{ mr: 3 }}
            >
              Save
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
export default ProjectUsersForm;
