import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { UseFieldArrayAppend, UseFormGetValues } from "react-hook-form";
import { EntityState } from "../../../../Common/api/models/EntityState";
import SearchAndAddUserSection from "../../../../Common/components/HeiUserSearch/SearchAndAddUserSection";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import { ToolCapability, User } from "../../../../Common/models/FormModels";
import styles from "./AddProjectUsersPopup.module.scss";

interface AddProjectUsersPopupProps {
  projectId: string;
  open: boolean;
  handleClose: () => void;
  handleAddUser: UseFieldArrayAppend<ProjectFormType, "users">;
  getValues: UseFormGetValues<ProjectFormType>;
  toolCaps: ToolCapability[];
}

const userDefValues: User = {
  adPrinciple: "",
  email: "",
  name: "",
  roles: [],
  toolCaps: [],
  editStatus: EntityState.Add,
};

const formDefaultValues: ProjectFormType = {
  users: [userDefValues],
} as ProjectFormType;
const AddProjectUsersPopup = ({
  projectId,
  open,
  handleClose,
  handleAddUser,
  getValues,
  toolCaps,
}: AddProjectUsersPopupProps): JSX.Element => {
  userDefValues.toolCaps = toolCaps;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "900px",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle>Add user(s)</DialogTitle>
      <DialogContent className={styles.dialog__content}>
        <div style={{ marginBottom: "16px" }}>
          Find users to add to project.
        </div>
        <SearchAndAddUserSection
          addUser={handleAddUser}
          getUsers={getValues}
          searchWidth={"65%"}
          customFilterParams={`&ProjectId=${projectId}`}
          formDefaultValues={formDefaultValues}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectUsersPopup;
