import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ActionConfirmationProps {
  when: boolean;
  title: string;
  description: string;
  confirm: () => void;
  cancel: () => void;
}

const ActionConfirmation = ({
  when,
  title,
  description,
  confirm,
  cancel,
}: ActionConfirmationProps): JSX.Element => {
  return (
    <Dialog
      sx={{ "& .MuiPaper-root": { width: "440px" } }}
      open={when}
      onClose={() => cancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => cancel()}>Cancel</Button>
        <Button variant="contained" onClick={() => confirm()}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionConfirmation;
