import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import HeiDynamicField from "../../../../Common/components/Form/HeiDynamicField/HeiDynamicField";
import { DynamicField } from "../../../../Common/models/form";

interface InvokeAddProcessFormProps {
  open: boolean;
  handleClose: () => void;
  form: UseFormReturn<ProjectFormType>;
  handleSave?: () => void;
  title: string;
  contentText?: string;
  actionButtonText: string;
  dynamicFields: DynamicField<ProjectFormType>[];
}
const PackageActionForm = ({
  open,
  handleClose,
  form,
  handleSave,
  title,
  contentText,
  actionButtonText,
  dynamicFields,
}: InvokeAddProcessFormProps): JSX.Element => {
  const { control } = form;
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant={"body2"} sx={{ marginBottom: "16px" }}>
          {contentText}
        </Typography>

        {dynamicFields.map((df) => (
          <HeiDynamicField control={control} field={df} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          CLOSE
        </Button>
        {handleSave && (
          <Button
            disabled={!form.formState.isDirty}
            variant="contained"
            onClick={handleSave}
            autoFocus
          >
            {actionButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PackageActionForm;
