import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  SxProps,
  Theme,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { SelectOption } from "../../../models/form";

interface HeiRadioGroupProps<TForm extends FieldValues> {
  control: Control<TForm>;
  name: Path<TForm>;
  options: SelectOption[];
  sx?: SxProps<Theme>;
  label?: string;
  rules?: Omit<
    RegisterOptions<TForm>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  labelClassName?: string;
  disabled?: boolean;
}

const HeiGroupButton = <TForm extends FieldValues>({
  control,
  name,
  options,
  rules,
  label,
  labelClassName,
  disabled,
}: HeiRadioGroupProps<TForm>): JSX.Element => {
  const required = rules?.required;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControl>
            {label && (
              <FormLabel className={labelClassName}>
                {label} {required && <span>*</span>}
              </FormLabel>
            )}
            <ButtonGroup
              disabled={disabled}
              sx={{
                background: "#E0E0E0",
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
              }}
            >
              {options.map((o) => (
                <Button
                  onClick={() => field.onChange(o.value)}
                  variant={o.value == field.value ? "contained" : "outlined"}
                  sx={{
                    color:
                      o.value == field.value ? "white" : "rgba(0, 0, 0, 0.54)",
                    border: "transparent",
                    "&:hover": {
                      border: "transparent",
                    },
                  }}
                >
                  {o.label}
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        );
      }}
    />
  );
};

export default HeiGroupButton;
