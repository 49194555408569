import styles from "./AdminUserSearchView.module.scss";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import usePageTitle from "../../../Common/hooks/usePageTitle";
import { useAdminUserSearch } from "../hooks/useAdminUserSearch";
import AdminUsersGrid from "../components/AdminUsersGrid";
import { useEffect, useState } from "react";

const AdminUserSearchView = (): JSX.Element => {
  const { fetchUsers, isLoading, users, setUsers } =
    useAdminUserSearch();
  usePageTitle("Admin | User search");
  
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchUsers(searchValue)
  }, [searchValue]);

  return (
    <div data-testid="adminusersearch" style={{ height: "100%" }}>
      <TopBar>
        <div className={styles.details__topBarContainer}>
          <Typography variant="h4">User search</Typography>
        </div>
      </TopBar>
      
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      { <AdminUsersGrid users={users} setUsers={setUsers} setSearchValue={setSearchValue} /> }
    </div>
  );
};

export default AdminUserSearchView;
