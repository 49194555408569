import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ReactRouterPrompt from "react-router-prompt";

interface NavigationPromptProps {
  when: boolean;
  title: string;
  description: string;
}

const NavigationPrompt = ({
  when,
  title,
  description,
}: NavigationPromptProps): JSX.Element => {
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) => {
        return (
          <Dialog
            sx={{ "& .MuiPaper-root": { width: "440px" } }}
            open={isActive}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {description}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel}>Stay on page</Button>
              <Button
                color="error"
                variant="contained"
                onClick={onConfirm}
                autoFocus
              >
                Leave
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </ReactRouterPrompt>
  );
};

export default NavigationPrompt;
