import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getFaqs } from "../api/faqHttpClient";

const useFaqList = () => {
  const [hasFilter, setHasFilter] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [tools, setTools] = useState<string[]>([]);

  const toggleToolSelect = (tool: string) => {
    if (tools.includes(tool)) {
      setTools(tools.filter((t) => t !== tool));
    } else {
      setTools([...tools, tool]);
    }
  };

  const queryKey = ["faqs", searchValue, tools];

  const { data: displayedFaqs = [], isLoading } = useQuery(
    queryKey,
    () => getFaqs(searchValue, tools),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setHasFilter(!!searchValue || tools.length > 0);
  }, [searchValue, tools]);

  return {
    setSearchValue,
    displayedFaqs,
    isLoading,
    toggleToolSelect,
    hasFilter,
    tools,
    searchValue,
  };
};

export default useFaqList;
