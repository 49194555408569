import * as React from "react";
import { UserSearch } from "../../models/UserSearch";
import { Box, Typography } from "@mui/material";

export function UserOptionItem(props: {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: UserSearch;
}) {
  return (
    <Box
      component="li"
      {...props.props}
      aria-disabled={props.option.isInProject}
    >
      <div style={{ width: "100%" }}>
        {props.option.isInProject && (
          <div>
            <b>User is already in project</b>
          </div>
        )}
        <Typography
          display="inline-block"
          variant={"subtitle2"}
          sx={{
            maxWidth: "100%",
            height: 20,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props.option.name}
        </Typography>
        <Typography
          display="inline-block"
          variant={"body2"}
          sx={{
            maxWidth: "100%",
            height: 20,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          ({props.option.adPrinciple})
        </Typography>
        <Typography
          variant={"body2"}
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props.option.email === null
            ? "No correspondence email"
            : props.option.email}
        </Typography>
      </div>
    </Box>
  );
}
