import { useCallback } from "react";
import { useQuery } from "react-query";
import { getProjectAccess } from "../api/selfOnboardingHttpClient";

const useProjectAccess = (id: string) => {
  const getProjectAccessCallback = useCallback(
    () => getProjectAccess(id),
    [id]
  );

  const { data: projectAccess, isLoading } = useQuery(
    ["project_access", id],
    getProjectAccessCallback
  );

  return { projectAccess, isLoading };
};
export default useProjectAccess;
