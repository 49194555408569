import { Button, Typography } from "@mui/material";
import InformationAlert from "../InformationAlert/InformationAlert";
import React, { useState } from "react";
import { FaqArticleResponse } from "../../api/models/FaqArticleResponse";
import DOMPurify from "dompurify";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import styles from "./FaqArticleBody.module.scss";

interface FaqArticleBodyProps {
  faqArticle: FaqArticleResponse;
  submitRating: (val: boolean) => void;
}


const FaqArticleBody = ({ faqArticle, submitRating }: FaqArticleBodyProps): JSX.Element => {

  const [hideRating, setHideRating] = useState(false)
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: {
        __html: DOMPurify.sanitize(rawHTML, {
          ADD_TAGS: ["iframe"],
          ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
        }),
      },
    });

  return (
    <>
      {faqArticle.faqAlertInfo.body && (
        <InformationAlert
          alertTitle={faqArticle.faqAlertInfo.title}
          alertBodyText={faqArticle.faqAlertInfo.body}
        />
      )}
      <Typography variant="h6">{faqArticle.faqArticle.title}</Typography>
      <Typography variant={"body2"} sx={{ marginBottom: 2 }}>
        <div className="ql-editor">
          {renderHTML(faqArticle.faqArticle.body)}
        </div>
      </Typography>
      {!faqArticle.isAlreadyRated && !hideRating &&
        <div className={styles.rateArticle}>
          <Typography variant={"h6"} sx={{ fontWeight: "bold", marginRight: 2 }}>
            Was this helpful?
          </Typography>
          <Button variant="contained"
            startIcon={<ThumbUpIcon />}
            sx={{ marginRight: 2 }}
            onClick={() => { submitRating(true); setHideRating(true) }}>
            Yes
          </Button>
          <Button variant="contained"
            startIcon={<ThumbDownIcon />}
            color="error"
            onClick={() => { submitRating(false); setHideRating(true) }}>
            No
          </Button>
        </div>
      }
    </>
  );
};

export default FaqArticleBody;
