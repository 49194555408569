import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Fragment, useEffect } from "react";
import useMultiCollapse from "../../../../Common/hooks/useMultiCollapse";
import { Faq } from "../../api/models/faq";
import FaqResultRow from "../FaqResultRow/FaqResultRow";
import FaqResultSkeleton from "../FaqResultSkeleton/FaqResultSkeleton";

interface FaqResultsProps {
  faqs: Faq[];
  hasFilter: boolean;
  searchValue?: string;
  isLoading: boolean;
  tools: string[];
  toggleToolSelect: (tool: string) => void;
}

const FaqResults = ({
  faqs,
  hasFilter,
  searchValue,
  isLoading,
  tools,
  toggleToolSelect,
}: FaqResultsProps): JSX.Element => {
  const { ToggleCollapseButton, ToggleItemButton, itemsCollapse } =
    useMultiCollapse(() => faqs.map((_, i) => i), false);

  const handleToolRemove = (tool: string) => toggleToolSelect(tool);

  return (
    <>
      {hasFilter ? (
        <>
          <Typography variant="h6">
            Results for "{searchValue}" ({faqs.length})
          </Typography>
          {tools.map((t) => (
            <Chip key={t} label={t} onDelete={() => handleToolRemove(t)} />
          ))}
        </>
      ) : (
        <Typography variant="h6">Latest topics</Typography>
      )}

      <TableContainer component={Paper} sx={{ width: "95%", mb: 5, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <b>Article</b>
              </TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                {!isLoading && <ToggleCollapseButton />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <FaqResultSkeleton />}
            {!isLoading &&
              faqs &&
              faqs.map((faq, i) => (
                <Fragment key={i}>
                  <TableRow>
                    <TableCell colSpan={4}>{faq.title}</TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        p: 0,
                        height: "100%",
                        "& button": { padding: "14px" },
                      }}
                    >
                      <ToggleItemButton index={i} />
                    </TableCell>
                  </TableRow>
                  <FaqResultRow
                    synopsis={faq.synopsis}
                    open={itemsCollapse[i]}
                    id={faq.id}
                  />
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FaqResults;
