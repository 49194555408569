import axios from "axios";
import { SelectOption } from "../../../Common/models/form";
import { Opco } from "./models/Opco";

export const getOpcosOptions = async (): Promise<SelectOption[]> => {
  const { data } = await axios.get<Opco[]>(
    `${window.configuration.apiUrl}api/Opco`
  );

  return data.map((o) => ({ label: o.name, value: o.id }));
};

export const validateProjectName = async (name: string): Promise<boolean> => {
  try {
    await axios.get(`${window.configuration.apiUrl}api/Projects/validateName`, {
      params: {
        name,
      },
    });
    return true;
  } catch {
    return false;
  }
};

export const validateCostCenter = async (costCenter: string): Promise<boolean> => {
  try {
    await axios.get(`${window.configuration.apiUrl}api/Projects/validateCostCenter`, {
      params: {
        costCenter,
      },
    });
    return true;
  } catch {
    return false;
  }
};
