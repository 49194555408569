import { useState } from "react";
import { useSnackbar, VariantType } from "notistack";
import { patchSelfonboardingRequest } from "../api/selfonboardingHttpClient";

export const useSelfonboarding = (projectId: string, memberId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);

  const showSnackbar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  };

  const submitHandler = async (isApproved: boolean) => {
    setIsSaving(true);
    try {
      await patchSelfonboardingRequest(projectId, memberId, isApproved);
      showSnackbar(
        `You have ${
          isApproved ? "ACCEPTED" : "DENIED"
        } request to join the project`,
        "success"
      );
      setIsSaving(false);
    } catch {
      showSnackbar(
        "Your request cannot be sent. Please contact administrator.",
        "error"
      );
    }
  };

  return { submitHandler, isSaving };
};
