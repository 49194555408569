import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";
import { UseFormTrigger } from "react-hook-form";
import { ProjectFormType } from "../../../../Common/hooks/useCreateProject";
import styles from "./CreateProjectStepper.module.scss";

export interface StepDetails {
  name: string;
  component: React.ReactNode;
}

interface CreateProjectStepperProps {
  stepsDetails: StepDetails[];
  activeStep: number;
  setActiveStep: (step: number) => void;
  validator: UseFormTrigger<ProjectFormType>;
  isFormValid: boolean;
}

const CreateProjectStepper = ({
  stepsDetails,
  activeStep,
  setActiveStep,
  validator,
  isFormValid,
}: CreateProjectStepperProps): JSX.Element => {
  const steps = stepsDetails.map((sd) => sd.name);

  const handleNext = async () => {
    const isValid = await validator();
    if (isValid) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleGoToStep = (index: number) => {
    if (index < activeStep) {
      setActiveStep(index);
    }
  };

  return (
    <div className={styles.stepperWrapper}>
      <Stepper activeStep={activeStep} className={styles.stepper}>
        {steps.map((label, index) => {
          return (
            <Step key={label} onClick={() => handleGoToStep(index)}>
              <StepLabel sx={{ fontWeight: 400, cursor: "pointer" }}>
                {label}{" "}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className={styles.stepper__content}>
        {stepsDetails[activeStep].component}
      </div>

      {activeStep > 0 && (
        <div className={styles.stepper__footer}>
          <div className={styles.stepper__footerButtons}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: "14px" }}
            >
              <ChevronLeftOutlinedIcon />
              PREVIOUS
            </Button>
            {(activeStep === steps.length - 1 && (
              <Button variant="contained" type="submit" size="large">
                Confirm
              </Button>
            )) || (
              <Button variant="contained" size="large" onClick={handleNext}>
                Next
                <ChevronRightOutlinedIcon />
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateProjectStepper;
