import * as React from "react";
import { User } from "../../../../Common/models/FormModels";
interface HeadCell {
  disablePadding: boolean;
  disableSorting: boolean;
  id: keyof User;
  label: string;
  numeric: boolean;
  width?: string;
}
export const getProjectUsersTableColumns = (): HeadCell[] => {
  return [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      width: "20%",
      disableSorting: false,
    },
    {
      id: "adPrinciple",
      numeric: false,
      disablePadding: false,
      label: "Heiway account",
      width: "20%",
      disableSorting: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      width: "20%",
      disableSorting: false,
    },
    {
      id: "roles",
      numeric: false,
      disablePadding: false,
      label: "Roles",
      width: "10%",
      disableSorting: true,
    },
    {
      id: "toolCaps",
      numeric: false,
      disablePadding: false,
      label: "Tools",
      width: "10%",
      disableSorting: true,
    },
  ];
};
