import { useCallback } from "react";
import { useQuery } from "react-query";
import { getProjectTools } from "../api/projectDetailsHttpClient";

const useProjectTools = (id: string) => {
  const getProjectToolsCallback = useCallback(() => getProjectTools(id), [id]);

  const { data: projectTools, isLoading } = useQuery(
    ["project_tools", id],
    getProjectToolsCallback
  );

  return { projectTools, isLoading };
};

export default useProjectTools;
