export type CapabilityTool =
  | "ARTIFACT:VSTS"
  | "BACKLOG:JIRA"
  | "BROWAPPAUT:BROWSER"
  | "BROWAPPLIV:BROWSER"
  | "BROWAUT:BROWSER"
  | "BROWLIV:BROWSER"
  | "CI:VSTS"
  | "COLLAB:SLACK"
  | "COPILOT:GITHUBCOPILOT"
  | "CS:SONARQUBE"
  | "MONITORING:NEWRELIC"
  | "MONITORING:SPLUNK"
  | "REPO:VSTS"
  | "TESTMAN:VSTS"
  | "UX:FIGMA"
  | "WIKI:CONFLUENCE"
  | "IAC:TERRAFORM"
  | "AZUREDEVOPS:VSTS"
  | "BROWTEST:BROWSER";

export const toolOrder: CapabilityTool[] = [
  "BACKLOG:JIRA",
  "WIKI:CONFLUENCE",
  "COPILOT:GITHUBCOPILOT",
  "UX:FIGMA",
  "REPO:VSTS",
  "CS:SONARQUBE",
  "TESTMAN:VSTS",
  "BROWAPPAUT:BROWSER",
  "BROWAPPLIV:BROWSER",
  "BROWAUT:BROWSER",
  "BROWLIV:BROWSER",
  "BROWTEST:BROWSER",
  "IAC:TERRAFORM",
  "CI:VSTS",
  "ARTIFACT:VSTS",
  "MONITORING:NEWRELIC",
  "MONITORING:SPLUNK",
  "COLLAB:SLACK",
];
