import { Link } from "@mui/material";
import * as React from "react";

export function NoOptionItem(props: { onClick: () => void }) {
  return (
    <div>
      <span>No users found. </span>
      <Link onClick={props.onClick}>Create a guest account</Link>
    </div>
  );
}
