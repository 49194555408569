import { Backdrop, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../Common/consts/routes";
import TopBar from "../../../../Layout/components/TopBar/TopBar";
import CreateProjectStepper, {
  StepDetails,
} from "../../components/CreateProjectStepper/CreateProjectStepper";
import useCreateProject from "../../../../Common/hooks/useCreateProject";
import AddOptionalToolsStep from "../AddOptionalToolsStep/AddOptionalToolsStep";
import AddUsersStep from "../AddUsersStep/AddUsersStep";
import EnterDetailsStep from "../EnterDetailsStep/EnterDetailsStep";
import SelectPackageStep from "../SelectPackageStep/SelectPackage";
import { generatePath } from "react-router";
import usePageTitle from "../../../../Common/hooks/usePageTitle";

const stepToPathMap = {
  0: "package",
  1: "tools",
  2: "details",
  3: "users",
};

const CreateProject = () => {
  usePageTitle("Create Project");

  const { activeStep, setActiveStep, form } = useCreateProject();
  const navigate = useNavigate();
  useEffect(() => {
    //@ts-ignore
    navigate(`./${stepToPathMap[activeStep]}`);
  }, [activeStep]);
  const {
    trigger,
    formState: { isValid },
  } = form;

  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const stepsDetails: StepDetails[] = [
    {
      name: "Select package",
      component: <SelectPackageStep setActiveStep={setActiveStep} />,
    },
    {
      name: "Add optional tools",
      component: <AddOptionalToolsStep />,
    },
    {
      name: "Enter details",
      component: <EnterDetailsStep form={form} />,
    },
    {
      name: "Add users",
      component: <AddUsersStep form={form} />,
    },
  ];

  const handleStartLoading = () => {
    setIsLoading(true);
  };

  const handleEndLoading = (success = true, errorList: any = null) => {
    setIsLoading(false);
    if (success) {
      enqueueSnackbar("Project creation has been requested!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      navigate(
        "../" +
          generatePath(routes.projects, {
            tab: "my",
          })
      );
    }
    if (errorList) {
      Object.entries(errorList).forEach(([key, value]) => {
        const errors = value as string[];
        errors.forEach((error) => {
          enqueueSnackbar(key + ": " + error, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        });
      });
    }
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    handleStartLoading();
    axios
      .post<boolean>(
        `${window.configuration.apiUrl}api/Projects`,
        form.getValues()
      )
      .then(() => {
        handleEndLoading();
      })
      .catch((reason) => {
        handleEndLoading(false, reason?.response?.data?.errors);
      });
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === "Enter") e.preventDefault();
  };
  return (
    <div style={{ height: "100%" }}>
      <TopBar>
        <Typography variant="h4">Create new project</Typography>
      </TopBar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ height: "calc(100% - 94px)" }}>
        <form
          style={{ height: "100%" }}
          onSubmit={handleSubmit}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <CreateProjectStepper
            validator={trigger}
            stepsDetails={stepsDetails}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isFormValid={isValid}
          />
        </form>
      </div>
    </div>
  );
};

export default CreateProject;
