import { Tooltip } from "@mui/material";
import styles from "./AdditionalToolIcon.module.scss";

interface AdditionalToolIconProps {
  additionalToolsCount: number;
  tooltipTitle?: React.ReactNode;
}

const AdditionalToolIcon = ({
  additionalToolsCount,
  tooltipTitle,
}: AdditionalToolIconProps): JSX.Element => {
  return (
    <Tooltip title={tooltipTitle || ""} arrow placement="top">
      <div className={styles.additionalTools} style={{ marginLeft: -6 }}>
        +{additionalToolsCount}
      </div>
    </Tooltip>
  );
};

export default AdditionalToolIcon;
