import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { PackageAction } from "../../api/models/PackageActions";
import PackageActionForm from "../PackageActionForm/PackageActionForm";
import usePackageActionsForm from "../../hooks/usePackageActionsForm";
import { mapCreateProjectFormField } from "../../../../Common/utils/dynamicFieldUtil";

interface PackageActionPopupProps {
  open: boolean;
  handleClose: () => void;
  projectId: string;
  packageAction: PackageAction;
}
const PackageActionPopup = ({
  open,
  handleClose,
  projectId,
  packageAction,
}: PackageActionPopupProps): JSX.Element => {
  const { form, handlePackageActionSubmit, isSaving } = usePackageActionsForm(
    projectId,
    packageAction.actionId
  );
  const { title, contentText, actionButtonText } =
    packageAction.actionConfiguration;
  const handleCloseAction = () => {
    form.reset({ packageCustomField: {} });
    handleClose();
  };
  const handleConfirmAction = async () => {
    await form.trigger();
    const isValid = !Object.keys(form.formState.errors).length;
    if (!isValid) {
      return;
    }
    await handlePackageActionSubmit();
    handleCloseAction();
  };
  const dynamicFields = packageAction.customFieldsConfiguration?.map(
    mapCreateProjectFormField
  );
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={isSaving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PackageActionForm
        open={open}
        handleClose={handleCloseAction}
        form={form}
        title={title}
        contentText={contentText}
        dynamicFields={dynamicFields}
        actionButtonText={actionButtonText}
        handleSave={handleConfirmAction}
      />
    </>
  );
};

export default PackageActionPopup;
