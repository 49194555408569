import * as React from "react";
import { CapabilityTool } from "../../models/tools";
import styles from "./ToolIcon.module.scss";
import AzureArtifacts from "../../assets/svg/azure-artifacts.svg";
import AzurePipelines from "../../assets/svg/azure-pipelines.svg";
import AzureRepos from "../../assets/svg/azure-repos.svg";
import AzureTestPlans from "../../assets/svg/azure-test-plans.svg";
import AzureDevOps from "../../assets/svg/azure-devops.svg";
import Browserstack from "../../assets/svg/browserstack.svg";
import BrowserstackApp from "../../assets/svg/browserstack_app.svg";
import BrowserstackAutomate from "../../assets/svg/browserstack_automate.svg";
import BrowserstackAppAutomate from "../../assets/svg/browserstack_appautomate.svg";
import Confluence from "../../assets/svg/confluence.svg";
import Copilot from "../../assets/svg/copilot.png";
import Figma from "../../assets/svg/figma.svg";
import Jira from "../../assets/svg/jira.svg";
import XRay from "../../assets/svg/xray.svg";
import Sonarqube from "../../assets/svg/sonarqube.svg";
import Splunk from "../../assets/svg/splunk.svg";
import NewRelic from "../../assets/svg/new-relic.svg";
import Terraform from "../../assets/svg/terraform.svg";
import { IconSize } from "../../models/iconSize";
import { Tooltip } from "@mui/material";
import classNames from "classnames";

interface ToolIconProps {
  tool: CapabilityTool;
  size: IconSize;
  tooltipTitle?: React.ReactNode;
}

const toolIconMap: Record<CapabilityTool, string> = {
  "ARTIFACT:VSTS": AzureArtifacts,
  "BACKLOG:JIRA": Jira,
  "BROWAPPAUT:BROWSER": BrowserstackAppAutomate,
  "BROWAPPLIV:BROWSER": BrowserstackApp,
  "BROWAUT:BROWSER": BrowserstackAutomate,
  "BROWLIV:BROWSER": Browserstack,
  "BROWTEST:BROWSER": Browserstack,
  "CI:VSTS": AzurePipelines,
  "COLLAB:SLACK": Jira,
  "CS:SONARQUBE": Sonarqube,
  "MONITORING:NEWRELIC": NewRelic,
  "MONITORING:SPLUNK": Splunk,
  "REPO:VSTS": AzureRepos,
  "UX:FIGMA": Figma,
  "WIKI:CONFLUENCE": Confluence,
  "TESTMAN:VSTS": AzureTestPlans,
  "IAC:TERRAFORM": Terraform,
  "COPILOT:GITHUBCOPILOT": Copilot,
  "AZUREDEVOPS:VSTS": AzureDevOps,
};

const ToolIcon = ({ tool, size, tooltipTitle }: ToolIconProps): JSX.Element => {
  return (
    <div
      className={classNames(styles.iconWrapper, {
        [styles.iconWrapper__large]: size === IconSize.Large,
        [styles.iconWrapper__small]: size === IconSize.Small,
      })}
    >
      <Tooltip title={tooltipTitle ? tooltipTitle : ""} arrow placement="top">
        <img src={toolIconMap[tool]} alt="tool" data-testid={`icon-${tool}`} />
      </Tooltip>
    </div>
  );
};

export default ToolIcon;
