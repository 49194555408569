import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import OutlinedFlagRoundedIcon from "@mui/icons-material/OutlinedFlagRounded";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ProjectStatus } from "../models/project";

export const projectStatusIcon: Record<ProjectStatus, any> = {
  Active: <CheckCircleOutlineRoundedIcon />,
  Archived: <ArchiveOutlinedIcon />,
  Blocked: <LockOutlinedIcon />,
  Flagged: <OutlinedFlagRoundedIcon />,
  Processing: <AccessTimeRoundedIcon />,
};
